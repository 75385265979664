.mylonaci-table{
    &__button {
        margin-top: 3px !important;
        cursor: pointer;
        &-disabled {
            margin-top: 3px !important;
            pointer-events: none;
            cursor: default;
            text-decoration: none !important;
            background-color: #ccc !important;
        }
    }
}