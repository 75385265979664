/*
* 	Owl Carousel Owl Demo Theme 
*	v1.24
*/

.owl-theme .owl-controls{
	margin-top: 10px;
	text-align: center;
}

/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div{
	color: #FFF;
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
	margin: 5px;
	padding: 3px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	background: #869791;
	filter: Alpha(Opacity=50);/*IE7 fix*/
	opacity: 0.5;
}
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
	text-decoration: none;
}

/* Styling Pagination*/

.owl-theme .owl-controls .owl-page{
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page span{
	display: block;
	width: 12px;
	height: 12px;
	margin: 5px 7px;
	filter: Alpha(Opacity=50);/*IE7 fix*/
	opacity: 0.5;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background: #869791;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers{
	height: auto;
	width: auto;
	color: #FFF;
	padding: 2px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}

/* preloading images */
.owl-item.loading{
	min-height: 150px;
	/* background: url(AjaxLoader.gif) no-repeat center center */
}




.owl-slider-2items .owl-item
{

}



.tag-slider
{
    height: 55px;
    line-height: 53px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px!important;
    padding-right: 0px!important;
}


.tag-slider a
{
    text-decoration:none;
}
/* 
 * 	Core Owl Carousel CSS File
 *	v1.24
 */

/* clearfix */

.owl-carousel .owl-wrapper:after
{
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

/* display none until init */

.owl-carousel
{
    display: none;
    position: relative;
    width: 100%;
    -ms-touch-action: pan-y;
}

.owl-carousel .owl-wrapper
{
    display: none;
    position: relative;
    -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper-outer
{
    overflow: hidden;
    position: relative;
    width: 100%;
}

.owl-carousel .owl-wrapper-outer.autoHeight
{
    -webkit-transition: height 500ms ease-in-out;
    -moz-transition: height 500ms ease-in-out;
    -ms-transition: height 500ms ease-in-out;
    -o-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item
{
    float: left;
}

.owl-controls .owl-page,
.owl-controls .owl-buttons div
{
    cursor: pointer;
}

.owl-controls
{
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */

.grabbing
{
    /* cursor: url(grabbing.png) 8 8, move; */
}

/* fix */

.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item
{
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
}

/* CSS3 Transitions */

.owl-origin
{
    -webkit-perspective: 1200px;
    -webkit-perspective-origin-x: 50%;
    -webkit-perspective-origin-y: 50%;
    -moz-perspective: 1200px;
    -moz-perspective-origin-x: 50%;
    -moz-perspective-origin-y: 50%;
    perspective: 1200px;
}

/* fade */

.owl-fade-out
{
    z-index: 10;
    -webkit-animation: fadeOut .7s both ease;
    -moz-animation: fadeOut .7s both ease;
    animation: fadeOut .7s both ease;
}

.owl-fade-in
{
    -webkit-animation: fadeIn .7s both ease;
    -moz-animation: fadeIn .7s both ease;
    animation: fadeIn .7s both ease;
}

/* backSlide */

.owl-backSlide-out
{
    -webkit-animation: backSlideOut 1s both ease;
    -moz-animation: backSlideOut 1s both ease;
    animation: backSlideOut 1s both ease;
}

.owl-backSlide-in
{
    -webkit-animation: backSlideIn 1s both ease;
    -moz-animation: backSlideIn 1s both ease;
    animation: backSlideIn 1s both ease;
}

/* goDown */

.owl-goDown-out
{
    -webkit-animation: scaleToFade .7s ease both;
    -moz-animation: scaleToFade .7s ease both;
    animation: scaleToFade .7s ease both;
}

.owl-goDown-in
{
    -webkit-animation: goDown .6s ease both;
    -moz-animation: goDown .6s ease both;
    animation: goDown .6s ease both;
}

/* scaleUp */

.owl-fadeUp-in
{
    -webkit-animation: scaleUpFrom .5s ease both;
    -moz-animation: scaleUpFrom .5s ease both;
    animation: scaleUpFrom .5s ease both;
}

.owl-fadeUp-out
{
    -webkit-animation: scaleUpTo .5s ease both;
    -moz-animation: scaleUpTo .5s ease both;
    animation: scaleUpTo .5s ease both;
}

/* Keyframes */

/*empty*/

@-webkit-keyframes empty{
  0% {opacity: 1;}
}

@-moz-keyframes empty{
  0% {opacity: 1;}
}




@keyframes empty{
  0% {opacity: 1;}
}

@-webkit-keyframes fadeIn{
  0% { opacity:0; }
  100% { opacity:1; }
}

@-moz-keyframes fadeIn{
  0% { opacity:0; }
  100% { opacity:1; }
}

@keyframes fadeIn{
  0% { opacity:0; }
  100% { opacity:1; }
}

@-webkit-keyframes fadeOut{
  0% { opacity:1; }
  100% { opacity:0; }
}

@-moz-keyframes fadeOut{
  0% { opacity:1; }
  100% { opacity:0; }
}

@keyframes fadeOut{
  0% { opacity:1; }
  100% { opacity:0; }
}

@-webkit-keyframes backSlideOut{
  25% { opacity: .5; -webkit-transform: translateZ(-500px); }
  75% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
  100% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
}

@-moz-keyframes backSlideOut{
  25% { opacity: .5; -moz-transform: translateZ(-500px); }
  75% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
  100% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
}

@keyframes backSlideOut{
  25% { opacity: .5; transform: translateZ(-500px); }
  75% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
  100% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
}

@-webkit-keyframes backSlideIn{
  0%, 25% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(200%); }
  75% { opacity: .5; -webkit-transform: translateZ(-500px); }
  100% { opacity: 1; -webkit-transform: translateZ(0) translateX(0); }
}

@-moz-keyframes backSlideIn{
  0%, 25% { opacity: .5; -moz-transform: translateZ(-500px) translateX(200%); }
  75% { opacity: .5; -moz-transform: translateZ(-500px); }
  100% { opacity: 1; -moz-transform: translateZ(0) translateX(0); }
}

@keyframes backSlideIn{
  0%, 25% { opacity: .5; transform: translateZ(-500px) translateX(200%); }
  75% { opacity: .5; transform: translateZ(-500px); }
  100% { opacity: 1; transform: translateZ(0) translateX(0); }
}

@-webkit-keyframes scaleToFade{
  to { opacity: 0; -webkit-transform: scale(.8); }
}

@-moz-keyframes scaleToFade{
  to { opacity: 0; -moz-transform: scale(.8); }
}

@keyframes scaleToFade{
  to { opacity: 0; transform: scale(.8); }
}

@-webkit-keyframes goDown{
  from { -webkit-transform: translateY(-100%); }
}

@-moz-keyframes goDown{
  from { -moz-transform: translateY(-100%); }
}

@keyframes goDown{
  from { transform: translateY(-100%); }
}

@-webkit-keyframes scaleUpFrom{
  from { opacity: 0; -webkit-transform: scale(1.5); }
}

@-moz-keyframes scaleUpFrom{
  from { opacity: 0; -moz-transform: scale(1.5); }
}

@keyframes scaleUpFrom{
  from { opacity: 0; transform: scale(1.5); }
}

@-webkit-keyframes scaleUpTo{
  to { opacity: 0; -webkit-transform: scale(1.5); }
}

@-moz-keyframes scaleUpTo{
  to { opacity: 0; -moz-transform: scale(1.5); }
}

@keyframes scaleUpTo{
  to { opacity: 0; transform: scale(1.5); }
}

#owl-demo .owl-v1  .item
{
    background: #42bdc2;
    padding: 30px 0px;
    margin: 5px;
    color: #FFF;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
}

.owl-v1  .item
{
    background: #42bdc2;
    padding: 30px 0px;
    margin: 5px;
    color: #FFF;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
}

.button-slider .item
{
    padding: 5px 0px;
    margin: 10px 5px;
    color: #FFF;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    height: 80px;
    line-height: 20px;
}

.tag-slider .item
{
    padding: 5px 0px;
    margin: 10px 5px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 30px;
    text-align: center;
    height: 30px;
    line-height: 15px;
    background-color: #fff;
    font-weight: bold;
    border: solid 1px #f1f1f1;
}

.tag-slider .item *
{
    font-size: 1em;
    color: #060000;
    margin-top: 0px;
    font-weight: bold;
    text-decoration: none;
}

.button-slider .owl-carousel .owl-item
{
    margin-left: -3px;
    border-left: solid 1px #ddd;
}

.button-slider .item *
{
    margin: 0px!important;
    font-size: 0.9em!important;
    line-height: inherit;
    font-weight: bold;
    color: black;
    letter-spacing: 0px!important;
}

.button-slider .item img
{
}

.button-slider .item img
{
    width: 40px;
    height: 40px;
    line-height: 0px;
}

@media (max-width:767px)
{
    .button-slider .item
    {
        padding: 5px 0px;
        margin: 5px;
        color: #FFF;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        text-align: center;
        height: 60px;
        line-height: 16px;
    }
    .button-slider .item img
    {
        width: 30px;
        height: 30px;
    }
}

.owl-v3  .item
{
    background: #42bdc2;
    padding: 30px 0px;
    margin: 5px;
    color: #FFF;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
}

.owl-lazyv1-box .item
{
    padding: 0px;
    margin: 0px 5px 0px 0px;
    color: #FFF;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    height: 450px;
    line-height: 20px;
}

.owl-lazyv1-box .owl-item
{
    border-left: solid 5px #fff;
}

.owl-lazyv1-box .item img
{
    width: 100%;
}

.owl-lazyv1-box
{
--empty-placeholder: 0px;
}

.owl-lazyv1-box .owl-wrapper
{
--empty-placeholder: 0px;
}

.owl-wrapper-outer
{
--empty-placeholder: 0px;
}.tag-slider
{
    height: 55px;
    line-height: 53px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px!important;
    padding-right: 0px!important;
}

.tag-slider .owl-wrapper-outer, .tag-slider .owl-wrapper-outer .owl-wrapper, .tag-slider .owl-wrapper-outer .owl-wrapper .owl-item
{
    height: 100%;
    line-height: inherit;
}.tag-slider .owl-next
{
    position: absolute;
    top: 0px;
    right: 0px;
    margin-top: 0px!important;
    margin-bottom: 0px!important;
    margin-right: 0px!important;
    opacity: 100!important;
    border-radius: 0px!important;
    padding: 0px 20px!important;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)!important;; /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)!important;; /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)!important;; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 )!important;; /* IE6-9 */


}

.tag-slider .owl-controls
{
    margin-top: 0px;
    margin-bottom: 0px;
}.tag-slider .owl-prev
{
    padding: 0px 10px;;;
    position: absolute;
    color:#000;
    top: 0px;
    left: 0px;
    height: 100%;
    line-height: inherit;
    margin-top: 0px!important;
    margin-bottom: 0px;
    margin-left: 0px!important;
    opacity: 100!important;
    border-radius: 0px!important;
   padding: 0px 20px!important;
 /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)!important; /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)!important; /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)!important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 )!important; /* IE6-9 */

}.owl-item {
   
}



/* OWL FOR 3SLIDES */


#owl-demo-3-slides .item{
        background: #3fbf79;
        padding: 30px 0px;
        margin: 10px;
        color: #FFF;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        text-align: center;
    }
    .customNavigation{
      text-align: center;
    }
    .customNavigation a{
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        
        
        
        
        
    }

/* OWL FOR 3 ITEMS */

