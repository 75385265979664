@import "../../layout/themes/css/gc-stater-package.css";
@import "../../layout/themes/css/theme-font.css";
@import "../../layout/themes/css/apps-package.css";
@import "../../layout/themes/css/scrolltop.css";
@import "../../layout/themes/css/pyn-package.css";
@import "../../layout/themes/components/bootstrap/css/bootstrap.css";
@import "../../layout/themes/components/owl-carroussel/owl-carousel/owl.theme-package.css";
@import "../../layout/themes/css/stratec.css";

/*#owl-demo-3-slides .item{
    background: #3fbf79;
    padding: 30px 0px;
    margin: 10px;
    color: #FFF;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
}

.customNavigation{
  text-align: center;
}

.customNavigation a{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);   
}*/

.btn-default .btn-home-download {
  font-size: 16px;
  color: #000 !important;
}

#menu-produits, #menu-liens-utiles, .social-networks {
  padding: 0;
}

@media (max-width: 767px) {
  .dash-main {
      margin-top: -50px;
  }
}