.theme-text-medium
{
    font-size: 2em!important;
}
.section
{
    border-bottom: solid 1px #f1f1f1!important;
    padding: 60px !important;
    border: none!important;
}.gc-block-header
{
    font-size: 07px!important;
    margin-bottom: 0px;
    font-weight: lighter;
    line-height: 130px!important;
    padding: 15px 15%;
}.title
{
    color: #0c673a;
    font-weight: bold;
    font-size: 4em;
    text-transform: uppercase;letter-spacing: 1px !important;
}

.topmenu .nav>li > a:hover,.nav>li > a:focus
{
    color: #fff!important;
    background-color: #5a9531!important;
    background: #5a9531!important;
}.topmenu .nav>li > a:hover,.nav>li > a
{
    font-weight: bold;
    color: #fff!important;
    background-color: transparent;
    font-size: 10px !important;
    border-radius: 30px;
    text-transform: uppercase;
    border:thin 1px #fff;
    padding-left: 20px;
    padding-right: 20px;
}

.dynamic-static-div
{
    line-height: 100px!important;
}.apps-social img
{
    width: 35px!important;
    min-width: 20px;

margin-right: 7px;

margin-left: 7px;

height: 45px;
}

.topmenu ul li:hover,ol li:hover
{
    background-color: #5a9531!important;
}.alpha *
{
    font-size: 1em;
}.btn.btn-default
{

    background-color: #fff;
    color: #000!important;
    box-shadow: none;
    font-size: 8px!important;
    padding: 15px 30px;

    border:solid 1px #333!important;
    font-weight:bold;
  
}

.gcontent-1
{
    height: 400px;
    background-position: center center !important;
    background-size: cover!important;
    background-repeat: no-repeat;
}.xclam
{
   
    padding:0px;
     padding-top: 45px;
    overflow: hidden;
    height: 350px;
    width: 101%;
    vertical-align: bottom;
    background-size: cover!important;
    background-position: center center;
   
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    background: #fff;
     border-radius:30px!important;margin: -2px;
}

.xclam h5
{
    font-size: 22px;
}.xclam div
{
    padding: 10px 20px!important;
    height: 610px;
    /*background-image: url('../components/last/images/bdack.png');*/
    background-repeat: repeat-x;
}

.xclam div h5
{
    margin-bottom: 50px;
}

.gc-newstitled
{
    color: #000;
    font-size: 1em;
}.theme-maincolorback
{
    background-color: #000!important;
    color:#ffffff!important;
}  /* .gcontent-2 .gcontentcontent
{
    background: none;
    background-position: center center !important;
} */.container-fluid
{
    border: none;
}.container
{
    border: none;

}

.distort-block
{
    border-left: none;
    border-top: solid 1px #f1f1f1;
}

.theme-maincolorback *
{
    font-weight: normal;
    color: #fff!important;
}

.right
{
    background-color: none;
    background: none!important;
    margin-right: -40px;
}

.left
{
    background: none!important;
    background-color: none!important;
    margin-left: -40px;
}

.logos img
{
    width: 80%;
    opacity: 1!important;
}header.smaller h1#logo img
{
    width: 100px !important;
    margin-top: -30px!important;
}

header.smaller .alpha
{
    display: none;
}header h1#logo img
{
    width: 180px !important;
    margin-top: -105px!important;
    margin-bottom: 0px!important;
}.xtron
{
    background-size: cover;
    background-position: center;
    margin-bottom: 30px;
    border: none!important;
    margin-right: 0px;
   
}.theme-maincolorback-light
{
    /* background-color: #f3f8fb!important; */
    background-color: #e6ecf0!important;
    border: none!important;
}.theme-maincolorback-light *, .theme-maincolorback-light p
{
    color: #000000;
}.thedashy h3
{
    font-size: 22px;
    text-align: center;
    white-space: wrap;

letter-spacing: 0px;

margin-bottom: 9px !important;

margin-top: 9px !important;
}.thedashy .bloc
{
    padding-left: 10px;
    padding-right: 10px;
    height: 150px;
    margin-bottom: 0px;

background-color: #FFF;
    border:solid 1px #e2d6cb;
}

.img-responsive,
.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img
{
    display: block;
    max-width: 100%;
    height: auto;
}.thedashy .icon
{
    border-radius: 0;
    border: none;

height: 100%;

width: 25% !important;
}

.sub-section
{
    padding: 15px !important
}

.transactions_list {
  margin-bottom: 30px !important
}

.long-news .data
{
    border-bottom: none!important;
}header nav
{
    height: 86px;
    min-height: 80px;
    line-height: 100px;
}.logo_slide img
{
     height: 70px;
    width:auto!important;
    max-width: 100%;
    text-align: center;
    min-height: 0px!important;
    margin-bottom: 0px;
}

.logo_slide*
{
    text-align: center;
}

.logo_slide div
{
    text-align: center;
    height: 100PX;
    line-height: 100px;
}

.page-title
{
    border-bottom: solid 10px black;
    padding-bottom: 10px;
}header
{
    height: auto!important;
    overflow: initial;
    border-bottom: solid 1px #000!important;
    background-color: transparent;width: 350px;
}

header nav .navbar-nav>li:hover
{
    color: #fff!important;
    background: none;
}

header nav .navbar-nav>li > a:hover
{
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover
{
}

header nav a:hover
{
    background-color: transparent!important;
    color: #346011!important;
    border:solid 1px;
}

ul.common-login-vertical li
{
    font-size: 1em!important;
    font-weight: normal;
    line-height: 150%;
    list-style-position: outside;
    text-transform: uppercase;
}

header .glyphicon
{
    font-size: 1em!important;
}

.topped
{
    height: 90px;
}.alpha
{
    height: 57px;
}

.xcontent
{
    padding: 3% !important;
}

.long-news .data p
{
    text-align: justify;
}p
{
   /* text-align: justify;*/
    font-size: 14px!important;
    font-weight: normal;
    color: #000!important;
}

a
{
    font-weight: normal;
}

.glyphicon
{
    color: #000!important;
}ul li,ol li
{
    font-weight: normal;
    list-style:none;
    list-style-position:outside;
   text-align:left;
    line-height:10px;
    color: #000;
}

.theme-text-big
{
    margin-bottom: 10px!important;
    margin-top: 30px;
    font-size: 2em !important;
    line-height: 1.3em !important;
}

.alpha ul li, .alpha ul li a
{
    font-size: 1em;
    padding-top: 5px;
    padding-bottom: 5px;

list-style-type: none;
}

.footer-1-1 ul li
{
    border-top: none!important;
}

ul.common-login-vertical li a
{
    font-size: 13px;
    font-weight: bold;
    color: #000;
}

ul.common-login-vertical li a:hover
{
    font-size: 13px;
    font-weight: bold;
    color: #000!important;
}

@media (max-width:767px)
{
    .footer-1-1 ul li
    {
        text-align: center;
        font-size: 0.9em;
        line-height: 100%;
    }
    .footer-wrap-1-1
    {
        text-align: center;
    }
}

.topmenu .ivonedlink
{
}

.btn-primary
{
    border-bottom: none!important;
    background-color: none!important;
    background: none!important;
}.btn.btn-primary
{
    box-shadow: none;
    color:white!important;
}

.gc-van-navbar ul.dropdown-menu
{
    margin-top: -20px;
}

body
{
   /* margin-top: 90px;*/
}

@media (max-width:767px)
{
    body
    {
        margin-top: 0px!important;
        overflow-y: visible;
    }
}.theme-maincolorback2
{
    background-color: #e7eeeb;
}

.galeria-block
{
    padding: 0px;
    height: 150px;
}

.galeria-block img
{
    width: 130%;
    height: AUTO;
}

.galeriav1
{
    padding-bottom: 0px;
    margin-bottom: 0px;
    height: auto;
}

.grid
{
    padding-left: 0px;
    padding-right: 0px;
}

.no-padding-section
{
    padding: 0px;
}

.models
{
    padding: 0px !important;
}

.frm_subscribe
{
    overflow: hidden;
    height: 200px;
}.description
{
    font-size: 1.5em !important;
    color: #999595;
    line-height: 140%;
    margin-bottom:1%;
}

.description_1
{
    font-size: 1em !important;
    color: #999595 !important;
    line-height: 140%;
    margin-bottom:1%;
}

.description_2
{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}




.icon-outline
{
    margin-bottom: 2px!important;
    background-size: cover;
}header.smaller
{
    border-bottom: solid  #00713a 0px !important;
    padding-bottom: 15px!important;
    background-color: #fff!important;
     background: #000!important;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

header.smaller .section
{
    padding-bottom: 15px!important;
}

header .section
{
}header.section
{
    /*background-color:;*/
}

figure.effect-lily figcaption
{
    padding-top: 6px;
    padding-left: 14px;
}.gc-van-navbar ul li,ol li
{
    font-size: 1em;
    color:white !important;
list-style-type: none;
}

.gcontent-1 .container
{
    background-size: cover;
    background-repeat: no-repeat;
}

.carousel
{
}

.full-height
{
    height: 100% !important;
}

.grid figure
{
    vertical-align: middle;
    background-position: center center;
    background-size: cover;
}

.gathering p
{
    font-size: 14px!important;
    text-align: left;
}

.gatherin-text-bloc
{
    padding-top: 0px!important;
}

.gathering
{
    border-top: solid 1px #333;
}

.gatherin-bloc-decription
{
    border-right: solid 1px #f1f1f1!important;
}

.panel-heading
{
}

.panel-title
{
    background-color: #b34141;
}

.panel-title>a,.panel-title>a:hover
{
    background-color: black;
    color: #ebebeb;
}.gcs-filter td,th,tr:hover
{


background-color:#fFF;
    pointer:cursor;
}.gcs-filter td,th,tr
{
    font-size: 1em!important;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
    line-height:120%!important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
border-radius: 0px;
    border:none;
    border-top-color: inherit;
border-right-color: inherit;
border-bottom-color: inherit;
border-left-color: inherit;

background-color: #eff5e3;
}.table
{
    font-size: 1.3em;
    font-weight: bold;
border-spacing: 0 15px!important;
    border-collapse: separate;
}.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th
{
    padding: 15px 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
    border:1px solid;
    

font-size: 11px;

height: 50PX;
}table tr td
{
    border:none!important;
}

.panel-group .panel
{
    margin-bottom: 0;
    border-radius: 4px;
    border: none!important;
}

.panel-title>a
{
    padding-top: 30px;
    padding-bottom: 30px;
}

.panel-body
{
    padding-left: 0px;
}.xclam h3
{
    text-shadow: 3x 3x 3x #333;
}

.collapsed
{
    background-color: #000!important;
    color: #fff!important;
}

.gathering
{
    background-color: white;
}

.footer-wrap-1-1 ul li
{
    list-style-type: none;
    line-height: 120%;
}

@media (max-width:767px)
{
    .section
    {
       padding: 20px!important;
    }
    .amen input
    {
        width: 100%;
    }
    .amen button
    {
        width: 100%;
        font-size: 2em;
        color: #ffffff;
        border: solid 1px#f1f1f1;
    }
}

.fullmenu2 ul li
{
    list-style-type: none;
    color: #000000!important;
}

.fullmenu2 ul
{
    padding-left: 0px;
}

@media (min-width:480px) and (max-width:767px)
{
}

@media (max-width:767px)
{
    h1.theme-text-big,h2.theme-text-big,h3.theme-text-big,h4.theme-text-big,h5.theme-text-big,span.theme-text-big,p.theme-text-big,.block-text-small h2,.block-text-small .h2
    {
        font-size: 2.5em!important;
    }
    .title
    {
        font-size: 3em;
    }
}

.fullmenu2 ul li a
{
    color: #000!IMPORTANT;
}.gc-bloc-sub-header {
  text-align: center;
font-size: 20px !important;
padding-left: 20%;
padding-right: 20%;
font-weight: 500;
line-height: 120%;
    opacity:0.7;margin-bottom: 45px;color: #827e7e !important;letter-spacing: -0.1px;
}
.apps-social {
  padding-top: 5%;
}
.position {
  font-weight: bold;
font-size: 18px;
}.thin-border-right {
  border-right: 1px solid #cac9c8!important;
}.gc-main-welcometext {
  font-size: 22px !important;
line-height: 141%;
margin-bottom:30px;
letter-spacing: 1px !important;
}.team-details {
    border:none;
}.team-member {
    border:none!important;
}.team-1 {
    border-radius:0px;
    /*background-image: url('../images/background/all_back-01-01-02-02.png');*/
}.theme-full-gradient-back5
{
background: #FC466B;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right,#dff789 ,#d04215);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right,#dff789 ,#d04215 ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.member-name {
  letter-spacing: 0px !important;
font-size: 19px;
font-weight: bold;
font-variant: normal;
text-transform: capitalize;
}.theme-smallblock-space * {
    line-height:100%;
    margin:auto auto 10px auto!important;
}.theme-full-gradient-back5 * {
    color:#000!important;
}
@media (min-width:1200px) {
.container {
  width: 1024px !important;
}
}
.xclam h5 {
  font-size: 20px;
}
.proddetails-group p {
  margin-top: 0px;
text-align: left;
}
.theme-text-main-color {
  color: #d9691e !important;
}

.owl-theme .owl-controls .owl-buttons {
color: #FFF;
display: inline-block;
zoom: 1;
margin: 5px;
padding: 5px 10px!important;
font-size: 12px;
-webkit-border-radius: 30px;
-moz-border-radius: 30px;
border-radius: 30px;
background: #1b1969;
filter: Alpha(Opacity=50);
opacity: 0.5;
}

.owl-next, .owl-prev{
color: #000!important;
    font-weight:normal;
    background: #fff!important;
    opacity: 1!important;
padding: 10px 20px!important;

}.shadow-6 {
    box-shadow:

  0.4px 0.7px 2.2px rgba(0, 0, 0, 0.011),
  0.9px 1.7px 5.3px rgba(0, 0, 0, 0.016),
  1.8px 3.3px 10px rgba(0, 0, 0, 0.02),
  3.1px 5.8px 17.9px rgba(0, 0, 0, 0.024),
  5.8px 10.9px 33.4px rgba(0, 0, 0, 0.029),
  14px 26px 80px rgba(0, 0, 0, 0.04),;


}.gc-bloc-pre-heading {
      text-align: center;
font-size: 16px!IMPORTANT;
padding-left: 20%;
padding-right: 20%;
font-weight: 100;
line-height: 120%;
    opacity:0.7;margin-bottom: -15px;color: #827e7e !important;letter-spacing: -0.1px;
}.theme-maincolorback * {
 color:#fff!important;
    
}.thin-border-top {
    border-top: solid 1px #e2d6cb!important;
}.theme-maincolorback * {
    color:white!important;
}.theme-maincolorback2 * {
    color:#fff!important;
}
.theme-maincolorback2 a {
  color: #ffffff !important;
}.dropdown-menu{
  list-style-type: none!important;
list-style-position: outside;
    z-index:99999;
}.dropdown-menu ul li a {
    font-size:13px!important;
}.dashboard ul li a, .dashboard ul li {
  /*color: #fff !important;*/
    font-size:09px;
}.modal-header {
  border-radius: 0px;
height: 80px;
    line-height:80px;
}.apps-data-bloc {
  padding-left: 0px;
padding-right: 0px;
    
       line-height: 20px;
    margin: 0px auto 0px auto;
    min-height: 20px;
}.apps-line-data, .row.apps-line-data
{
line-height:100%;
    background: transparent!important;
    height: auto;
    border-bottom: solid 1px #ccc;text-align: left;padding: 15px 15px 15px 5px;
}
.apps-line-data p {
  text-align: left;
font-size: 11px;
}.appsv3-admin-bloc {
    border:none;margin-top: 0px;background-color: #fff;
}.appsv3-admin-bloc-title p {
    font-size:18px !important;font-weight: bold;
    text-align:left;margin-bottom: -1px;color: #03040d !important;
}.map_place {
  position: absolute;
z-index: 9000;
width: 50px;
height: 50px;
border-radius: 100px;
    opacity:1;background-image: url('../images/background/index.glowing-rotate-ring.svg');background-repeat: no-repeat;background-position: center center;
}
.dash_topbar img {
  width: 18px;
}.left-content-aapvs img {
  width: 18px;
margin-right: 8px;
      
}.left-content-aapvs ul li {
    font-size:12px;list-style-type: none;list-style-position: inside;
    border:none;
       -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;margin-top: 5px;
}
.dropdown-menu ul li {
  list-style-type: none;
}
.appsv3-top-menu ul li {
}.responsive-menu ul li img {
  margin-right: 15px;
width: 20px;
    opacity:1;
}
@media (max-width:991px) {
.appv3-appname {
  font-size: 1em;
}
}
.theme-text-extrabig {
  margin-bottom: 0px !important;
}
.appsv3-admin-bloc-title {
  padding-top: 10px;
padding-bottom: 10px;padding-left: 0px;
}
.appsv3-admin-bloc-title_2 {
  display: flex;
  align-items: center;
  justify-content: space-between; 
}
.theme-text-extrabig {
  font-size: 3em !important;
margin-top: 0px;
}.theme-maincolorback2 {
  background-color: #bb5428 !important;
}.btn {
  background-color: transparent;
    border:solid 1px #666;
        border-radius: 61px!important;
    font-size: 11px!important;
     padding: 5px 30px;margin-bottom: 5px;
}.dashboard .preview_icon {
  width: 70px;
}
@media (max-width:767px) {
.main_img_hero {
  margin-left: 60px;
margin-top: -200px;
margin-right: 45px;
}
}.cookies p {
    font-size:3em!important;
}.btn-default img {
  margin-right: 15px;
margin-top: -5px;
  
}
@media (max-width:767px) {
.gc-main-welcometext {
  font-size: 22px !important;
margin-bottom: 0px;
margin-top: 6px;
}
}.xclam {
    text-align:center;
    justify-content:center;
    text-align:center;
}.btn-group .btn {
  width: auto;
background-color: transparent;
    border:none;padding-right: 10px;padding-left: 10px;
}
.dashboard .title {
}.dashboard-title {
  font-size: 50px;
    line-height:120%;margin-top: 5px;margin-bottom: 5px;
}
@media (max-width:767px) {
.dashboard-title {
  font-size: 50px;
font-weight: bold;
line-height: 120%;
}
.theme-text-extrabig {
  font-size: 2em !important;
}
.btn-group  .btn-default {
  padding: 6px 15px;
}
}
.modal_coockie p {
}
@media (max-width:767px) {
.modal_coockie p {
  font-size: 0.7em !important;
}
}
.modal_coockie .btn-default {
  padding: 10px;
font-size: 0.8em;
}
@media (max-width:767px) {
.modal_coockie .btn-default {
  padding: 10px;
font-size: 0.6em !important;
}
.btn-group .btn-default img {
  margin-right: 0px;
}
}.transactions_list p {
 
font-size: 1em;
/* margin-bottom: 1%; */
}
.group-items {
  background-color: #fff;
}
@media (max-width:767px) {
.transactions_list p {
  /* font-size: 1.5em !important; */
}
}.transactions_list img {
  width: 20px;margin-right: 0px;margin-left: 0px;margin-top: -5px!IMPORTANT;
}
.transactions_list .prize {
  font-weight: bold;
font-size: 1.2em !important;
color: #1d3621;
}
.transactions_list .date_header {
font-size: 1.2em;
font-weight: normal;
margin-bottom: 17px;
color: #777 !important;
}.btn-primary {
  background-color: #000 !important;
  
}.table thead tr th{
background-color:#000 !important;
    color:#fff !important;
    text-align:center;

font-size: 14px !important;
  
}
@media (max-width:479px) {
.transactions_list .prize {
  font-size: 10px !important;
}
}
@media (max-width:767px) {
.transactions_list .prize {
  font-size: 0.8em !important;
}
}
.date_header {
  padding-bottom: 15px;
border-bottom: 1px solid #ded6d6;
}
@media (max-width:767px) {
.transactions_list img {
  width: 15px;
}
}.hero-area-form {
  padding-top: 5px;
border-radius: 0px;
    border:none!important;
}.dashboard .dashboard-session {
  font-size: 11px !important;
margin-bottom: 10px;
color: #fff !important;
}
.dashboard_devises {
  font-size: 13px;
margin-top: 5px !important;
}
@media (max-width:767px) {
.group-items {
  border-left: 8px solid #077b40!important;
}
}.transactions_list .label {
  font-size: 13px !important;
    font-weight:400;
padding-left: 0px;
    white-space:normal;line-height: 100%;
}.transactions_list .profileimg {
  width: 60px;
height: 60px;
}
.transactions_list .profileimg img {
  width: 100% !important;
margin-top: 1px;
}.transactions_list .data {

    font-weight:bold;text-align: left;
}.thin-border {
   border: solid 1px #ccc;
    border-radius:0px;
    
}.transactions_list .dropdown-toggle {
    border:none!important;
  
}
.dash-main .info {
}
.dash-main .data {
}
@media (max-width:767px) {
.dash-main .data {
  font-size: 32px !important;
}
.transactions_list .data {
  font-size: 11px !important;
}
.transactions_list .label {
  font-size: 11px !important;
line-height: 100%;
}
.transactions_list .profileimg {
  width: 45px;
height: 45px;
}
}.left-content-aapvs-close li, .left-content-aapvs-close p  {
  font-size: 10px !important;
    text-align:center!important;
    padding-top:5px;
    font-weight:bold;
}
.left-content-aapvs-close li img {
  width: 35px;
height: 35px;
       -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    opacity:0.6!important;
    margin:0px;
    margin-bottom:10px;
    clear:both!important;
}.left-content-aapvs-close li img:hover {
 
    opacity:1!important;
}.left-content-aapvs-close .appsv3-admin-bloc-title {
    display:none;
}.button-slider .item h1 {
  font-size: 07px!important;
}
.transactions_list ul li  {
}
@media (max-width:767px) {
.login-connectpart {
  padding: 40px !important;
background-image: url('../images/background/Capture.png');
background-repeat: no-repeat;
background-position: left top;
background-size: cover;
}
}.hero-area-form input {
    border: solid 0px #fff0c1!important;
    background-color:#000!important;
    
}.transactions_list .btn {
  padding: 5px;
    border:none!important;
    opacity:1;
    
}.profileimg p {
  font-size: 20px !important;
color: #fff !important;
font-weight: bold;
    line-height:65px!important;
 background-color: #e7b10d !important;
}
@media (max-width:767px) {
.profileimg p {
  font-size: 11px !important;
  line-height: 35px !important;
}
}
.transactions_list .btn-data-highlited {
  padding: 10px;
background-color: #bf7115;
color: #fff !important;
}
.transactions_list .positive-amount {
  color: #328302 !important;
}
.gc-separator {
  background-color: transparent !important;
background: transparent !important;
}
.dash-main .data {
}.dash-main .data {
  font-size: 32px;
font-weight:500;
margin-top: 0px;
    color:#fff!important;
    margin-bottom:0px;line-height: 42px;
}
@media (max-width:767px) {
.table-responsive {
}
.threebloc-animate {
  height: auto !important;
margin-top: 15px;
padding-left: 23px !important;
margin-left: 13px;
}
.welcome-bloc {
  height: 550px !important;
background-repeat: repeat;
background-position: left top;
background-size: contain;
background-origin: padding-box;
}
}.dashboard .appsv3-admin-bloc-title p {
  font-size: 19px !important;
   color: #0a6056!important;
    font-weight:bold;
}.dashboard .appsv3-admin-bloc-title {
    border-bottom: solid 1px #eee;
        margin-top:1px;
    margin-bottom:10px;

    
}.theme-maincolorback2 * {
    color:white!important;
}
@media (max-width:767px) {
.welcome-bloc {
  text-align: center;
overflow: hidden;
}
}.dash-main {
 /* height: 200px !important;*/
}
.theme-maincolorback-alternative {
  background-color: #389583;
}
@media (max-width:767px) {
.transactions_list .profileimg {
  width: 35px;
height: 35px;
}
}
.transactions_list .prize_extented {
}.prize-extented {
  /*font-size: 25px !important;*/
}
.thedashy img {
  width: 20% !important;
}
.home-section-user {
  text-align: left;
}
@media (max-width:767px) {
.home-section-user {
  text-align: center;
padding-bottom: 0px !important;
}
}
.home-section-amount-resume {
  text-align: right;
}
@media (max-width:479px) {
.home-section-amount-resume {
}
.home-section-amount-resume {
  text-align: center;
}
}
@media (max-width:767px) {
.home-section-amount-resume {
  text-align: center;
}
}.dashboard-amount-preview {
  font-size: 50px;
    font-weight:bold;margin-top: 5px;
}
@media (max-width:767px) {
.dashboard-amount-preview {
  font-size: 28px;
}
}.home-section * {
 
}
.gc-grid {
}
@media (max-width:767px) {
.dashboard .appsv3-admin-bloc-title p  {
  font-size: 20px !important;
}
}
.item {
  padding-top: 10px !important;
}
@media (max-width:767px) {
.virgin-button {
  width: 100%;
}
}.main_centering_content .btn.btn-default {
}.main_centering_content .btn.btn-default img {
    width:30px;
    margin:0px;
}.html, body {
    
}
@media (max-width:767px) {
.html {
  overflow: auto;
}
body {
  overflow: auto;
height: auto !important;
}
}
.main {
  height: 100%;
}
@media (max-width:767px) {
.main {
  height: auto !important;
  overflow-x: hidden;
}
}
.home-section-responsive * {
  margin-top: 0px !important;
margin-bottom: 0px;
padding-top: 0px !important;
padding-bottom: 0px !important;
}.home-section-responsive .dashboard-matricule {  margin-bottom: 5px;margin-top: 5px;
  
}.home-section-responsive {
  padding-top: 10px!important;
padding-bottom: 10px!important;position: fixed;z-index: 100;top: 0px;margin-top: 50px;width: 100%;left: 0px;
     -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
          -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-transition-duration: 0.2s;
-moz-transition-duration: 0.2s;
-ms-transition-duration: 0.2s;
-o-transition-duration: 0.2s;
transition-duration: 0.2s;
}.home-section-responsive .dashboard-title {
  font-size: 31px!important;
     -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}.home-section-responsive .dashboard-amount-preview {
  font-size: 18px;
     -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}.home-section {
   
   /* height:auto;*/
    
 
      -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-transition-duration: 0.2s;
-moz-transition-duration: 0.2s;
-ms-transition-duration: 0.2s;
-o-transition-duration: 0.2s;
transition-duration: 0.2s;
    
line-height:100%;   
    
}.transactions_details input, .transactions_details .form-control {
    background-color:transparent!important;
    padding:0px;
    border:none;overflow: auto;
}.theme-maincolorback-alternative {
    background-color:#0a6056!important;
}.responsive-modal-menu ul li {
  color: #fff !important;
    font-weight: bold;
color: #0a0101;
background-color: transparent;
border: none;
margin: 0px;
padding: 5px 15px;
width: 100%;
font-size: 15px;
}.responsive-modal-menu .appsv3-admin-bloc-title p {
    color:#fff!important;
}.load_flter .appsv3-form-fieldset {
  margin-top: 0px;
margin-bottom: 0px;
    border:none;
}


.transactions_details label{
   font-size:16px;
}
@media (max-width:767px) {
.home-section-amount-resume {
  padding-top: 0px !important;
}
}
.dashboard-matricule {
  margin-bottom: 0px;
}
@media (max-width:767px) {
.home-section {
  padding-left: 50px;
padding-right: 50px;
padding-bottom: 50px;
text-align: center;
}
.dashboard-matricule {
  text-align: center;
}
}.thedashy .bloc h3, .thedashy .bloc h2, .thedashy .bloc h1 {
    color:#20483e !important;
}
#regForm p {
  margin-top: 0px;
margin-bottom: 15px;
}.bloc .description {
  text-align: center;
color: #a9a4a4 !important;
}
@media (max-width:767px) {
.dash-main {
  margin-top: -80px;
}
}.dash-main .item {
    height:120px;padding-top: 0px!IMPORTANT;
}.dash-main .icon_place {
  /*background-color: #f3f8fb!important;*/
    line-height:80px;
    vertical-align:center;
}.dash-main .data-place {
    background-color:#0a6056!important;
}
.lightSlider ul li {
}
@media (max-width:767px) {
.lightSlider ul li {
  width: 100%;
}
}
.transactions_details .appsv3-form-fieldset {
}.gc-form-data .form-control, .gc-form-data .form-control:focus {
    height:50px;
    font-size:18px;
    padding:15px;
    border-radius: 30px !important;
}.dashboard_subsession{
  margin-top: 0px;
color: #fff !important;
    font-size:11px!important;margin-bottom: 0px;
}
.promo-slice {
  height: 350px;
}.welcome-bloc {
  height: 420px;
}
.calling {
  font-size: 3.8em;
}
.threebloc-animate {
  height: 490px;
}.modal_details_list table {
    border-spacing: 0 5px!important;
border-collapse:separate;
}


.theme-vertical-align {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-name-desc-title{
  justify-content: left;
}

.windows-left-appv2 .appsv3-admin-bloc {
    background-color:transparent;
}
.modal-header img.icon {
  width: 30px;
margin-right: 0px;
}
.modal-header btn {
}.modal-header .btn {
  padding: 10px;
width: 50px;
height: 50px;
margin-top: 55px;
margin-left: 10px !important;
    border: 1px solid #000 !important;
    background-color: #fff;
}.modal-header .btn:hover {
  background-color: #fff;
}
.hero-area-form input:hover {
}.hero-area-form input:focus {
    background-color:#f1f1f1 !important;
}.home-section .breadcrumb {font-weight: 400;font-size: 19px;background-color: transparent;padding-left: 0px;line-height: 100%;margin-top: 5px;margin-bottom: 0px;
}.home-section-responsive .breadcrumb {
  font-size: 19px;
margin-bottom: 5px;
font-weight: lighter;
}.dash-main .block-color-cold-green {
    background-color: #00a488 !important;
}.dash-main .block-color-green {
    background-color: #0b8b3e!important;
}.dash-main .block-color-yellow {
    background-color: #e7b10d!important;
}.dash-main .block-color-orange {
    background-color: #f3531e !important;
}.home-section .form-control {
    width:180px;
}
@media (max-width:767px) {
.home-section .form-control {
  width: 100%;
text-align: center;
}
.home-section .breadcrumb {
  margin-bottom: 5px;
margin-top: 5px;
font-size: 19px;
}
}
.bothed {
  width: 70px;
}
.log_session h2 {
  font-size: 32px;
line-height: 40px;
font-weight: bolder;
margin-top: 5px;
}.left-content-aapvs-close li img:hover {
    opacity:1;
}
.hosted-copy {
  padding-left: 30px;
padding-right: 30px;
font-size: 10px !important;
margin-bottom: 25px;
}
@media (max-width:767px) {
.home-section * {
  margin-bottom: 2%;
padding-top: 0px;
margin-top: 2%;
}
}.transactions_list .appsv3-admin-bloc-title p {
    
   
}
.therma {
  font-size: 28px!IMPORTANT;
margin-left: 10px;
}.pagination > li > a, .pagination > li > span {
position: relative;
float: left;
padding: 12px 32px;
margin-left: -1px;
line-height: 1.42857143;
color: #337ab7;
text-decoration: none;
background-color: #fff;
border: 1px solid #ddd;font-size: 13px !important;
}.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
z-index: 3;
color: #fff !important;
cursor: default;
background-color: #0b8b3e !important;
border-color: #337ab7;
}.terminal-list .data-big {
  font-size: 19px !important;text-align: left;
}.terminal-list {
    border: solid thin #ada486;
    /* margin:0 10px 15px; */
    margin:0  0 15px;
    height: 100px;padding: 0px 0PX;
}.terminal-list .bloc {
  /* padding-right: 20px;
padding-left: 20px; */
    overflow:clip;
    
}.terminal-list .btn, .terminal-list .default-btn  {  
  background-color: transparent;
  width: 100% !important;
  height: 100%;
  padding: 8px;
  border-radius: 0px !important;
  color: #ffffff !important;
  border:none;
}
@media (max-width:767px) {
.terminal-list {
  height: 80px;
}
.pagination > li > a, .pagination > li > span {
  padding: 6px 12px !important;
}
}
.terminal-list img.icon {
}
.terminal-list img.icon {
  width: 30px;
}
@media (max-width:767px) {
.terminal-list .default-btn {
  padding: 5px;
}
}
.dc-desc {
  text-align: center;
padding-right: 30px !important;
}
@media (max-width:767px) {
.dc-desc {
  text-align: left;
height: 130px;
margin-bottom: 30px;
background-color: #f1f1f1;
}
}
.terminal-list .preview_icon {
  width: 50px;
}
.terminal-list .name {
    font-size:28px!important;
}
.terminal_list .label {
  padding-left: 0px;
}

@media (max-width:767px) {
  .terminal-list .name {
    font-size:16px!important;
}
.terminal-list .bloc {
  /* padding-left: 10px; */
/* padding-right: 10px; */
}
}
.desc-desc {
}
@media (max-width:767px) {
.desc-desc {
  padding-left: 15px!important;
/* padding-right: 30px !important; */
text-align: left;
     /* border-right: 1px solid #cac9c8!important; */
}
.desc-pict {
     border-right: 1px solid #cac9c8!important;
}
}.terminal-list .label {
    padding-left:0px;
}
.dc-desc .preview_icon {
}.rechar_step {
    font-size:31px;
  
}
.rech_bloc .input {
}
.rech_bloc * {
  margin-bottom: 2%;
padding-right: 15px;
padding-left: 15px;
padding-top: 0px;
}
.rech_bloc {
  height: 450px;
overflow: hidden;
}
@media (max-width:767px) {
.rech_bloc {
  height: auto;
}
}
.logod {
  height: 50px;
padding-left: 0px;
}