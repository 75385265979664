.mylonaci-no-results{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 150px;

    &__search-image{
        width: 50px !important;
        height: 50px !important;
    }

    p{
        text-align : center;
        color : red !important;
    }

}