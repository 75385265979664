/*
 * 	Core Owl Carousel CSS File
 *	v1.3.3
 */

/* clearfix */
.owl-carousel .owl-wrapper:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}
/* display none until init */
.owl-carousel{
    display: none;
    position: relative;
    width: 100%;
    -ms-touch-action: pan-y;
}
.owl-carousel .owl-wrapper{
    display: none;
    position: relative;
    -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper-outer{
    overflow: hidden;
    position: relative;
    width: 100%;
}
.owl-carousel .owl-wrapper-outer.autoHeight{
    -webkit-transition: height 500ms ease-in-out;
    -moz-transition: height 500ms ease-in-out;
    -ms-transition: height 500ms ease-in-out;
    -o-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item{
    float: left;
}
.owl-controls .owl-page,
.owl-controls .owl-buttons div{
    cursor: pointer;
}
.owl-controls {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */
.grabbing {
    /* cursor:url(grabbing.png) 8 8, move; */
}

/* fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility:    hidden;
    -ms-backface-visibility:     hidden;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
}

/*
 *  Owl Carousel CSS3 Transitions
 *  v1.3.2
 */

.owl-origin {
    -webkit-perspective: 1200px;
    -webkit-perspective-origin-x : 50%;
    -webkit-perspective-origin-y : 50%;
    -moz-perspective : 1200px;
    -moz-perspective-origin-x : 50%;
    -moz-perspective-origin-y : 50%;
    perspective : 1200px;
}
/* fade */
.owl-fade-out {
    z-index: 10;
    -webkit-animation: fadeOut .7s both ease;
    -moz-animation: fadeOut .7s both ease;
    animation: fadeOut .7s both ease;
}
.owl-fade-in {
    -webkit-animation: fadeIn .7s both ease;
    -moz-animation: fadeIn .7s both ease;
    animation: fadeIn .7s both ease;
}
/* backSlide */
.owl-backSlide-out {
    -webkit-animation: backSlideOut 1s both ease;
    -moz-animation: backSlideOut 1s both ease;
    animation: backSlideOut 1s both ease;
}
.owl-backSlide-in {
    -webkit-animation: backSlideIn 1s both ease;
    -moz-animation: backSlideIn 1s both ease;
    animation: backSlideIn 1s both ease;
}
/* goDown */
.owl-goDown-out {
    -webkit-animation: scaleToFade .7s ease both;
    -moz-animation: scaleToFade .7s ease both;
    animation: scaleToFade .7s ease both;
}
.owl-goDown-in {
    -webkit-animation: goDown .6s ease both;
    -moz-animation: goDown .6s ease both;
    animation: goDown .6s ease both;
}
/* scaleUp */
.owl-fadeUp-in {
    -webkit-animation: scaleUpFrom .5s ease both;
    -moz-animation: scaleUpFrom .5s ease both;
    animation: scaleUpFrom .5s ease both;
}

.owl-fadeUp-out {
    -webkit-animation: scaleUpTo .5s ease both;
    -moz-animation: scaleUpTo .5s ease both;
    animation: scaleUpTo .5s ease both;
}
/* Keyframes */
/*empty*/
@-webkit-keyframes empty {
    0% {opacity: 1}
}
@-moz-keyframes empty {
    0% {opacity: 1}
}
@keyframes empty {
    0% {opacity: 1}
}
@-webkit-keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:1; }
}
@keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes fadeOut {
    0% { opacity:1; }
    100% { opacity:0; }
}
@-moz-keyframes fadeOut {
    0% { opacity:1; }
    100% { opacity:0; }
}
@keyframes fadeOut {
    0% { opacity:1; }
    100% { opacity:0; }
}
@-webkit-keyframes backSlideOut {
    25% { opacity: .5; -webkit-transform: translateZ(-500px); }
    75% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
    100% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
}
@-moz-keyframes backSlideOut {
    25% { opacity: .5; -moz-transform: translateZ(-500px); }
    75% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
    100% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
}
@keyframes backSlideOut {
    25% { opacity: .5; transform: translateZ(-500px); }
    75% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
    100% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
}
@-webkit-keyframes backSlideIn {
    0%, 25% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(200%); }
    75% { opacity: .5; -webkit-transform: translateZ(-500px); }
    100% { opacity: 1; -webkit-transform: translateZ(0) translateX(0); }
}
@-moz-keyframes backSlideIn {
    0%, 25% { opacity: .5; -moz-transform: translateZ(-500px) translateX(200%); }
    75% { opacity: .5; -moz-transform: translateZ(-500px); }
    100% { opacity: 1; -moz-transform: translateZ(0) translateX(0); }
}
@keyframes backSlideIn {
    0%, 25% { opacity: .5; transform: translateZ(-500px) translateX(200%); }
    75% { opacity: .5; transform: translateZ(-500px); }
    100% { opacity: 1; transform: translateZ(0) translateX(0); }
}
@-webkit-keyframes scaleToFade {
    to { opacity: 0; -webkit-transform: scale(.8); }
}
@-moz-keyframes scaleToFade {
    to { opacity: 0; -moz-transform: scale(.8); }
}
@keyframes scaleToFade {
    to { opacity: 0; transform: scale(.8); }
}
@-webkit-keyframes goDown {
    from { -webkit-transform: translateY(-100%); }
}
@-moz-keyframes goDown {
    from { -moz-transform: translateY(-100%); }
}
@keyframes goDown {
    from { transform: translateY(-100%); }
}

@-webkit-keyframes scaleUpFrom {
    from { opacity: 0; -webkit-transform: scale(1.5); }
}
@-moz-keyframes scaleUpFrom {
    from { opacity: 0; -moz-transform: scale(1.5); }
}
@keyframes scaleUpFrom {
    from { opacity: 0; transform: scale(1.5); }
}

@-webkit-keyframes scaleUpTo {
    to { opacity: 0; -webkit-transform: scale(1.5); }
}
@-moz-keyframes scaleUpTo {
    to { opacity: 0; -moz-transform: scale(1.5); }
}
@keyframes scaleUpTo {
    to { opacity: 0; transform: scale(1.5); }
}

/* Magnific Popup CSS */
.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
    filter: alpha(opacity=80); }

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden; }

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

.mfp-align-top .mfp-container:before {
    display: none; }

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto; }

.mfp-ajax-cur {
    cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out; }

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
    cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none; }

.mfp-loading.mfp-figure {
    display: none; }

.mfp-hide {
    display: none !important; }

.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044; }
.mfp-preloader a {
    color: #CCC; }
.mfp-preloader a:hover {
    color: #FFF; }

.mfp-s-ready .mfp-preloader {
    display: none; }

.mfp-s-error .mfp-content {
    display: none; }

button.mfp-close, button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none; }
button::-moz-focus-inner {
    padding: 0;
    border: 0; }

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    filter: alpha(opacity=65);
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace; }
.mfp-close:hover, .mfp-close:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
.mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
    color: #333; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%; }

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap; }

.mfp-arrow {
    position: absolute;
    opacity: 0.65;
    filter: alpha(opacity=65);
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
.mfp-arrow:active {
    margin-top: -54px; }
.mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
.mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
.mfp-arrow:after, .mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
.mfp-arrow:before, .mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
    left: 0; }
.mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
.mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
    right: 0; }
.mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
.mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px; }
.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
.mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%; }
.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
    line-height: 0; }
.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
.mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto; }

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px; }

.mfp-image-holder .mfp-content {
    max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
    /**
         * Remove all paddings around the image on small screen
         */
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0; }
    .mfp-img-mobile img.mfp-img {
        padding: 0; }
    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0; }
    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px; }
    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0; }
    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px; }
    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        text-align: center;
        padding: 0; }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        transform: scale(0.75); }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0; }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%; }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px; }
}

.mfp-ie7 .mfp-img {
    padding: 0; }
.mfp-ie7 .mfp-bottom-bar {
    width: 600px;
    left: 50%;
    margin-left: -300px;
    margin-top: 5px;
    padding-bottom: 5px; }
.mfp-ie7 .mfp-container {
    padding: 0; }
.mfp-ie7 .mfp-content {
    padding-top: 44px; }
.mfp-ie7 .mfp-close {
    top: 0;
    right: 0;
    padding-top: 0; }




/* BLOCK */



/*
Template Name: Bootstrap Starter Kit - Header 1
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/

/*	HEADER 1
/*----------------------------------------------------*/

#header-1
{
    width: 100%;
    background: none;
    height: 80px;
}

#header-1 .main-nav
{
    background-color: #193441;
    border: 0;
    height: 80px;
}

#header-1 .main-nav .navbar-toggle
{
    margin-right: 0;
    margin-top: 0;
    background: none;
    position: absolute;
    right: 20px;
    top: 20px;
}

#header-1 .main-nav .navbar-toggle:focus
{
    outline: none;
}

#header-1 .main-nav .navbar-toggle .icon-bar
{
    background-color: rgba(255, 255, 255, 0.6);
    height: 3px;
}

#header-1 .main-nav .navbar-toggle:hover .icon-bar
{
    background-color: #fff;
}

#header-1 .main-nav .nav
{
    margin-top: 12px;
}

#header-1 .main-nav .navbar-nav > li a {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 15px;
	color: #fff;
	color: rgba(255, 255, 255, 0.8);
}

#header-1 .main-nav .navbar-nav > li.active a
{
    color: #3498DB;
    background: none;
}


#header-1 .main-nav .navbar-nav > li a:hover
{
    color: #3498DB;
    background: none;
}

#header-1 .main-nav .navbar-nav > li a:focus
{
    outline: none;
    background: none;
}

#header-1 .main-nav .navbar-nav > li a:active
{
    outline: none;
    background: none;
}

#header-1 .main-nav .navbar-nav > li.active
{
    color: #333;
}

#header-1 .main-nav .nav .dropdown-menu
{
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    margin: 0;
    border: none;
    text-transform: none;
    min-width: 180px;
    background: #3E606F;
}

#header-1 .main-nav .nav .dropdown-menu:before
{
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #3E606F;
    position: absolute;
    right: 35px;
    top: -10px;
}

#header-1 .main-nav .nav .dropdown-menu a
{
    padding: 8px 20px;
    color: #fff;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
}

#header-1 .main-nav .nav .dropdown-menu a:hover
{
    background: #193441;
    color: #fff;
}

#header-1 .brand-img
{
    max-width: 150px;
    padding: 20px 0;
}

.headroom
{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all .2s ease-in-out;
}

.headroom--unpinned
{
    top: -100px;
}

.headroom--pinned
{
    top: 0;
}

/* Override bootstrap default */

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus
{
    background: none;
}

/*	HEADER 1 MEDIA QUERIES
/*----------------------------------------------------*/

/* Extra small devices (phones, less than 768px) */

@media (max-width: 767px)
{
    #header-1 .brand-img
    {
        float: none;
        margin: 0 auto;
        text-align: center;
    }
    #header-1 .navbar-fixed-top
    {
        position: relative;
    }
    #header-1 .navbar-collapse
    {
        border-top: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        width: 100%;
        top: 80px;
        position: absolute;
        background: #193441;
        z-index: 999;
    }
    #header-1 .navbar-collapse .nav
    {
        margin: 0;
    }
    #header-1 .main-nav .nav .dropdown-menu:before
    {
        display: none;
    }
    #header-1 .navbar-fixed-top .navbar-collapse,
	#header-1 .navbar-fixed-bottom .navbar-collapse
    {
        max-height: inherit;
    }
}

/* Small devices (tablets, 768px and up) */

@media (min-width: 768px){
  
	
  
  
}

/* Medium devices (desktops, 992px and up) */

@media (min-width: 992px){
  
	
  
  
}



/*
Template Name: Bootstrap Starter Kit - Header 2
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/

/*	HEADER 2
/*----------------------------------------------------*/

#header-2
{
    width: 100%;
    position: relative;
    z-index: 999;
}

#header-2 .brand-img
{
    float: none;
    margin: 30px auto;
    text-align: center;
    max-width: 150px;
}

#header-2 .navbar-toggle
{
    margin-right: 0;
    margin-top: 0;
    background: none;
    position: absolute;
    right: 20px;
    top: 30px;
    border: none;
}

#header-2 .navbar-toggle:focus
{
    outline: none;
}

#header-2 .navbar-toggle .icon-bar
{
    background-color: #999;
    height: 3px;
}

#header-2 .navbar-toggle:hover .icon-bar
{
    background-color: #3498DB;
}

#header-2 .navbar-fixed-top .navbar-collapse
{
    max-height: inherit;
    border-top: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

#header-2 .main-nav .nav
{
    margin-top: 25px;
}

#header-2 .main-nav .navbar-nav > li a
{
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 15px;
    color: #666;
    background: none;
}

#header-2 .main-nav .navbar-nav > li.active a
{
    color: #3498DB;
}


#header-2 .main-nav .navbar-nav > li a:hover
{
    color: #3498DB;
}

#header-2 .main-nav .navbar-nav > li a:focus
{
    outline: none;
}

#header-2 .main-nav .nav .dropdown-menu
{
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    margin: 0;
    border: none;
    text-transform: none;
    min-width: 180px;
    background: #3E606F;
}

#header-2 .main-nav .nav .dropdown-menu a
{
    padding: 8px 20px;
    color: #666;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
}

#header-2 .main-nav .nav .dropdown-menu a:hover
{
    background: #3498DB;
    color: #fff;
}

/* Override bootstrap default */

#header-2 .main-nav .nav .open > a,
#header-2 .main-nav .nav .open > a:hover,
#header-2 .main-nav .nav .open > a:focus
{
    background: none;
}

/*	Header 2 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */

@media only screen and (min-width : 768px)
{
    #header-2 .main-nav .nav .dropdown-menu
    {
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        margin: 0;
        border: none;
        text-transform: none;
        min-width: 180px;
        background: #3E606F;
    }
    #header-2 .main-nav .nav .dropdown-menu:before
    {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #3E606F;
        position: absolute;
        right: 35px;
        top: -10px;
    }
    #header-2 .main-nav .nav .dropdown-menu a
    {
        padding: 8px 20px;
        color: #fff;
        -webkit-opacity: 1;
        -moz-opacity: 1;
        opacity: 1;
    }
    #header-2 .main-nav .nav .dropdown-menu a:hover
    {
        background: #193441;
        color: #fff;
    }
}

/* Medium Devices, Desktops */

@media only screen and (min-width : 992px){
	

	
}

/* Large Devices, Wide Screens */

@media only screen and (min-width : 1200px){
	
	
	
}

/*
Template Name: Bootstrap Starter Kit - Header 3
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/

/*	HEADER 3
/*----------------------------------------------------*/

#header-3 {
    width: 100%;
}

#header-3 .brand {
	position: relative;
}

#header-3 .brand-img {
	position: absolute;
	left: 15px;
	top: 0;
	max-width: 150px;
}

#header-3 nav {
	background-color: #233447;
	margin-top: -1px;
}

#header-3 nav ul {
	list-style: none;
	padding: 0;
}

#header-3 nav ul li {
	color: rgba(255, 255, 255, 0.6);
	display: block;
	border-bottom: 1px dotted rgba(255, 255, 255, 0.2);
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	padding-left: 0;
	position: relative;
}

#header-3 nav ul li:last-child {
	border: none;
}

#header-3 nav ul li a {
	color: rgba(255, 255, 255, 0.6);
	display: block;
	padding: 20px;
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	padding-left: 0;
	position: relative;
}

#header-3 nav ul li a:hover, #header-3 nav ul li a:focus {
	text-decoration: none;
	color: rgba(255, 255, 255, 1);
}

#header-3 #nav-toggle {
	position: absolute;
	right: 15px;
	top: 0;
}

#header-3 #nav-toggle {
	cursor: pointer;
	padding: 10px 35px 16px 0px;
}

#header-3 #nav-toggle span, #header-3 #nav-toggle span:before, #header-3 #nav-toggle span:after {
	cursor: pointer;
	border-radius: 1px;
	-moz-border-radius: 1px;
	-webkit-border-radius: 1px;
	-o-border-radius: 1px;
	height: 5px;
	width: 35px;
	background: #353535;
	position: absolute;
	display: block;
	content: '';
}

#header-3 #nav-toggle span:before { top: -10px; }
#header-3 #nav-toggle span:after { bottom: -10px; }

#header-3 #nav-toggle span, #header-3 #nav-toggle span:before, #header-3 #nav-toggle span:after {
	transition: all 500ms ease-in-out;
	-webkit-transition: all 500ms ease-in-out;
	-moz-transition: all 500ms ease-in-out;
	-o-transition: all 500ms ease-in-out;
}

#header-3 #nav-toggle.active span { background-color: transparent; }

#header-3 #nav-toggle.active span:before, #header-3 #nav-toggle.active span:after { top: 0; }

#header-3 #nav-toggle.active span:before {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
}

#header-3 #nav-toggle.active span:after {
	transform: translateY(-10px) rotate(-45deg);
	-webkit-transform: translateY(-10px) rotate(-45deg);
	-ms-transform: translateY(-10px) rotate(-45deg);
	top: 10px;
}

#header-3 .navicon {
	position: relative;
	height: 26px;
}



#header-3 .indicator {
	position: absolute;
	right: 0 !important;
}

#header-3 .pull { display: none; }


#header-3 .hero {
	/* background: url('../images/header-3-bg.jpg') no-repeat center center; */
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding: 50px 0 300px 0;
	height: auto;
}

#header-3 .hero h1 {
	margin-top: 100px;
	font-weight: 600;
	color: #31152B;
}

#header-3 .hero .lead {
	font-weight: 600;
	color: #723147;
}



/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
  
	
  
}
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  
	
  
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  
	
	  
}
/*
Template Name: Bootstrap Starter Kit - Promo Blocks
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/






/*	PROMO BLOCK 1
/*----------------------------------------------------*/

.promo-1 {
	/* background: url("../images/water-bg.jpg") center top no-repeat; */
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding: 140px 0;
}

.promo-1 .video-wrapper {
	max-width: 600px;
	margin: 20px auto 0 auto;
}


/*	PROMO BLOCK 1 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}




/*	PROMO BLOCK 2
/*----------------------------------------------------*/

.promo-2 h1 {
	font-size: 60px;
}

.promo-2 h1, .promo-2 h2 {
	color: #ffffff;
}

.promo-2 .countDown {
	margin: 80px auto 40px auto;
	font-size: 0;
}

.promo-2 .countDown .countDown-input {
	display: none;
}

.promo-2 .countDown .countDown-wrap {
	width: 185px;
	margin: 0 15px 20px;
	display: inline-block;
	background: rgba(133, 214, 222, 0.1);
	border-left: 4px solid #F39C12;
	border-right: 4px solid #F39C12;
}

.promo-2 .countDown .digits {
	display: block;
	margin-top: 34px;
	margin-bottom: -6px;
	font-family: 'Lato', Helvetica, Arial, sans-serif;
	font-weight: 300;
	font-size: 120px;
	color: #ffffff;
}

.promo-2 .countDown .digits-label {
	margin-bottom: 18px;
	font-family: 'Lato', Helvetica, Arial, sans-serif;
	font-size: 24px;
	text-transform: uppercase;
	color: #ffffff;
}


/*	PROMO BLOCK 2 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}




/*	PROMO BLOCK 3
/*----------------------------------------------------*/

.promo-3 {
	/* background: url("../images/star-sky.jpg") center top no-repeat; */
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-attachment: fixed;
	padding: 140px 0;
}

.promo-3 h1 {
	font-size: 60px;
	color: #ffffff;
}

.promo-3 h2 {
	font-weight: 600;
	color: #ffffff;
}

.promo-3 a {
	margin-top: 40px;
}


/*	PROMO BLOCK 3 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}





/*
Template Name: Bootstrap Starter Kit - Content Blocks 1
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/



/*	CONTENT BLOCK 1-1
/*----------------------------------------------------*/

.content-1-1
{
    /* background: url("../images/typewriter-bg.jpg") center top no-repeat; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.content-1-1 .container
{
    margin-top: 380px;
}

.content-1-1 h1
{
    text-transform: uppercase;
    font-weight: 300;
}

/*	CONTENT BLOCK 1-1 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */

@media only screen and (min-width : 768px){
	
		
	
}

/* Medium Devices, Desktops */

@media only screen and (min-width : 992px){
	

	
}

/* Large Devices, Wide Screens */

@media only screen and (min-width : 1200px){
	
	
	
}

/*	CONTENT BLOCK 1-2
/*----------------------------------------------------*/

/*	CONTENT BLOCK 1-2 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */

@media only screen and (min-width : 768px){
	
		
	
}

/* Medium Devices, Desktops */

@media only screen and (min-width : 992px){
	

	
}

/* Large Devices, Wide Screens */

@media only screen and (min-width : 1200px){
	
	
	
}

/*	CONTENT BLOCK 1-3
/*----------------------------------------------------*/

.content-1-3
{
    text-align: center;
}

.content-1-3 p
{
    padding: 0 20px;
}

.content-1-3 .services-wrapper
{
    margin: 20px 0 0;
}

.content-1-3 .services-wrapper .icon
{
    height: 175px;
    width: 175px;
    max-width: 100%;
    border-radius: 50%;
    -o-border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    display: block;
    text-align: center;
    vertical-align: middle;
    position: relative;
    margin: 0 auto 50px;
}

.content-1-3 .services-wrapper .icon span
{
    line-height: 175px;
    color: #fff;
    font-size: 55px;
}

/*	CONTENT BLOCK 1-3 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */

@media only screen and (min-width : 768px){
	
		
	
}

/* Medium Devices, Desktops */

@media only screen and (min-width : 992px){
	

	
}

/* Large Devices, Wide Screens */

@media only screen and (min-width : 1200px){
	
	
	
}

/*	CONTENT BLOCK 1-4
/*----------------------------------------------------*/

.content-1-4
{
    padding: 0px;
    position: relative;
}

.content-1-4 .image-container
{
    position: absolute;
    height: 100%;
    padding: 0px;
    top: 0px;
}

.content-1-4 .image-container .background-image-holder
{
    /* background: #cccccc url('../images/dark-logs-bg.jpg') no-repeat center center; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
}

.content-1-4 .content
{
    padding-top: 80px;
    padding-bottom: 80px;
}

.content-1-4 h1
{
    margin-bottom: 32px;
}

.content-1-4 p.lead
{
    margin-bottom: 20px;
}

/*	CONTENT BLOCK 1-4 MEDIA QUERIES
/*----------------------------------------------------*/

/* Extra small devices (phones, less than 768px) */

@media (max-width: 767px)
{
    .content-1-4 .image-container
    {
        display: none;
    }
}

/*	CONTENT BLOCK 1-5
/*----------------------------------------------------*/

.content-1-5 h1
{
    color: #3498DB;
}

/*	CONTENT BLOCK 1-3 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */

@media only screen and (min-width : 768px){
	
	
	
}

/* Medium Devices, Desktops */

@media only screen and (min-width : 992px){
	
	
	
}

/* Large Devices, Wide Screens */

@media only screen and (min-width : 1200px){
	
	
	
}

/*	CONTENT BLOCK 1-6
/*----------------------------------------------------*/

.content-1-6 h2
{
    margin-bottom: 40px !important;
}

.content-1-6 .client-row
{
    overflow: hidden;
    text-align: center;
    line-height: 0;
}

.content-1-6 .client-row .col-md-3
{
    display: inline-block;
    min-height: 80px;
    line-height: 80px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.content-1-6 .client-row img
{
    max-width: 140px;
    opacity: 0.3;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-backface-visibility: none;
    height: auto;
}

.content-1-6 .client-row img:hover
{
    opacity: 1;
}

.content-1-6 .client-row .row-wrapper
{
    display: inline-block;
    width: 80%;
}

.content-1-6 .client-row:last-child .row-wrapper
{
    border-bottom: none;
}

.content-1-6 .row-wrapper .col-md-3
{
}

.content-1-6 .row-wrapper .col-md-3:last-child
{
    border-right: none;
}

/*	CONTENT BLOCK 1-6 MEDIA QUERIES
/*----------------------------------------------------*/

/* Extra small devices (phones, less than 768px) */

@media (max-width: 767px)
{
    .content-1-6 .client-row .row-wrapper
    {
        border-bottom: none;
    }
    .content-1-6 .row-wrapper .col-md-3
    {
        border-right: none;
    }
}

/* Small Devices, Tablets */

@media only screen and (min-width : 768px){
	
	
	
}

/* Medium Devices, Desktops */

@media only screen and (min-width : 992px)
{
    .content-1-6 .client-row .row-wrapper
    {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    .content-1-6 .row-wrapper .col-md-3
    {
        border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
}

/* Large Devices, Wide Screens */

@media only screen and (min-width : 1200px){
	
	
	
}

/*	CONTENT BLOCK 1-7
/*----------------------------------------------------*/

.content-1-7
{
    text-align: center;
    padding: 64px 0px 64px 0px;
}

.content-1-7 .col-md-2
{
    height: 80px;
    line-height: 80px;
}

.content-1-7 img
{
    max-width: 140px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    transition: all .3s ease;
    opacity: 0.4;
    height: auto;
}

.content-1-7 img:hover
{
    opacity: 1;
}

/*	CONTENT BLOCK 1-7 MEDIA QUERIES
/*----------------------------------------------------*/

/* Extra small devices (phones, less than 768px) */

@media (max-width: 767px){
	
	
	
}

/* Small Devices, Tablets */

@media only screen and (min-width : 768px){
	
	
	
}

/* Medium Devices, Desktops */

@media only screen and (min-width : 992px){
	
	
	
}

/* Large Devices, Wide Screens */

@media only screen and (min-width : 1200px){
	
	
	
}

/*	CONTENT BLOCK 1-9
/*----------------------------------------------------*/

.content-1-9 h4
{
    margin-top: 0;
}

.content-1-9 .fa
{
    font-size: 32px;
    color: #16A085;
}

/*	CONTENT BLOCK 1-9 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */

@media only screen and (min-width : 768px){
	
	
	
}

/* Medium Devices, Desktops */

@media only screen and (min-width : 992px){
	
	
	
}

/* Large Devices, Wide Screens */

@media only screen and (min-width : 1200px){
	
	
	
}

/*
Template Name: Bootstrap Starter Kit - Content Blocks 2
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/






/*	CONTENT BLOCK 2-1
/*----------------------------------------------------*/

.content-2-1 .btn-outline {
	margin-bottom: 30px;
}

.content-2-1 h2 {
	color: #ffffff;
}


/*	CONTENT BLOCK 2-1 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}




/*	CONTENT BLOCK 2-2
/*----------------------------------------------------*/

.content-2-2 h3 { font-weight: 300; }
.content-2-2 h3, .content-2-2 p { color: #ffffff; }

.content-2-2 .icon-outline {
	height: 150px;
	width: 150px;
	max-width: 100%;
	border: 4px solid rgba(255,255,255,0.6);
	border-radius: 50%;
	-o-border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	display: block;
	text-align: center;
	vertical-align: middle;
	position: relative;
	margin: 0 auto 50px;
	-webkit-transition: box-shadow 0.2s;
	-moz-transition: box-shadow 0.2s;
	transition: box-shadow 0.2s;
}

.content-2-2 .icon-outline:hover {
	box-shadow: 0 0 0 14px rgba(255,255,255,0.4);
	color: #fff;
}

.content-2-2 .icon-outline span {
	line-height: 150px;
	color: #fff;
	font-size: 55px;
}


/*	CONTENT BLOCK 2-2 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}




/*	CONTENT BLOCK 2-3
/*----------------------------------------------------*/

.content-2-3 h2 {
	color: #ffffff;
	margin: 0;
}



/*	CONTENT BLOCK 2-3 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}




/*	CONTENT BLOCK 2-4
/*----------------------------------------------------*/

.content-2-4 i {
	clear: both;
}

#quote-carousel {

}

.content-2-4 h1, .content-2-4 p {
	color: #ffffff;
    font-size: 44px;
    font-weight: 300;
    margin-bottom: 20px;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    line-height: 1.1;
}



/*	CONTENT BLOCK 2-4 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}



/*	CONTENT BLOCK 2-5
/*----------------------------------------------------*/

/* Light */
.content-2-5-light h2 {
	color: #333333;
}

/* Dark */
.content-2-5-dark h2 {
	color: #ffffff;
}

.content-2-5-dark h2 a {
	color: #f1c40f;
}

.content-2-5-dark h2 a:hover {
	color: #ffffff;
}


/*	CONTENT BLOCK 2-5 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}




/*	CONTENT BLOCK 2-6
/*----------------------------------------------------*/

.content-2-6 .btn-outline {
	margin: 1.8em;
}

.content-2-6 h2 {
	font-weight: 700;
	line-height: 1.4em;
	color: #ffffff;
}


/*	CONTENT BLOCK 2-6 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}




/*	CONTENT BLOCK 2-7
/*----------------------------------------------------*/

.content-2-7 {
	/* background: url("../images/dark-logs-bg.jpg"); */
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
}

.content-2-7 .counter-icon {
	font-size: 5em;
	margin-top: 30px;
	color: #f39c12;
}

.content-2-7 .counter, .content-2-7 .counter-text {
	font-family: 'Lato', Helvetica, Arial, sans-serif;
	font-weight: 700;
	color: #f39c12;
}

.content-2-7 .counter-text {
	font-size: 20px;
}

.content-2-7 .counter {
	font-size: 44px;
	color: #fff;
}

.content-2-7 .underlined-title h1, .content-2-7 .underlined-title h2 {
	color: #fff;
}


/*	CONTENT BLOCK 2-7 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}




/*	CONTENT BLOCK 2-8
/*----------------------------------------------------*/

.content-2-8 {
	padding: 0px;
	position: relative;
}

.content-2-8 .image-container {
	position: absolute;
	height: 100%;
	padding: 0px;
	top: 0px;
}

.content-2-8 .image-container .background-image-holder {
	/* background: url('../images/gold-iphone.png') no-repeat bottom center; */
	
	height: 100%;
}

.content-2-8 .content {
	padding-top: 60px;
	padding-bottom: 60px;
}

.content-2-8 h1 {
	font-weight: 600;
	font-size: 56px;
}

.content-2-8 h3 {
	margin-top: 0;
	font-weight: 600;
}

.content-2-8 h1, .content-2-8 h3, .content-2-8 .lead, .content-2-8 p, .content-2-8 span {
	color: #ffffff;
}


/*	CONTENT BLOCK 2-8 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}




/*	CONTENT BLOCK 2-9
/*----------------------------------------------------*/

.content-2-9 {
	background: #ccc;
	min-height: 260px;
	position: relative;
}

.content-2-9 .project {
	height: 260px;
	overflow: hidden;
	position: relative;
	padding: 0;
}

.content-2-9 .project img {
	width: 100%;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translateY(-50%) translateX(-50%);
    -moz-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
}

.content-2-9 .project .background-image-holder {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-size: cover !important;
	z-index: 0;
	background-position: 50% 50% !important;
}


.content-2-9 .project .hover-state {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	background-color: rgba(44, 62, 80, 0.6);
	text-align: center;
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	transition: all .5s ease;
	opacity: 0;
	padding: 0 25px;
}

.content-2-9 .project:hover .hover-state {
	opacity: 1;
}

.content-2-9 .project .hover-state h3 {
	color: #fff;
	margin-top: 40px;
	margin-bottom: 8px;
}

.content-2-9 .project .hover-state p {
	color: #fff;
}

.content-2-9 .project .hover-state .align-vertical {
	-webkit-transform: translate3d(0, 20px, 0);
	-moz-transform: translate3d(0, 20px, 0);
	transform: translate3d(0, 20px, 0);
	-webkit-transition: all .5s ease;
	-moz-transition: all .5s ease;
	transition: all .5s ease;
	opacity: 0;
}

.content-2-9 .project:hover .align-vertical {
	-webkit-transform: translate3d(0, 0px, 0);
	-moz-transform: translate3d(0, 0px, 0);
	transform: translate3d(0, 0px, 0);
	opacity: 1;
}


/*	CONTENT BLOCK 2-9 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	.content-2-9 {
		min-height: 520px;
	}
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	.content-2-9 {
		min-height: 260px;
	}
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}




/*	CONTENT BLOCK 2-10
/*----------------------------------------------------*/

.content-2-10 .border-box {
	border: 4px solid #BDC3C7;
	padding: 20px 40px;
}

.content-2-10 .border-box h2 {
	
}



/*	CONTENT BLOCK 2-10 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}/*
Template Name: Bootstrap Starter Kit - Content Blocks 3
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/




/* MTCH */
.overlay-aqua {
	background-color: rgba(26, 188, 156, 0.8);
}
.overlay-orange {
	background-color: rgba(253, 156, 18, 0.8);
}
.overlay-blue {
	background-color: rgba(52, 152, 219, 0.8);
}

/*	CONTENT BLOCK 3-1
/*----------------------------------------------------*/

.content-3-1 {
	/* background-image: url("../images/palm-trees.jpg"); */
	background-size: cover !important;
	min-height: 500px;
}

.content-3-1 .overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
}

.content-3-1 h1, .content-3-1 h2 {
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
}

.content-3-1 h1 {
	
}

.content-3-1 h2 {
	margin-top: 100px;
	font-size: 13px;
}


/*	CONTENT BLOCK 3-1 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	.content-3-1 {
		min-height: 400px;
	}
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}



/*	CONTENT BLOCK 3-2
/*----------------------------------------------------*/

.content-3-2 {
	/* background-image: url("../images/camera-girl.jpg"); */
	background-size: cover !important;
	min-height: 500px;
}

.content-3-2 .overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
}

.content-3-2 h1, .content-3-2 h2 {
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
}

.content-3-2 h1 {
	
}

.content-3-2 h2 {
	margin-top: 100px;
	font-size: 13px;
}


/*	CONTENT BLOCK 3-2 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	.content-3-2 {
		min-height: 400px;
	}
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}



/*	CONTENT BLOCK 3-3
/*----------------------------------------------------*/

.content-3-3 {
	/* background-image: url("../images/seagulls.jpg"); */
	background-size: cover !important;
	min-height: 500px;
}

.content-3-3 .overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
}

.content-3-3 h1, .content-3-3 h2 {
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
}

.content-3-3 h1 {
	
}

.content-3-3 h2 {
	margin-top: 100px;
	font-size: 13px;
}


/*	CONTENT BLOCK 3-3 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	.content-3-3 {
		min-height: 400px;
	}
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}



/*	CONTENT BLOCK 3-4
/*----------------------------------------------------*/






/*	CONTENT BLOCK 3-4 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}



/*	CONTENT BLOCK 3-5
/*----------------------------------------------------*/

.content-3-5 .feature-box {
    background: #723147;
    margin: 20px 0;
    padding: 35px 30px;
    text-align: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    -ms-border-radius: 4px;
}

.content-3-5 .feature-box:hover {
    background-color: #31152B;
}

.content-3-5 .icon {
    margin: 0 auto;
    background-color: #ffffff;
    text-align: center;
    color: #723147;
    font-size: 60px;
    height: 140px;
    width: 140px;
    line-height: 130px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
}

.content-3-5 .feature-box p {
    line-height: 25px;
    color: #ffffff;
    opacity: 0.5;
}

.content-3-5 .feature-box h3 {
    padding: 15px 0px 0px;
    color: #ffffff;
}

.content-3-5 .feature-box, .content-3-5 .feature-box:hover, .content-3-5 .feature-icon, .content-3-5 .feature-box:hover .feature-icon {
    transition: all 0.4s ease-in 0s;
    -moz-transition: all 0.4s ease-in 0s;
    -webkit-transition: all 0.4s ease-in 0s;
    -o-transition: all 0.4s ease-in 0s;
    -ms-transition: all 0.4s ease-in 0s;
}


/*	CONTENT BLOCK 3-5 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}



/*	CONTENT BLOCK 3-6
/*----------------------------------------------------*/

.content-3-6 .feature-box {
    background: #91AA9D;
    margin: 20px 0;
    padding: 35px 30px;
    text-align: center;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    -ms-border-radius: 4px;
}

.content-3-6 .feature-box:hover {
    background-color: #3E606F;
}

.content-3-6 .icon {
    margin: 0 auto;
    background-color: #ffffff;
    text-align: center;
    color: #91AA9D;
    font-size: 60px;
    height: 140px;
    width: 140px;
    line-height: 130px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
}

.content-3-6 .feature-box p {
    line-height: 25px;
    color: #ffffff;
    opacity: 0.5;
}

.content-3-6 .feature-box h3 {
    padding: 15px 0px 0px;
    color: #ffffff;
}

.content-3-6 .feature-box, .content-3-6 .feature-box:hover, .content-3-6 .feature-icon, .content-3-6 .feature-box:hover .feature-icon {
    transition: all 0.4s ease-in 0s;
    -moz-transition: all 0.4s ease-in 0s;
    -webkit-transition: all 0.4s ease-in 0s;
    -o-transition: all 0.4s ease-in 0s;
    -ms-transition: all 0.4s ease-in 0s;
}


/*	CONTENT BLOCK 3-6 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}



/*	CONTENT BLOCK 3-7
/*----------------------------------------------------*/

.content-3-7 .underlined-title {
	padding-bottom: 0;
}




/*	CONTENT BLOCK 3-7 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}



/*	CONTENT BLOCK 3-8
/*----------------------------------------------------*/

.content-3-8 .underlined-title {
	padding-bottom: 0;
    text-align: left;
}

.content-3-8 .underlined-title hr {
    float: left;
}

.content-3-8 .underlined-title h2 {
	clear: left;
}


/*	CONTENT BLOCK 3-8 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}



/*	CONTENT BLOCK 3-9
/*----------------------------------------------------*/

.content-3-9 .underlined-title {
	padding-bottom: 0;
    text-align: right;
}

.content-3-9 .underlined-title hr {
    float: right;
}

.content-3-9 .underlined-title h2 {
	clear: right;
}


/*	CONTENT BLOCK 3-9 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}



/*	CONTENT BLOCK 3-10
/*----------------------------------------------------*/

.content-3-10 {
	padding: 0px;
	position: relative;
}

.content-3-10 .image-container {
	position: absolute;
	height: 300px;
	padding: 0px;
	top: 0px;
}

.content-3-10 .image-container .background-image-holder {
	/* background: url('../images/low-poly-1.jpg') no-repeat center center; */
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 100%;
}

.content-3-10 .content {
	margin-top: 300px;
	padding: 40px;
}

.content-3-10 h3 {
	margin-bottom: 32px;
}

.content-3-10 p {
	margin-bottom: 20px;
}



/*	CONTENT BLOCK 3-10 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	.content-3-10 .image-container {
		height: 100%;
	}
	
	.content-3-10 .content {
		margin-top: 0px;
		padding: 80px 15px 80px 80px;
	}
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}



/*	CONTENT BLOCK 3-11
/*----------------------------------------------------*/

.content-3-11 {
	padding: 0px;
	position: relative;
}

.content-3-11 .image-container {
	position: absolute;
	height: 300px;
	padding: 0px;
	top: 0px;
	right: 0px;
}

.content-3-11 .image-container .background-image-holder {
	/* background: url('../images/low-poly-2.jpg') no-repeat center center; */
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 100%;
}

.content-3-11 .content {
	margin-top: 300px;
	padding: 40px;
}

.content-3-11 h3 {
	margin-bottom: 32px;
}

.content-3-11 p {
	margin-bottom: 20px;
}



/*	CONTENT BLOCK 3-11 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	.content-3-11 .image-container {
		height: 100%;
	}
	
	.content-3-11 .content {
		margin-top: 0px;
		padding: 80px 80px 80px 15px;
	}
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}/*
Template Name: Bootstrap Starter Kit - Gallery 2
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/






/*	Gallery 2
/*----------------------------------------------------*/

.gallery-2-title {
	text-align: center;
	padding-bottom: 30px;
}

.gallery-2-title h1 { color: #2c3e50; margin: 0; }
.gallery-2-title h2 { color: #95a5a6; }

.gallery-2-title hr {
	width: 10%;
	border-width: 2px;
	border-color: #16a085;
}

*, *:after, *:before { box-sizing: border-box; }

.grid {
	overflow: hidden;
	text-align: center;
	margin: 10px 0;
}

/* Common style */
.grid figure {
	position: relative;
	z-index: 1;
	display: inline-block;
	overflow: hidden;
	height: 300px;
	background: #2c3e50;
	text-align: center;
	cursor: pointer;
}

.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	opacity: 0.8;
}

.grid figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}

.grid figure figcaption,
.grid figure a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.grid figure h2 {
	word-spacing: -0.15em;
	
	font-weight: 300;
}

.grid figure h2 span {
	font-weight: 800;
}

.grid figure h2,
.grid figure p {
	margin: 0;
}

.grid figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}

/* Individual effects */

/*---------------*/
/***** Lily *****/
/*---------------*/

figure.effect-lily img {
	width: -webkit-calc(100% + 50px);
	width: calc(100% + 50px);
	opacity: 0.5;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(-40px,0, 0);
	transform: translate3d(-40px,0,0);
}

figure.effect-lily figcaption {
	top: auto;
	bottom: 0;
	height: 50%;
	text-align: left;
}

figure.effect-lily h2,
figure.effect-lily p {
	-webkit-transform: translate3d(0,40px,0);
	transform: translate3d(0,40px,0);
}

figure.effect-lily h2 {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}

figure.effect-lily p {
	color: rgba(255,255,255,0.6);
	opacity: 0;
	-webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
	transition: opacity 0.2s, transform 0.35s;
}

figure.effect-lily:hover img,
figure.effect-lily:hover p {
	opacity: 1;
}

figure.effect-lily:hover img,
figure.effect-lily:hover h2,
figure.effect-lily:hover p {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

figure.effect-lily:hover p {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
	-webkit-transition-duration: 0.35s;
	transition-duration: 0.35s;
}







/*	Gallery 1 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	
	
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}



/*
Template Name: Bootstrap Starter Kit - Gallery 1
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/



/*	Gallery 1
/*----------------------------------------------------*/

/* Gallery Item
 * -------------------------- */

.isotope-gallery-container .col-md-3
{
    margin-right: -1px;
}

.gallery-item-wrapper
{
    padding-bottom: 25px;
}

.gallery-item
{
    overflow: hidden;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 3px;
}

.flush .gallery-item
{
    border-radius: 0;
}

.gallery-item .gallery-thumb
{
    position: relative;
    overflow: hidden;
}

.gallery-item .gallery-thumb img
{
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    width: 100%;
}

.gallery-item:hover .gallery-thumb img
{
    transform: scale(1.1);
}

.gallery-item .gallery-thumb .image-overlay
{
    background-color: #2c3e50;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.gallery-item:hover .gallery-thumb .image-overlay
{
    opacity: 0.50;
    filter: alpha(opacity=50);
}

.gallery-item .gallery-thumb .gallery-zoom, .gallery-item .gallery-thumb .gallery-link {
    width: 45px;
    height: 45px;
    text-align: center;
    background-color: #2c3e50;
    display: none;
    line-height: 45px;
    position: absolute;
    top: 50%;
    margin-top: -22px;
    font-size: 18px;
    color: #fff;
    display: block;
    border-radius: 2px;
}

.gallery-item .gallery-thumb .gallery-zoom:hover, .gallery-item .gallery-thumb .gallery-link:hover
{
    background-color: #1ABC9C;
    color: #ffffff;
}

.gallery-item .gallery-thumb .gallery-zoom:focus, .gallery-item .gallery-thumb .gallery-link:focus
{
    outline: none;
}

.gallery-item .gallery-thumb .gallery-link
{
    right: 0;
    margin-right: -45px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.gallery-item .gallery-thumb .gallery-zoom
{
    left: 0;
    margin-left: -45px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.gallery-item:hover .gallery-thumb .gallery-zoom
{
    display: block;
    left: 50%;
    margin-left: -48px;
}

.gallery-item:hover .gallery-thumb .gallery-link
{
    display: block;
    right: 50%;
    margin-right: -48px;
}

.gallery-item .gallery-details
{
    text-align: center;
    padding-top: 20px;
    background-color: #fff;
    border: 2px solid #ecf0f1;
    border-top: 0;
    overflow: hidden;
}

.gallery-item .gallery-details h5
{
    margin: 0;
    font-size: 1em;
    font-weight: 700;
    position: relative;
}

.gallery-item .gallery-details p
{
    font-size: .9em;
}

/* Gallery Filter
 * -------------------------- */

.filter
{
    list-style: none;
    padding: 0;
    margin: 0 0 60px 0;
    text-align: center;
}

.filter li
{
    display: inline-block;
    margin: 0;
}

.filter li:before
{
    display: inline-block;
    content: "/";
    padding: 0 25px 0 19px;
    color: rgba(0,0,0,0.20);
}

.filter li:first-child:before
{
    display: none;
}

.filter li a
{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    color: #2c3e50;
}

.filter li a:hover,
.filter li a:focus,
.filter li a.active
{
    color: #1ABC9C;
    text-decoration: none;
}

/* Isotope Animating
 * -------------------------- */

.isotope,
.isotope .isotope-item
{
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    -ms-transition-duration: 0.8s;
    -o-transition-duration: 0.8s;
    transition-duration: 0.8s;
}

.isotope
{
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    -ms-transition-property: height, width;
    -o-transition-property: height, width;
    transition-property: height, width;
}

.isotope .isotope-item
{
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -ms-transition-property: -ms-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    transition-property: transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition
{
    -webkit-transition-duration: 0s;
    -moz-transition-duration: 0s;
    -ms-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
}

/*	Gallery 1 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */

@media only screen and (min-width : 768px){
	
		
	
}

/* Medium Devices, Desktops */

@media only screen and (min-width : 992px){
	

	
}

/* Large Devices, Wide Screens */

@media only screen and (min-width : 1200px){
	
	
	
}



/*
Template Name: Bootstrap Starter Kit - Team 1
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/



/*	Team 1
/*----------------------------------------------------*/

.team-1 h2
{
    margin-bottom: 50px;
}

.team-member
{
    max-width: 530px;
    margin: 0 0 -10px 0;
    overflow: hidden;
    border-top: 0;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
}

.team-details
{
    padding: 20px;
}

.team-member .member-name
{
    margin-top: 20px;
    position: relative;
}

.team-member .member-name:before
{
    content: "";
    width: 100%;
    height: 2px;
    background-color: #ecf0f1;
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
}

.team-member .position
{
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    display: block;
}

.team-member .social-links
{
    padding-top: 10px;
}

.team-member .social-links > li a
{
    border-color: #ecf0f1;
    border-radius: 3px;
}

/*	Team 1 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */

@media only screen and (min-width : 768px){
	
	
	
	
}

/* Medium Devices, Desktops */

@media only screen and (min-width : 992px){
	
	
	
	
}

/* Large Devices, Wide Screens */

@media only screen and (min-width : 1200px){
	
	
	
}

.team-member > img
{
    width: 100%;
}



/*
Template Name: Bootstrap Starter Kit - Team 2
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/



/*	Team 2
/*----------------------------------------------------*/

.team-2 .team-wrapper
{
    padding-bottom: 25px;
}

.team-2 .team-wrapper .team-item
{
    max-width: 500px;
    margin: 0 auto;
    overflow: hidden;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.team-2 .team-wrapper .team-item .team-thumb
{
    position: relative;
    overflow: hidden;
}

.team-2 .team-wrapper .team-item .team-thumb img
{
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    width: 100%;
}

.team-2 .team-wrapper .team-item:hover .team-thumb img
{
    transform: scale(1.1);
}

.team-2 .team-wrapper .team-item .team-thumb .image-overlay
{
    background-color: #2c3e50;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.team-2 .team-wrapper .team-item:hover .team-thumb .image-overlay
{
    opacity: 0.50;
    filter: alpha(opacity=50);
}

.team-2 .team-wrapper .team-item .team-thumb .team-link
{
    position: absolute;
    color: #fff;
    display: block;
}

.team-2 .team-wrapper .team-item .team-thumb .team-link:hover
{
    color: #F39C12;
}

.team-2 .col-sm-4 .team-item .team-thumb .team-link, .team-2 .col-md-3 .team-item .team-thumb .team-link
{
    top: 0;
    left: 45%;
    margin-top: -50px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.team-2 .col-sm-4 .team-item:hover .team-thumb .team-link, .team-2 .col-md-3 .team-item:hover .team-thumb .team-link
{
    display: block;
    top: 58%;
    left: 45%;
}

.team-2 .team-wrapper .team-item .team-details
{
    text-align: center;
    padding-top: 20px;
    background-color: #fff;
    border-top: 0;
    overflow: hidden;
}

/*	Team 2 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */

@media only screen and (min-width : 768px)
{
    .team-2 .col-sm-4 .team-item .team-thumb .team-link
    {
        left: 40%;
    }
    .team-2 .col-sm-4 .team-item:hover .team-thumb .team-link
    {
        top: 66%;
        left: 40%;
    }
    .team-2 .col-sm-6 .team-item .team-thumb .team-link
    {
        left: 43.4%;
    }
    .team-2 .col-sm-6 .team-item:hover .team-thumb .team-link
    {
        top: 60%;
        left: 43.4%;
    }
}

/* Medium Devices, Desktops */

@media only screen and (min-width : 992px)
{
    .team-2 .col-sm-4 .team-item .team-thumb .team-link
    {
        left: 42%;
    }
    .team-2 .col-sm-4 .team-item:hover .team-thumb .team-link
    {
        top: 62%;
        left: 42%;
    }
    .team-2 .col-md-3 .team-item .team-thumb .team-link
    {
        left: 39%;
    }
    .team-2 .col-md-3 .team-item:hover .team-thumb .team-link
    {
        top: 66%;
        left: 39%;
    }
}

/* Large Devices, Wide Screens */

@media only screen and (min-width : 1200px)
{
    .team-2 .col-sm-4 .team-item .team-thumb .team-link
    {
        left: 43.4%;
    }
    .team-2 .col-sm-4 .team-item:hover .team-thumb .team-link
    {
        top: 60%;
        left: 43.4%;
    }
    .team-2 .col-md-3 .team-item .team-thumb .team-link
    {
        left: 41.3%;
    }
    .team-2 .col-md-3 .team-item:hover .team-thumb .team-link
    {
        top: 63%;
        left: 41.3%;
    }
}

/*
Template Name: Bootstrap Starter Kit - Pricing Tables 1
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/






/*	Pricing Tables 1
/*----------------------------------------------------*/

.pricing-table-1 .price-block {
	text-align: center;
	color: #ffffff;
	transition: all 0.25s;
	max-width: 400px;
	margin: 0 auto 20px auto;
}

.pricing-table-1 h3 {
	text-transform: uppercase;
	padding: 20px 0;
	background: #2c3e50;
	margin: 0;
	border-radius: 6px 6px 0 0;
}

.pricing-table-1 .price {
	display: inline-block;
	background: #898989;
	width: 100%;
}

.pricing-table-1 .price-figure {
	font-size: 24px;
	text-transform: uppercase;
	padding-top: 14px;
}

.pricing-table-1 .price-number {
	font-weight: 600;
	display: block;
	margin-bottom: 0;
}

.pricing-table-1 .price-tenure {
	font-size: 11px;
	margin-top: 0;
}

.pricing-table-1 .features {
	background: #e6e6e6;
	color: #555555;
	padding: 0;
	margin: 0;
}

.pricing-table-1 .features li {
	padding: 8px 15px;
	border-bottom: 1px solid #ccc;
	list-style-type: none;
}

.pricing-table-1 .price-footer {
	display: block;
	background: #2c3e50;
	border-radius: 0 0 6px 6px;
	margin-top: -5px;
	padding: 10px 0;
}


/* Hover Effects */
.pricing-table-1 .price-block:hover {
	transform: scale(1.04) translateY(-5px);
	-webkit-transform: scale(1.04) translateY(-5px);
	z-index: 1;
	border-bottom: 0 none;
}

.pricing-table-1 .price-block:hover .price.pastel-blue { background: #6082EB; }
.pricing-table-1 .price-block:hover .price.pastel-green { background: #18B29D; }
.pricing-table-1 .price-block:hover .price.pastel-red { background: #FF5E65; }
.pricing-table-1 .price-block:hover .price.pastel-orange { background: #FFB068; }


.pricing-table-1 .price-block:hover h3 {
	background: #233447;
}

/* Ribbons */
.ribbon {
	position: absolute;
	top: -4px;
	right: 11px;
	width: 82px;
	height: 82px;
	overflow: hidden;
	z-index: 10
}

/* .red-hot { background: url("../images/ribbons/red-hot.png") 0 0 no-repeat; } 
.best-value { background: url("../images/ribbons/best-value.png") 0 0 no-repeat; }
.super-cool { background: url("../images/ribbons/super-cool.png") 0 0 no-repeat; }
.new { background: url("../images/ribbons/new.png") 0 0 no-repeat; }
.premium { background: url("../images/ribbons/premium.png") 0 0 no-repeat; }
.exclusive { background: url("../images/ribbons/exclusive.png") 0 0 no-repeat; }
.popular { background: url("../images/ribbons/popular.png") 0 0 no-repeat; }
.five-stars { background: url("../images/ribbons/five-stars.png") 0 0 no-repeat; }*/



/*	Pricing Tables 1 MEDIA QUERIES
/*----------------------------------------------------*/



/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	.pricing-table-1 .price-block {
		margin-bottom: 20px;
    }
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}

/*
Template Name: Bootstrap Starter Kit - Pricing Tables 2
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/






/*	Pricing Table 2
/*----------------------------------------------------*/

.pricing-table-2 .tables-row {
	margin-top: 40px;
}

.pricing-table-2 .price-block {
	max-width: 500px;
	margin: 0 auto 40px auto;
	-webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.pricing-table-2 .price-block header {
	background: #2F4052;
	text-align: center;
	padding: 50px;
}

.pricing-table-2 .price-block h2 {
	font-size: 20px;
	font-weight: 400;
	letter-spacing: .15em;
	text-transform: uppercase;
	color: #FFF;
}

.pricing-table-2 .price-block .btn {
	margin-bottom: 0;
}

.pricing-table-2 .price-block .price {
	margin-bottom: 15px;
	color: #ffffff;
}

.pricing-table-2 .price-block .price * {
	font-family: 'Lato', sans-serif;
	line-height: 100%;
}

.pricing-table-2 .price-block .price .amount {
	font-size: 120px;
	font-weight: 900;
}

.pricing-table-2 .price-block .price .currency {
	position: relative;
	top: 16px;
	font-size: 24px;
	vertical-align: top;
	margin-right: 5px;
}

.pricing-table-2 .price-block .price .period {
	font-size: 16px;
	text-transform: uppercase;
}

.pricing-table-2 .price-block .features {
	background: #F5F7FA;
	padding: 25px 35px;
	border: 1px solid #E6E9ED;
	border-top: none;
	border-bottom-width: 2px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	margin-top: 0 !important;
}

.pricing-table-2 .price-block .features li {
	padding: 15px 0;
 	border-top: 1px solid #BCC7D1;
	list-style: none;
}

.pricing-table-2 .price-block .features li:first-child {
	border: none;
}

.pricing-table-2 .price-block .features li i {
	margin-right: 10px;
}

.pricing-table-2 .col-4 .price-block header {
	padding: 40px;
}

.pricing-table-2 .col-4 .price-block h2 {
	font-size: 18px;
}

.pricing-table-2 .col-4 .price-block .price .amount {
	font-size: 90px;
}

.pricing-table-2 .col-4 .price-block .features {
	padding: 20px 30px;
}

/* Ribbon & Colors */
.ribbon {
	position: absolute;
	top: -4px;
	right: 11px;
	width: 82px;
	height: 82px;
	overflow: hidden;
	z-index: 10
}

/* .red-hot { background: url("../images/ribbons/red-hot.png") 0 0 no-repeat; } 
.best-value { background: url("../images/ribbons/best-value.png") 0 0 no-repeat; }
.super-cool { background: url("../images/ribbons/super-cool.png") 0 0 no-repeat; }
.new { background: url("../images/ribbons/new.png") 0 0 no-repeat; }
.premium { background: url("../images/ribbons/premium.png") 0 0 no-repeat; }
.exclusive { background: url("../images/ribbons/exclusive.png") 0 0 no-repeat; }
.popular { background: url("../images/ribbons/popular.png") 0 0 no-repeat; }
.five-stars { background: url("../images/ribbons/five-stars.png") 0 0 no-repeat; }*/



/*	Pricing Table 2 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}

/*
Template Name: Bootstrap Starter Kit - Contact 1
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/






/*	CONTACT 1
/*----------------------------------------------------*/

.contact-1 .form-container {
	margin-top: 60px;
}

.contact-1 .contact-info {
	margin-top: 40px;
	padding: 0;
	list-style: none;
}

.contact-1 .contact-info span {
	margin-right: 7px;
	color: #1abc9c;
	padding-right: 5px;
}

.contact-1 .contact-info li {
	display: inline-block;
	margin: 0 10px;
	font-size: 15px;
	font-weight: 700;
	color: #2e2e2e
}

.contact-1 .contact-info li a {
	color: #2e2e2e
}

.contact-1 .contact-info li a:hover {
	color: #1abc9c
}

#map {
    width:100%;
    height:350px;
}

#contact span.required {
	font-size: 13px;
	color: #ff0000;
}

#message {
	margin-bottom: 20px;
	padding: 0;
}

.error_message {
	display: block;
	background: #FBE3E4;
	padding: 10px 0;
	color: #8a1f11;
	border: 2px solid #FBC2C4;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
}


/*	CONTACT 1 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}

/*
Template Name: Bootstrap Starter Kit - Contact 3
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/






/*	CONTACT 3
/*----------------------------------------------------*/

.contact-3 h2 {
	margin-top: 0;
}

.contact-3 .map-img {
	margin-top: 40px;
}

#contact span.required {
	font-size: 13px;
	color: #ff0000;
}

#message {
	margin-bottom: 20px;
	padding: 0;
}

.error_message {
	display: block;
	background: #FBE3E4;
	padding: 10px 0 10px 15px;
	color: #8a1f11;
	border: 2px solid #FBC2C4;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
}


/*	CONTACT 3 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}

/*
Template Name: Bootstrap Starter Kit - Blog 1
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/






/*	Blog 1
/*----------------------------------------------------*/

.blog-1 .post {
	text-align: center;
	padding: 10px 0;
}

.blog-1 .post img {
	margin: 30px 0;
}







/*	Blog 1 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
	
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	
	
	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}

/*
Template Name: Bootstrap Starter Kit - Footers 1
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/






/*	FOOTER 1-1
/*----------------------------------------------------*/

.footer-1-1 {
	padding-top: 60px;
	padding-bottom: 20px;
}

.footer-1-1 h3, .footer-1-1 h4, .footer-1-1 .lead, .footer-1-1 li {
	color: #ffffff;
}

.footer-1-1 .brand-img {
	width: 200px;
	margin-bottom: 30px;
}

.footer-1-1 h4 {
	margin-bottom: 20px;
}

.footer-1-1 ul {
	list-style-type: none;
	margin: 0 0 26px;
	padding: 0;
}

.footer-1-1 ul li {
	border-top: 1px solid #bdc3c7;
	line-height: 19px;
	padding: 6px 0;
}

.footer-1-1 li:first-child {
	border-top: none;
	padding-top: 1px;
}


/*	FOOTER 1-1 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}




/*	FOOTER 1-2
/*----------------------------------------------------*/

.footer-1-2 {
	padding-top: 40px;
	padding-bottom: 30px;
}

.footer-1-2 .brand-img {
	margin: 0 auto;
	width: 150px;
}

.big-social {
	margin-top: 20px;
}

.big-social .social-item {
	text-align: center;
}

.big-social .social-item a {
	display: block;
	border-radius: 4px;
	padding: 18px;
	color: #ffffff;
	font-size: 22px;
	text-decoration: none;
}

.big-social .social-item a h4 {
	color: #ffffff;
	visibility: hidden;
	margin-bottom: 0;
}

.big-social .social-item a:hover h4, .big-social .social-item a:focus h4 { visibility:visible; }

.big-social .social-item.facebook a:hover, .big-social .social-item.facebook a:focus { background-color:#016bb6; }
.big-social .social-item.twitter a:hover, .big-social .social-item.twitter a:focus { background-color:#08c1e2; }
.big-social .social-item.google a:hover, .big-social .social-item.google a:focus { background-color:#d94b34; }


/*	FOOTER 1-2 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}




/*	FOOTER 1-3
/*----------------------------------------------------*/

.footer-1-3 {
	padding-top: 40px;
	padding-bottom: 10px;
}

.footer-1-3 .brand-img {
	width: 180px;
}

.footer-1-3 .social {
	margin-top: 20px;
}

.footer-1-3 .address-bold-line {
	font-weight: 700;
	margin: 0;
	color: #ffffff;
}

.footer-1-3 .address {
	color: #ffffff;
}

.footer-1-3 .footer-text {
	border-top: #ecf0f1 1px solid;
	text-align: center;
	margin-top: 10px;
}

.footer-1-3 .footer-text p {
	color: #ecf0f1;
	
}


/*	FOOTER 1-3 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}




/*	FOOTER 1-4
/*----------------------------------------------------*/

.footer-1-4 {
	padding-top: 60px;
	padding-bottom: 40px;
	text-align: center;
}

.footer-1-4 h2 {
	color: #ffffff;
}

.footer-1-4 .footer-form {
	margin-top: 40px;
}

.footer-1-4 input[type=text], .footer-1-4 input[type=email] {
	background-color: rgba(255, 255, 255, 0.1);
	border: solid 1px #fff;
	padding: 20px;
	width: 30%;
	color: #fff;
}

.footer-1-4 input[type=submit] {
	background: rgba(61, 201, 179, 0.5);
	border: solid 1px rgba(61, 201, 179, 0.5);
	padding: 20px;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.footer-1-4 input[type=submit]:hover {
	background: rgba(61, 201, 179, 1);
	border: solid 1px rgba(61, 201, 179, 1);
	color: #fff;
}

.footer-1-4 .social {
	margin-top: 30px;
}


/*	FOOTER 1-4 MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	
		
	
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	

	
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	
	
	
}




/*	FOOTER Copyright Bar
/*----------------------------------------------------*/

.copyright-bar {
	height: 70px;
	padding-top: 20px;
	background-color: #233447;
	position: relative;
	bottom: 0;
}

.copyright-bar p {
	color: #ffffff;
	font-weight: 700;
	margin: 0;
}




/*	FOOTER Copyright Bar 2
/*----------------------------------------------------*/

.copyright-bar-2 {
	height: 80px;
	padding-top: 28px;
	background-color: #233447;
	position: relative;
	bottom: 0;
}

.copyright-bar-2 p {
	color: #ffffff;
	font-weight: 700;
	margin: 0;
}



/*END BLOCK */



/* STYLE LIBRARY */



/*
Template Name: Bootstrap Starter Kit - Style Library 1
Description: Business Startup & Prototyping HTML Framework
Author: Theme Spirit
Author URI: http://www.creativemarket.com/theme_spirit

Version: 1.0

*/

/*	PATTERN LIBRARY STYLES
/*----------------------------------------------------*/

body
{
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    color: #333333;
    background: #ffffff;
    overflow-x: hidden;
    /*text-rendering: optimizeLegibility;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* BACKGROUND COLORS */
.bg-transparent
{
    background-color: transparent !important;
}

.bg-black
{
    background-color: #111111 !important;
}

.bg-white
{
    background-color: #FFFFFF !important;
}

.bg-offwhite
{
    background-color: #F5F5F5 !important;
}

.bg-clouds
{
    background-color: #ECF0F1 !important;
}

.bg-silver
{
    background-color: #BDC3C7 !important;
}

.bg-concrete
{
    background-color: #95A5A6 !important;
}

.bg-asbestos
{
    background-color: #7F8C8d !important;
}

.bg-tan
{
    background-color: #D1DBBD !important;
}

.bg-pewter
{
    background-color: #91AA9D !important;
}

.bg-moonlight
{
    background-color: #3E606F !important;
}

.bg-deepocean
{
    background-color: #193441 !important;
}

.bg-turquoise
{
    background-color: #1ABC9C !important;
}

.bg-aqua
{
    background-color: #16A085 !important;
}

.bg-sunflower
{
    background-color: #F1C40F !important;
}

.bg-orange
{
    background-color: #F39C12 !important;
}

.bg-sienna
{
    background-color: #BF4E19 !important;
}

.bg-milanored
{
    background-color: #AC1C00 !important;
}

.bg-river
{
    background-color: #3498DB !important;
}

.bg-marina
{
    background-color: #2980B9 !important;
}

.bg-deco
{
    background-color: #C9D787 !important;
}

.bg-crete
{
    background-color: #7D8A2E !important;
}

.bg-guardsman
{
    background-color: #D90000 !important;
}

.bg-pomegranate
{
    background-color: #C0392B !important;
}

.bg-finn
{
    background-color: #723147 !important;
}

.bg-tamarind
{
    background-color: #31152B !important;
}



.bg-transparent-hover:hover
{
    background-color: transparent !important;
}

.bg-black-hover:hover
{
    background-color: #111111 !important;
}

.bg-white-hover:hover
{
    background-color: #FFFFFF !important;
}

.bg-offwhite-hover:hover
{
    background-color: #F5F5F5 !important;
}

.bg-clouds-hover:hover
{
    background-color: #ECF0F1 !important;
}

.bg-silver-hover:hover
{
    background-color: #BDC3C7 !important;
}

.bg-concrete-hover:hover
{
    background-color: #95A5A6 !important;
}

.bg-asbestos-hover:hover
{
    background-color: #7F8C8d !important;
}

.bg-tan-hover:hover
{
    background-color: #D1DBBD !important;
}

.bg-pewter-hover:hover
{
    background-color: #91AA9D !important;
}

.bg-moonlight-hover:hover
{
    background-color: #3E606F !important;
}

.bg-deepocean-hover:hover
{
    background-color: #193441 !important;
}

.bg-turquoise-hover:hover
{
    background-color: #1ABC9C !important;
}

.bg-aqua-hover:hover
{
    background-color: #16A085 !important;
}

.bg-sunflower-hover:hover
{
    background-color: #F1C40F !important;
}

.bg-orange-hover:hover
{
    background-color: #F39C12 !important;
}

.bg-sienna-hover:hover
{
    background-color: #BF4E19 !important;
}

.bg-milanored-hover:hover
{
    background-color: #AC1C00 !important;
}

.bg-river-hover:hover
{
    background-color: #3498DB !important;
}

.bg-marina-hover:hover
{
    background-color: #2980B9 !important;
}

.bg-deco-hover:hover
{
    background-color: #C9D787 !important;
}

.bg-crete-hover:hover
{
    background-color: #7D8A2E !important;
}

.bg-guardsman-hover:hover
{
    background-color: #D90000 !important;
}

.bg-pomegranate-hover:hover
{
    background-color: #C0392B !important;
}

.bg-finn-hover:hover
{
    background-color: #723147 !important;
}

.bg-tamarind-hover:hover
{
    background-color: #31152B !important;
}


/* BG COVER - MTCH */

.bg-image-cover
{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

/* COLORS - MTCH added !important */

.black
{
    color: #111111 !important;
}

.white
{
    color: #FFFFFF !important;
}

.offwhite
{
    color: #F5F5F5 !important;
}

.clouds
{
    color: #ECF0F1 !important;
}

.silver
{
    color: #BDC3C7 !important;
}

.concrete
{
    color: #95A5A6 !important;
}

.asbestos
{
    color: #7F8C8d !important;
}

.tan
{
    color: #D1DBBD !important;
}

.pewter
{
    color: #91AA9D !important;
}

.moonlight
{
    color: #3E606F !important;
}

.deepocean
{
    color: #193441 !important;
}

.turquoise
{
    color: #1ABC9C !important;
}

.aqua
{
    color: #16A085 !important;
}

.sunflower
{
    color: #F1C40F !important;
}

.orange
{
    color: #F39C12 !important;
}

.sienna
{
    color: #BF4E19 !important;
}

.milanored
{
    color: #AC1C00 !important;
}

.river
{
    color: #3498DB !important;
}

.marina
{
    color: #2980B9 !important;
}

.deco
{
    color: #C9D787 !important;
}

.crete
{
    color: #7D8A2E !important;
}

.guardsman
{
    color: #D90000 !important;
}

.pomegranate
{
    color: #C0392B !important;
}

.finn
{
    color: #723147 !important;
}

.tamarind
{
    color: #31152B !important;
}


.black-hover:hover
{
    color: #111111 !important;
}

.white-hover:hover
{
    color: #FFFFFF !important;
}

.offwhite-hover:hover
{
    color: #F5F5F5 !important;
}

.clouds-hover:hover
{
    color: #ECF0F1 !important;
}

.silver-hover:hover
{
    color: #BDC3C7 !important;
}

.concrete-hover:hover
{
    color: #95A5A6 !important;
}

.asbestos-hover:hover
{
    color: #7F8C8d !important;
}

.tan-hover:hover
{
    color: #D1DBBD !important;
}

.pewter-hover:hover
{
    color: #91AA9D !important;
}

.moonlight-hover:hover
{
    color: #3E606F !important;
}

.deepocean-hover:hover
{
    color: #193441 !important;
}

.turquoise-hover:hover
{
    color: #1ABC9C !important;
}

.aqua-hover:hover
{
    color: #16A085 !important;
}

.sunflower-hover:hover
{
    color: #F1C40F !important;
}

.orange-hover:hover
{
    color: #F39C12 !important;
}

.sienna-hover:hover
{
    color: #BF4E19 !important;
}

.milanored-hover:hover
{
    color: #AC1C00 !important;
}

.river-hover:hover
{
    color: #3498DB !important;
}

.marina-hover:hover
{
    color: #2980B9 !important;
}

.deco-hover:hover
{
    color: #C9D787 !important;
}

.crete-hover:hover
{
    color: #7D8A2E !important;
}

.guardsman-hover:hover
{
    color: #D90000 !important;
}

.pomegranate-hover:hover
{
    color: #C0392B !important;
}

.finn-hover:hover
{
    color: #723147 !important;
}

.tamarind-hover:hover
{
    color: #31152B !important;
}

.no-shadow {
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -ms-box-shadow:none !important;
}

/* PADDING, MARGIN & ALIGNMENT */

.pad0
{
    padding-top: 0px !important;
}
.pad5
{
    padding-top: 5px !important;
}

.pad10
{
    padding-top: 10px !important;
}

.pad15
{
    padding-top: 15px !important;
}

.pad20
{
    padding-top: 20px !important;
}

.pad25
{
    padding-top: 25px !important;
}

.pad30
{
    padding-top: 30px !important;
}

.pad45
{
    padding-top: 45px !important;
}

.pad60
{
    padding-top: 60px !important;
}

.pad90
{
    padding-top: 90px !important;
}

.pad-bottom0
{
    padding-bottom: 0px !important;
}
.pad-bottom5
{
    padding-bottom: 5px !important;
}

.pad-bottom10
{
    padding-bottom: 10px !important;
}

.pad-bottom15
{
    padding-bottom: 15px !important;
}

.pad-bottom20
{
    padding-bottom: 20px !important;
}

.pad-bottom25
{
    padding-bottom: 25px !important;
}

.pad-bottom30
{
    padding-bottom: 30px !important;
}

.pad-bottom45
{
    padding-bottom: 45px !important;
}

.pad-bottom60
{
    padding-bottom: 60px !important;
}

.pad-bottom90
{
    padding-bottom: 90px !important;
}

.margin-bottom0
{
    margin-bottom: 0px !important;
}
.margin-bottom5
{
    margin-bottom: 5px !important;
}

.margin-bottom10
{
    margin-bottom: 10px !important;
}

.margin-bottom15
{
    margin-bottom: 15px !important;
}

.margin-bottom20
{
    margin-bottom: 20px !important;
}


.margin-bottom25
{
    margin-bottom: 25px !important;
}

.margin-bottom30
{
    margin-bottom: 30px !important;
}

.margin-bottom45
{
    margin-bottom: 45px !important;
}

.margin-bottom60
{
    margin-bottom: 60px !important;
}

.margin-bottom90
{
    margin-bottom: 90px !important;
}

.margin-top0
{
    margin-top: 0px !important;
}
.margin-top5
{
    margin-top: 5px !important;
}

.margin-top10
{
    margin-top: 10px !important;
}

.margin-top15
{
    margin-top: 15px !important;
}

.margin-top20
{
    margin-top: 20px !important;
}

.margin-top25
{
    margin-top: 25px !important;
}

.margin-top30
{
    margin-top: 30px !important;
}

.margin-top45
{
    margin-top: 45px !important;
}

.margin-top60
{
    margin-top: 60px !important;
}

.margin-top90
{
    margin-top: 90px !important;
}

.min-height-100px
{
    min-height: 100px !important;
}

.min-height-200px
{
    min-height: 200px !important;
}

.min-height-300px
{
    min-height: 300px !important;
}

.min-height-400px
{
    min-height: 400px !important;
}

.min-height-500px
{
    min-height: 500px !important;
}

.min-height-600px
{
    min-height: 600px !important;
}

.min-height-700px
{
    min-height: 700px !important;
}

.min-height-800px
{
    min-height: 800px !important;
}

@media only screen and (max-width : 767px){

    .min-height-xs-100px
    {
        min-height: 100px !important;
    }

    .min-height-xs-200px
    {
        min-height: 200px !important;
    }

    .min-height-xs-300px
    {
        min-height: 300px !important;
    }

    .min-height-xs-400px
    {
        min-height: 400px !important;
    }

    .min-height-xs-500px
    {
        min-height: 500px !important;
    }

    .min-height-xs-600px
    {
        min-height: 600px !important;
    }

    .min-height-xs-700px
    {
        min-height: 700px !important;
    }

    .min-height-xs-800px
    {
        min-height: 800px !important;
    }

}

.marg-bottom5
{
    margin-bottom: 5px !important;
}

.marg-left5
{
    margin-left: 5px !important;
}

.marg-right5
{
    margin-right: 5px !important;
}

.but_marg
{
    margin-left: 3px !important;
}

.v-center
{
    display: inline-block;
    vertical-align: middle;
    float: none;
}

/** TYPOGRAPHY **/

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6
{
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    line-height: 1.1;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small
{
    color: #e5e5e5;
}

h1, h2, h3
{
    margin-bottom: 20px;
    font-weight: 300;
}

h4, h5, h6
{
    margin-bottom: 15px;
    font-weight: 400;
}

h1, .h1
{
    font-size: 44px;
}

h2, .h2
{
    font-size: 37px;
}

h3, .h3
{
    font-size: 27px;
}

h4, .h4
{
    font-size: 23px;
}

h5, .h5
{
    font-size: 18px;
}

h6, .h6
{
    font-size: 16px;
}

p
{
    /*font-family: "Open Sans", Helvetica, Arial, sans-serif;*/
    font-size: 14px;
    line-height: 1.6;
    margin: 15px 0;
    color: #515157;
}

.lead
{
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 300;
}

small, .small
{
    font-size: 80%;
    line-height: 2;
}

em
{
    color: #ff6600;
}

/* LINKS */

a { color: #1abc9c; }

a:hover {
    color: #16a085;
    text-decoration: none;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
/* LISTS */

ol
{
    position: relative;
    padding-left: 22px;
}

ul
{
    list-style: none;
    position: relative;
    padding-left: 22px;
}

ul li,
ol li
{
    /*font-family: "Open Sans", Helvetica, Arial, sans-serif;*/
    font-size: 14px;
    color: #515157;
    margin-bottom: 5px;
}

ul.circle li:before
{
    font-family: 'fontawesome';
    content: '\f10c';
    font-size: 12px;
    color: #16a085;
    padding-top: 2px;
    margin-left: -20px;
    position: absolute;
}

/* TITLES */

.underlined-title
{
    text-align: center;
    padding-bottom: 30px;
}

.underlined-title h1
{
    color: #2c3e50;
    margin: 0;
}

.underlined-title h2
{
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    color: #95a5a6;
}

.underlined-title hr
{
    width: 10%;
    border-width: 2px;
    border-color: #16a085;
}

/* IMAGES */

img
{
}

/*===================================================================================*/

/*	BUTTONS
/*===================================================================================*/

.btn
{
    color: #FFF;
    margin: 10px 0;
    /*font-family: "Open Sans", Helvetica, Arial, sans-serif;*/
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-shadow: none;
    border: none;
    text-transform: uppercase;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
}

.modal-footer .btn+.btn {
    margin-bottom: 15px;
}

.btn-sm
{
    padding: 7px 13px;
    font-size: 12px;
}

.btn-lg
{
    padding: 16px 26px;
    font-size: 16px;
}

.btn-xl
{
    padding: 18px 30px;
    font-size: 24px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
}

h1 .btn,
h2 .btn,
h3 .btn,
h4 .btn,
p .btn
{
    margin-left: 15px;
}

/* Button DISABLED */

.disabled a
{
    cursor: not-allowed;
}

/* Button DEFAULT */

.btn.btn-default
{
    background: #ecf0f1;
    box-shadow: 0 2px #bdc3c7;
    color: #999;
}

.btn.btn-default:hover
{
    background: #bdc3c7;
}

/* Button PRIMARY */

.btn.btn-primary
{
    background: #1abc9c;
    box-shadow: 0 2px #16a085;
}

.btn.btn-primary:hover
{
    background: #16a085;
}

/* Button WARNING */

.btn.btn-warning
{
    background: #f1c40f;
    box-shadow: 0 2px #f39c12;
}

.btn.btn-warning:hover
{
    background: #f39c12;
}

/* Button DANGER */

.btn.btn-danger
{
    background: #e74c3c;
    box-shadow: 0 2px #c0392b;
}

.btn.btn-danger:hover
{
    background: #c0392b;
}

/* Button SUCCESS */

.btn.btn-success
{
    background: #2ecc71;
    box-shadow: 0 2px #27ae60;
}

.btn.btn-success:hover
{
    background: #27ae60;
}

/* Button DARK */

.btn.btn-dark
{
    background: #34495e;
    box-shadow: 0 2px #2c3e50;
    color: #ffffff;
}

.btn.btn-dark:hover
{
    background: #2c3e50;
}

/* Button INFO */

.btn.btn-info
{
    background: #3498db;
    box-shadow: 0 2px #2980b9;
}

.btn.btn-info:hover
{
    background: #2980b9;
}

/* Outline Buttons */

.btn-outline
{
    color: #FFF;
    padding: 12px 20px;
    margin: 15px 0;
    /*font-family: "Open Sans", Helvetica, Arial, sans-serif;*/
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.1em;
    text-shadow: none;
    border: none;
    text-transform: uppercase;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
}

.btn-outline:hover
{
    text-decoration: none;
    color: #ffffff;
}

.btn-outline-sm
{
    padding: 5px 13px;
    font-size: 13px;
}

.btn-outline-lg
{
    padding: 14px 26px;
    font-size: 15px;
}

.btn-outline-xl
{
    padding: 18px 30px;
    font-size: 24px;
}

.btn-outline.outline-light,
.icon.outline-light
{
    color: #ffffff;
    background: none;
    border: 2px solid #ffffff;
}

.btn-outline.outline-light:hover,
.icon.outline-light:hover
{
    color: #2c3e50;
    background: #ffffff;
}

.btn-outline.outline-dark,
.icon.outline-dark
{
    color: #2c3e50;
    background: none;
    border: 2px solid #2c3e50;
}

.btn-outline.outline-dark:hover,
.icon.outline-dark:hover
{
    color: #ffffff;
    background: #2c3e50;
}

button:hover
{
    text-decoration: none;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

/*===================================================================================*/

/*	SOCIAL ICONS
/*===================================================================================*/

.social
{
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    z-index: 100;
}

.social li
{
    display: inline-block;
    margin-right: 6px;
}

.social li a
{
    display: table;
    position: relative;
}

.social li a i
{
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    width: 34px;
    height: 34px;
    font-size: 18px;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
}

.social-light li a i
{
    color: #ffffff;
}

.social-dark li a i
{
    color: #666666;
}

.social li a i:after
{
    content: '';
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
}

.social li a:hover i
{
    color: #f0f0f0;
}

.social li a:hover i:after
{
    height: 100%;
}

.social a .fa-facebook:after
{
    background: #3b5998;
}

.social a .fa-google-plus:after
{
    background: #dd4b39;
}

.social a .fa-twitter:after
{
    background: #00aced;
}

.social a .fa-pinterest:after
{
    background: #CB2027;
}

.social a .fa-behance:after
{
    background: #1769FF;
}

.social a .fa-dribbble:after
{
    background: #EA4C89;
}

/* FORM CONTROL */

fieldset
{
    border: 2px solid #ecf0f1;
    border-radius: 6px;
    padding: 30px 50px;
}

fieldset legend
{
    color: #333;
    padding: 5px 10px;
    border: 2px solid #ecf0f1;
    border-radius: 6px;
}

.form-control
{
    border: 2px solid #ecf0f1;
    color: #34495e;
    /*font-family: "Open Sans", Helvetica, Arial, sans-serif;*/
    font-size: 14px;
    line-height: 1.467;
    padding: 8px 12px;
    height: 40px;
    border-radius: 6px;
    /*-webkit-appearance: none;
    
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
    transition: border .25s linear, color .25s linear, background-color .25s linear;*/
}

.form-group
{
    position: relative;
    margin-bottom: 20px;
}

.form-group.focus .form-control,
.form-control:focus
{
    border-color: #1abc9c;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control
{
    background-color: #f4f6f6;
    border-color: #d5dbdb;
    color: #d5dbdb;
    cursor: default;
    opacity: 0.7;
    filter: alpha(opacity=70);
}

.form-control.form-minimal
{
    border-color: transparent;
}

.form-control.form-minimal:hover
{
    border-color: #bdc3c7;
}

.form-control.form-minimal:focus
{
    border-color: #1abc9c;
}

.has-success .help-block,
.has-success .control-label
{
    color: #2ecc71;
}

.has-success .form-control
{
    color: #2ecc71;
    border-color: #2ecc71;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-success .form-control:-moz-placeholder
{
    color: #2ecc71;
    opacity: 1;
}

.has-success .form-control::-moz-placeholder
{
    color: #2ecc71;
    opacity: 1;
}

.has-success .form-control:-ms-input-placeholder
{
    color: #2ecc71;
}

.has-success .form-control::-webkit-input-placeholder
{
    color: #2ecc71;
}

.has-success .form-control.placeholder
{
    color: #2ecc71;
}

.has-success .form-control:focus
{
    border-color: #2ecc71;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-success .input-group-addon
{
    color: #2ecc71;
    border-color: #2ecc71;
    background-color: #ffffff;
}

.has-warning .help-block,
.has-warning .control-label
{
    color: #f1c40f;
}

.has-warning .form-control
{
    color: #f1c40f;
    border-color: #f1c40f;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-warning .form-control:-moz-placeholder
{
    color: #f1c40f;
    opacity: 1;
}

.has-warning .form-control::-moz-placeholder
{
    color: #f1c40f;
    opacity: 1;
}

.has-warning .form-control:-ms-input-placeholder
{
    color: #f1c40f;
}

.has-warning .form-control::-webkit-input-placeholder
{
    color: #f1c40f;
}

.has-warning .form-control.placeholder
{
    color: #f1c40f;
}

.has-warning .form-control:focus
{
    border-color: #f1c40f;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-warning .input-group-addon
{
    color: #f1c40f;
    border-color: #f1c40f;
    background-color: #ffffff;
}

.has-error .help-block,
.has-error .control-label
{
    color: #e74c3c;
}

.has-error .form-control
{
    color: #e74c3c;
    border-color: #e74c3c;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-error .form-control:-moz-placeholder
{
    color: #e74c3c;
    opacity: 1;
}

.has-error .form-control::-moz-placeholder
{
    color: #e74c3c;
    opacity: 1;
}

.has-error .form-control:-ms-input-placeholder
{
    color: #e74c3c;
}

.has-error .form-control::-webkit-input-placeholder
{
    color: #e74c3c;
}

.has-error .form-control.placeholder
{
    color: #e74c3c;
}

.has-error .form-control:focus
{
    border-color: #e74c3c;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-error .input-group-addon
{
    color: #e74c3c;
    border-color: #e74c3c;
    background-color: #ffffff;
}

/* FORM - INPUT ICON */

.form-control + .input-icon
{
    position: absolute;
    top: 2px;
    right: 2px;
    line-height: 37px;
    vertical-align: middle;
    font-size: 20px;
    color: #b2bcc5;
    background-color: #ffffff;
    padding: 0 12px 0 0;
    border-radius: 6px;
}

.input-hg + .input-icon
{
    line-height: 49px;
    padding: 0 16px 0 0;
}

.input-lg + .input-icon
{
    line-height: 41px;
    padding: 0 15px 0 0;
}

.input-sm + .input-icon
{
    font-size: 18px;
    line-height: 30px;
    padding: 0 10px 0 0;
}

/* FORM - INPUT GROUP */

.input-group .input-group-btn .btn
{
    margin: 0;
    box-shadow: none;
}

/*	ACCORDION / TOGGLE */

.panel-group
{
    margin-bottom: 0;
}

.panel-group + .panel-group
{
    margin-top: 20px;
}

.panel-group .panel
{
    background: #fff;
    border: 1px solid #E6E9ED;
    border-bottom-width: 2px;
    border-radius: 3px;
    box-shadow: none;
}

.panel-group .panel + .panel
{
    margin-top: 10px;
}

.panel-group .panel-heading + .panel-collapse .panel-body
{
    border-top-color: #E6E9ED;
}

.panel-body
{
    padding: 25px;
}

.panel-heading
{
    background-color: inherit !important;
    color: inherit !important;
    padding: 0;
    border-color: inherit !important;
}

.panel-title
{
    /*font-family: "Open Sans", Helvetica, Arial, sans-serif;*/
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
}

.panel-title > a
{
    display: block;
    padding: 15px 25px 15px;
    text-decoration: none;
    color: #fff;
}

.panel-title > a.collapsed
{
    background: #f5f5f5;
}

.panel-title > a.collapsed,
.panel-title > a.collapsed span
{
    color: #16A085;
}

.panel-title > a,
.panel-title > a:hover
{
    background: #16A085;
    color: #fff;
}

.panel-title > a span
{
    display: block;
    position: relative;
    top: 0;
    color: #fff;
    padding-right: 25px;
}

.text-right .panel-title > a span
{
    padding-left: 25px;
    padding-right: 0;
}

.text-center .panel-title > a span
{
    padding-left: 0;
    padding-right: 0;
}

.panel-title > a:hover span
{
    color: inherit;
}

.text-center .panel-title > a:hover span
{
    top: .5em;
    color: transparent;
}

.panel-title > a span:after
{
    font-family: "fontawesome";
    content: "\f077";
    position: absolute;
    top: 0;
    right: 0;
}

.text-right .panel-title > a span:after
{
    left: 0;
    right: auto;
}

.text-center .panel-title > a span:after
{
    top: -.75em;
    left: 0;
    opacity: 0;
}

.panel-title > a.collapsed span:after
{
    content: "\f078";
}

/* TABS */

.nav-tabs
{
    margin: 0 auto;
}

.nav-tabs > li
{
    z-index: 2;
    float: none;
    display: inline-block;
}

.nav-tabs > li > a
{
    padding: 16px 25px 12px;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    color: #737c85;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: none !important;
    border-bottom: 4px solid transparent !important;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus
{
    background-color: transparent;
    border-bottom: 4px solid #85d6de !important;
}

.nav > li > a:hover,
.nav > li > a:focus
{
    outline: 0;
}

.tab-content
{
    padding-left: 0;
    padding-right: 0;
    border: none;
}

.tab-pane
{
    padding: 50px 0;
    border-bottom: 1px solid #ecf0f1;
}

/* PAGINATION */

.pagination li a
{
    /*font-family: "Open Sans", Helvetica, Arial, sans-serif;*/
    font-size: 14px;
    color: #16A085;
    background: none;
    margin: 0 3px;
    border: 1px solid #16A085;
    padding: 5px 12px;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus
{
    color: #16A085;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus
{
    background-color: #1ABC9C;
    border-color: #16A085;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus
{
    color: #999;
}

.pagination li:first-child a,
.pagination li:last-child a
{
    border-radius: 0 !important;
}

.pagination-lg li a
{
    padding: 8px 16px;
    font-size: 16px;
}

.pagination-sm li a
{
    padding: 4px 10px;
    font-size: 12px;
}

/* PAGER */

.pager li > a,
.pager li > span
{
    border: 1px solid #16A085;
    border-radius: 0;
    color: #16A085;
    /*font-family: "Open Sans", Helvetica, Arial, sans-serif;*/
    font-size: 14px;
    min-width: 160px;
}

.pager li > a:hover,
.pager li > a:focus
{
    border: 1px solid #ddd;
}

.pager .next > a,
.pager .next > span
{
    float: right;
}

.pager .previous > a,
.pager .previous > span
{
    float: left;
}

.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span
{
    color: #999;
    border: 1px solid #ddd;
}

/* CONTENT BLOCK HOLDERS */

.content-block
{
    width: 100%;
    padding: 60px 0 60px 0;
    z-index: 100;
    position: relative;
    background-color: #ffffff;
}

.content-block-nopad
{
    width: 100%;
    z-index: 100;
    position: relative;
    background-color: #ffffff;
}

/*	PATTERN LIBRARY MEDIA QUERIES
/*----------------------------------------------------*/

/* Small Devices, Tablets */

@media only screen and (min-width : 768px){
	
	
	
}

/* Medium Devices, Desktops */

@media only screen and (min-width : 992px){
	
	
	
}

/* Large Devices, Wide Screens */

@media only screen and (min-width : 1200px){
	
	
	
}


