.arrow-section{
    /* padding-top: 10px !important ; */
}

/*=========== ANT STYLE ============ */
.ant-input, .ant-select-selector, .ant-input-number-input {
    border-radius: 30px !important;
    /* height: 50px !important; */
}

.ant-select-arrow {
    /* height: 25px !important; */
}

.ant-table-content table {
    /* border-spacing: 0 15px !important; */
    background-color: transparent !important;
}

thead.ant-table-thead tr th {
    background-color: #000 !important;
    color: #fff !important;
    text-align: center;
    font-size: 14px !important;
}

tbody.ant-table-thead tr {
    border: 1px solid black;
}
table tr td {
    border: revert-layer;
}
.ant-table-body .ant-table-row {
    background-color: transparent !important;
    box-shadow: none;
}

.ant-form-item-label {
    padding: 0 !important;
}

.ant-form-item {
    margin-bottom: 0px !important;
}

.ant-select-multiple .ant-select-selection-placeholder, .ant-select-selector.ant-select-selection-placeholder {
    inset-inline-start: 11px !important;
    inset-inline-end: unset !important;
}

.ant-select-selection-placeholder {
    text-align: left !important;
}

.ant-divider {
    margin-top: 0 !important;
}
/* .ant-input:hover, .ant-input:focus   {
    border: 0 !important;
    border-radius: 30px !important;
    height: 50px !important;
} */


/*================================*/
.edit-padding {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.sub-section {
    padding: 15px 15px 0 15px !important;
}

.sign-in input, .sign-in button {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

#stepForm .step.active {
    opacity: 1;
}

#stepForm .step.active {
    opacity: 1;
}

#stepForm .step {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbbbbb;
    border: none;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.5;
}

#stepForm .step {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbbbbb;
    border: none;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.5;
}

.add-btn {
    background: #21893a !important;
    
}
.add-btn span {
    color: #fff !important;
}

input[inputmode]::placeholder, .ant-select-selection-search-input::placeholder {
    color: rgb(189, 188, 188);
    opacity: 1; /* Firefox */
  }

.ant-select-selection-placeholder {
    color: rgb(189, 188, 188) !important;
    opacity: 1; /* Firefox */
  }
  

  .resent-otp {
    color: #21893a !important;
  }

  .theme-maincolorback-light.* .anticon .anticon-check-circle {
    color: #21893a !important;
  }

  .btn-override {
    line-height: 2;
    font-size: 100px !important;
  }
.gc-sign-part {
    height: 100% !important;
}