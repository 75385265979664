

/* =Header
-------------------------------------------------------------- */

header
{
    width: 100%;
    height: 150px;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    -ms-transition: height 0.3s;
    -o-transition: height 0.3s;
    transition: height 0.3s;
    padding-left: 15px;
    padding-right: 15px;
}

header h1#logo
{
    display: inline-block;
    height: 150px;
    line-height: 150px;
    float: left;
    font-family: "Oswald", sans-serif;
    font-size: 18px;
    color: #000000;
    font-weight: 400;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    margin-top: 0px;
    text-shadow: 0px 1px #FFFFFF;
}

header h1#logo img
{
    width: 110px;
    margin-right: 9px;
}

header .smaller h1#logo img
{
    width: 50px;
    margin-right: 9px;
}

header nav
{
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;
    padding-right: 30px;
    height: 150px;
    line-height: 150px;
    float: right;
}

header .glyphicon
{
    font-size: 52px;
    margin-top: 45px;
}

header.smaller .glyphicon
{
    font-size: 52px;
    margin-top: 45px;
}

header.smaller nav
{
    display: inline-block;
    vertical-align: middle;
    background-color: transparent;
    padding-right: 30px;
    height: 70px;
    line-height: 70px;
    float: right;
}

header nav .navbar-nav > li > a
{
    font-size: 18px;
    color: #060606;
    display: inline-block;
    text-shadow: 0px 1px #FFFFFF;
    font-weight: bold;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    height: 100%;
}

header.smaller nav .navbar-nav > li > a
{
    font-size: 13px;
    color: #060606;
    height: 100%;
    display: inline-block;
    text-shadow: 0px 1px #FFFFFF;
    font-weight: bold;
}

header nav .navbar-nav > li
{
}

header nav .navbar-nav > li:hover
{
    background-color: red;
}

.scroll_header nav a
{
    height: auto;
    padding: 0 25px;
    font-size: 21px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

header nav a:hover
{
    color: white;
}

header.smaller
{
    height: 70px;
      -webkit-transition: all 0.2s ease;
     transition: all 0.2s ease;
}


header.smaller h1#logo
{
    width: 203px;
    height: 75px;
    line-height: 75px;
    font-size: 13px;
}

header.smaller h1#logo img
{
    width: 60px;
    line-height: 75px;
    font-size: 30px;
    margin-top: 0px;
}

.scroll_header.smaller nav a
{
    line-height: 75px;
}

/* =Media Queries
-------------------------------------------------------------- */

@media all and (max-width: 660px)
{
/* =Header
  -------------------------------------------------------------- */
    header h1#logo
    {
        display: block;
        float: left;
        height: 150px;
        line-height: 150px;
        text-align: center;
        width: 300px;
        margin-top: 0;
        margin-bottom: 0;
    }
    header nav
    {
        display: block;
        float: none;
        height: 50px;
        text-align: center;
        margin: 0 auto;
    }
    header nav a
    {
        line-height: 50px;
        margin: 0 10px;
    }
    header.smaller
    {
        height: 75px;
    }
    header.smaller h1#logo
    {
        height: 40px;
        line-height: 40px;
        font-size: 30px;
    }
    header.smaller nav
    {
        height: 35px;
    }
    header.smaller nav a
    {
        line-height: 35px;
    }
}

@media (max-width:767px)
{
    header h1#logo img
    {
        width: 90px;
    }
    header.smaller h1#logo
    {
        font-size: 13px;
        height: 75px;
        line-height: 75px;
    }
    header.smaller .glyphicon
    {
        margin-top: 20px;
        font-size: 35px;
    }
}

