
.button-component{
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: row;
   &__loader{
    width: 20px;
    height: 20px;
   }
}

.btn-grey {
   background: grey !important;
}