$color_1: #333;
$background-color_1: #f5f5f5;

.breadcrumb {
	background-color: $background-color_1;
	padding: 10px;
    margin-left: 12px;
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	li {
		display: inline;
		margin-right: 5px;
		&:last-child {
			font-weight: bold;
		}
		a {
			text-decoration: none;
			color: $color_1;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
