/* gc-apps */


.gcapps-mention
{
    color: #aba9a9;
    text-align: left;
    margin: 0px 30px 0px -6px;
    border-right: 1px solid #fdfdfd;
    height: 80px;
    line-height: 80px;
}

.gcapps-mention .glyphicon
{
    font-size: 25px;
    float: left;
}

.apps-line-data, .row.apps-line-data
{
    line-height: 80px;
    background: #fff;
    height: 80px;
    border-bottom: solid 1px #ccc;
}

.apps-line-data:hover
{
    filter: alpha(opacity=0);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    background-color: #F3F3F3;
}

.apps-data-bloc
{
    vertical-align: middle;
    display: inline-block;
    line-height: 80px;
    margin: 0px auto 0px auto;
    min-height: 80px;
}

.apps-data-bloc .glyphicon
{
    font-size: 15px;
    margin-right: 21px;
    border-radius: 31px;
    width: 40px;
    height: 40px;
    background-color: #bf2525;
    color: #ffffff;
    padding: 10px;
}

.align-vertical-middle
{
    vertical-align: middle;
    width: 100%;
    text-align: center;
    margin: 0px;
    line-height: 120%;
    display: inline-block;
}.full-height
{
}

.gc-list-elements
{
    padding: 0px;
    line-height: 140%;
    font-size: 12px;
    color: #565151;
    background: #FCFCFC;
    margin-right: 0px 0px 30px 0px;
}

.gc-list-elements-img
{
    width: 47px;
}

.gc-list-elements-bloc
{
    margin-left: 0px;
    margin-right: 0px;
    min-height: 70px;
    line-height: 70px;
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    border: 1px solid #f1f1f1;
}

.gc-list-elements-bloc:hover
{
    margin-left: 0px;
    background-color: #F3F3F3;
    margin-right: 0px;
    min-height: 70px;
    line-height: 70px;
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    border: 1px solid #f1f1f1;
}

.gc-list-elements-span
{
    margin-right: 8px;
    border-right: solid 1px gray;
    padding-right: 7px;
}

.gc-tabs-bloc
{
    line-height: 130%;
    padding: 0px;
    font-size: 16px;
    color: #807c7c;
    background-color: #fbf9f9;
}

.gc-tabs-bloc .glyphicon
{
    margin-right: 9px;
}

.nav-tabs li .active
{
}

.container
{
    border: 1px solid rgba(0,0,0,0.06);
}

.gc-simply-show .item
{
    padding: 0;
    height: 300px;
    margin-top: 30px;
    margin-right: 5px;
    margin-left: 5px;
}

@media (min-width: 1200px)
{
    .container
    {
        width: 1024px;
    }
}

.gcs-left-filter .tab-content
{
    padding: 10px 0px;
    font-weight: bold;
    color: #0a0101;
}

.glyphcon
{
    padding: 30px;
    font-weight: bold;
    color: #0a0101;
}

.gcs-left-filter .nav-tabs > li > a
{
    padding: 16px 25px 12px;
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    color: #737c85;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus
{
    color: #555;
    cursor: default;
    background-color: transparent;
    font-weight: bold;
}

.gcs-left-filter .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .gcs-left-filter .nav-tabs > li.active > a:focus
{
    font-size: 1em;
    background-color: rgba(255, 255, 255, 0);
    border-top: 1px solid #f1f1f1;
}

.gc-newsletter-block
{
    color: #423f3f;
    text-align: center;
    font-weight: lighter;
    padding: 18px 30px 30px;
    font-size: 1em;
    line-height: 120%;
}

.gc-menu-block
{
    margin-bottom: 25px;
    background: #394264;
    border-radius: 5px;
    background-color: #871313;
}

.gc-appone-header-menu li
{
    position: relative;
    -webkit-transform: translateZ(0);
}

.gc-appone-horizontal-list li
{
    float: left;
}

.gc-appone-header-menu .header-menu-number
{
    position: absolute;
    line-height: 22px;
    padding: 0 6px;
    font-weight: 700;
    background: #e64c65;
    border-radius: 100%;
    top: 15px;
    right: 2px;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
}

.gc-appone-header-menu a, .gc-appone-header-menu span
{
    color: #fff;
    font-size: 1em;
}

.gc-appone-horizontal-list
{
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.gc-appone-profile-menu
{
    float: right;
    height: 80px;
    padding: 20px 20px auto auto;
}

.appsv3-top-menu .gc-appone-profile-menu p
{
    font-size: 1em;
    display: inline-block;
    padding-right: 10px;
    color: #c1baba;
}

.small-profile-picture
{
    display: inline-block;
    vertical-align: middle;
}

.small-profile-picture
{
    border: 2px solid #50597b;
    width: 40px;
    height: 40px;
}

.profile-picture
{
    border-radius: 100%;
    overflow: hidden;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.menu-box-number
{
    width: 36px;
    line-height: 22px;
    background: #50597b;
    text-align: center;
    border-radius: 15px;
    position: absolute;
    top: 15px;
    right: 15px;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.menu-box-menu li
{
    height: 60px;
    position: relative;
}

.titular
{
    display: block;
    line-height: 60px;
    margin: 0;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #ffffff;
    background: #11a8ab;
}

.gc-menublock1
{
    margin-bottom: 25px;
    background: #394264;
    border-radius: 5px;
    padding-left: 0px;
    padding-right: 0px;
}

.gc-menublock1 .menu-box-tab:hover
{
    background: #50597b;
    border-top: 1px solid #1f253d;
    text-decoration: none;
}

.gc-menublock1 .menu-box-tab
{
    line-height: 60px;
    padding-left: 32px;
    display: block;
    border-bottom: 1px solid #1f253d;
    -webkit-transition: background .2s;
    transition: background .2s;
}

.gc-menublock1 .menu-box-menu li
{
    height: 60px;
    position: relative;
}

.gc-menublock1 ul
{
    list-style-type: none;
    margin: 0;
    padding-left: 0;
}

.gc-menublock1 a, .gc-menublock1 span
{
    color: #fff;
    text-decoration: none;
    font-size: 15px;
}

.gc-form-data
{
    border: none;
    padding: 15px;
}

.gc-form-data .search input, .gc-form-data .search input:focus
{
    color: #2c2525;
    font-size: 15px;
    margin: 0;
    padding: 0;
    outline: 0;
    width: 100%;
    height: 45px;
    line-height: 45px;
    transition-property: color;
    transition-duration: 0.3s;
    border: none;
}

.gc-form-data fieldset
{
    padding: 15px;
    border: solid 1px #f1f1f1;
}

.gc-form-data .form-control, .gc-form-data input, .gc-form-data select, .gc-form-data textarea
{
    filter: alpha(opacity=0);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 0px;
    border: solid 1px #ccc;
    font-size: 14px;
    height: 30px;
    width: 100%;
    padding: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.login-connectpart .form-control
{
    filter: alpha(opacity=0);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 0px;
    border: solid 1px #ccc;
    font-size: 14px;
    height: 40px;
    width: 100%;
    padding: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.gc-form-data .form-control:focus, .gc-form-data input:focus, .gc-form-data textarea:focus
{
    border-radius: 0px;
    border: solid 1px red;
    font-size: 14px;
    height: 30px;
    width: 100%;
    padding: 5px;
}

.login-connectpart .form-control:focus, .login-connectpart input:focus, .login-connectpart textarea:focus
{
    border-radius: 0px;
    border: solid 1px red;
    font-size: 14px;
    height: 40px;
    width: 100%;
    padding: 5px;
}

.gc-form-data .gc-filter .control-label
{
    border: solid 1px #D1D1D7;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    padding: 5px 15px 5px 30px;
    display: inline-block;
    font-family: "Open Sans",Arial,sans-serif;
    font-weight: bold;
    background-color: #f3efef;
}

.gc-form-data .gc-filter .control-label:hover
{
    border: solid 1px #D1D1D7;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    padding: 5px 15px 5px 30px;
    display: inline-block;
    font-family: "Open Sans",Arial,sans-serif;
    font-weight: bold;
    background-color: #fff;
}

.gc-form-datav2 fieldset legend
{
    color: #c71212;
    background-color: #E1E1E1;
    width: 110%;
    margin-left: -20px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
    border-radius: 1px;
}

.gc-form-datav2 fieldset table
{
    margin-left: 0px;
    width: 100%;
    margin-right: 0px;
}

.gc-form-data .form-group
{
    margin-top: 15px;
    margin-bottom: 15px;
    float: none;
}

.gc-form-datav2 button, html .gc-form-datav2 input[type="button"], .gc-form-datav2 input[type="reset"], .gc-form-datav2 input[type="submit"]
{
    background: linear-gradient(#fe9100, #da4800);
    background-color: #fe9100;
    border: none;
    display: inline-block;
    border-radius: 6px;
    position: relative;
    text-align: center;
    cursor: pointer;
    margin: 10px 10px 10px 0px;
    vertical-align: middle;
    font-size: 11px;
    font-weight: bold;
    height: 35px;
}

.gc-form-datav2 .search button, html .gc-form-datav2 .search input[type="button"], .gc-form-datav2 .search input[type="reset"], .gc-form-datav2 .search input[type="submit"]
{
    background-color: transparent;
    border: none;
    display: inline-block;
    border-radius: 6px;
    position: relative;
    text-align: center;
    cursor: pointer;
    margin: 10px 10px 10px 0px;
    vertical-align: middle;
    font-size: 11px;
    font-weight: bold;
    box-shadow: none;
    padding: 5px 20px;
    height: 35px;
    background: transparent;
}

.gc-form-datav2 table tr
{
    border-bottom: 2px solid #fff;
    line-height: 120%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.gc-form-datav2 table tr td
{
    line-height: 120%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: normal;
    color: #585757;
    text-transform: lowercase;
}

.gc-form-datav2 button:hover, html .gc-form-datav2 input[type="button"]:hover, .gc-form-datav2 input[type="reset"]:hover, .gc-form-datav2 input[type="submit"]:hover
{
    background: linear-gradient(#f80, #e76300);
    background-color: #e76300;
}

.gc-login-1
{
    height: 100%;
}

.gc-faq-1
{
    padding: 30px;
    background-color: #688381;
}

.gc-login-part
{
    height: 100%;
}

.gc-login-imgpart
{
    /* background-image: url('../images/expensive+black+car+in+sunset+background+photo.jpg'); */
    background-size: auto 100%;
    vertical-align: middle;
}

.gc-login-imgpart-1
{
    background-size: auto 100%;
    /* background-image: url('../images/texture/bright-light-and-dark-surroundings-for-website-backgrounds-and-wallpapers-f5.jpg'); */
}

.gc-login-imgpart-2
{
    background-size: auto 100%;
    /* background-image: url('../images/texture/dark-website-backgrounds-2.jpg'); */
}

.login-connectpart
{
    background-color: #ffffff;
}

.gc-login-partfooter
{
    position: absolute;
    bottom: 0px;
}

.gc-smalled-top
{
    background: #00A7E7;
    color: #fbfbfb;
    margin-left: 0px;
    margin-right: 0px;
    height: 38px;
    line-height: 35px;
    overflow: hidden;
}

.gc-smalled-top li
{
    font-size: 12px;
    padding-right: 0px;
    padding-left: 0px;
    margin-left: 6px;
}

.gc-smalled-block
{
}

.gc-smalled-top .form-control
{
    border-radius: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 25px;
    font-size: 10px;
}

.gc-smalled-top .glyphicon
{
    font-size: 18px;
    vertical-align: middle;
}

.gc-smalled-block
{
    border-right: 1px solid #9D9D9D;
    text-align: center;
    color: #ffffff;
}

.gc-smalled-block ul
{
    margin-bottom: 0px;
}

.gc-time
{
    font-size: 21px;
    margin-bottom: 0px;
    padding-bottom: 0;
    margin-top: 0px;
}

.gc-timedetails
{
    font-size: 13px;
    margin-left: 7px;
    color: #a5a0a0;
}

.gc-smalled-top .list-inline
{
    margin-bottom: 0px;
}

.gc-top-share .glyphicon
{
    margin-right: 14px;
    color: #302020;
}

.gc-top-share
{
    text-align: right;
}

.gcmarket1
{
    margin: 0px;
    padding: 30px;
}

.gc-market-block1
{
    float: left;
    background-repeat: no-repeat;
    background-position: center center;
}

.gc-market-block1 img
{
    width: 100%;
}

.gc-market-cat1
{
    position: absolute;
    top: 0px;
}

.gc-iconedmenu-ul img
{
    width: 40px;
    margin-right: 10px;
    margin-left: 15px;
    float: left;
    vertical-align: middle;
    margin-top: 0px;
}

.gc-iconedmenu ul li
{
    font-size: 14px;
    background-color: #f5f5f5;
    border-right: solid 2px white;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 195px;
    margin-bottom: 0px;
    margin-top: 0px;
    height: 70px;
    vertical-align: top;
}

.gc-iconedmenu ul li a
{
    line-height: 0px;
    color: #0a5b70;
}

.gc-iconedmenu ul li a:hover
{
    text-decoration: none;
}

.gc-iconedmenu ul li:hover
{
    background-color: #edebeb;
    cursor: pointer;
}

.gc-iconedmenu
{
}

.gc-iconedmenu-ul
{
    min-height: 100px;
    overflow: hidden;
    vertical-align: middle;
}

.newzytitle
{
}

.newszy
{
    padding-top: 15px;
    border: 1px dashed #CCCCCC;
    color: #000000;
}

.newszy:hover
{
    filter: alpha(opacity=0);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    background-color: #B00000;
    color: white;
}

.newsy-call
{
    font-size: 2em;
    font-style: italic;
    text-align: center;
    padding: 25px;
}

.smally-news
{
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 0px;
}

.smally-rubrik
{
    background-color: #7a0505;
    color: #ffffff;
    font-size: 13px;
    display: inline-block;
    padding: 7px 5px;
}

.gc-droppedmenu
{
    height: 55px;
    background-color: #f3f1f1;
}

.gc-droppedmenu .level-0
{
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 100;
    color: #cbc2c2;
}

.gc-droppedmenu .level-1
{
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 100;
    color: #cbc2c2;
    line-height: 100%;
}

.gc-droppedmenu .level-2
{
    font-size: 13px;
    text-transform: capitalize;
    font-weight: 100;
    color: #100202;
    line-height: 100%;
}

.gc-droppedmenu .level-0 a, .gc-droppedmenu .level-0 a:hover
{
    color: #bdaaaa;
    cursor: none;
}

.gc-droppedmenu .level-1 a, .gc-droppedmenu .level-1 a:hover
{
    padding-left: 50px;
    color: #000000;
    font-weight: bold;
}

.gc-droppedmenu .level-2 a, .gc-droppedmenu .level-2 a:hover
{
    padding-left: 60px;
    color: #222121;
}

.gc-droppedmenu .level-0:hover
{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #bdaaaa;
    background-color: transparent;
}

.gc-droppedmenu .glyphicon
{
    font-size: 30px;
    width: 55px;
    height: 55px;
    position: absolute;
    vertical-align: middle;
    padding-top: 12px;
}

.gc-droppedmenu li
{
    font-size: 13px;
    vertical-align: middle;
    line-height: 58px;
    padding-right: 15px;
    padding-left: 15px;
    border-right: solid 0px white;
    color: #c3bebe;
    text-transform: uppercase;
    font-weight: bold;
    border-top: solid 1px #E6E6E6;
    min-height: 45px;
    list-style-type: disc;
}

.gc-droppedmenu li:hover
{
    background-color: #e7e5e5;
    text-decoration: none;
    -webkit-box-shadow: inset 0px 1px 16px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px 1px 16px -8px rgba(0,0,0,0.75);
    box-shadow: inset 0px 1px 16px -8px rgba(0,0,0,0.75);
}

.gc-droppedmenu li a
{
    padding-left: 40px;
    padding-top: 0px;
}

.gc-droppedmenu li a:hover
{
    padding-left: 40px;
    text-decoration: none;
}

.gc-droppedmenu .dropdown-menu li
{
    height: 39px;
    line-height: 45px;
    padding-left: 0px;
}

.gc-droppedmenu .dropdown-menu
{
    padding-left: 0px;
}

.gc-droppedmenu .dropdown-menu li a
{
    display: inline-block;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
}

.gc-droppedmenu .dropdown-menu li a:hover
{
    background-color: transparent;
}

.thedashy
{
    text-align: center;
}

.thedashy .bloc
{
    background-color: #f7f7f7;
    padding: 30px 15px;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

.thedashy .stats
{
    background-color: #f7f7f7;
    display: block;
    font-size: 25px;
    font-weight: bold;
    padding-bottom: 2px;
    padding-top: 2px;
    color: #1a7b95;
    margin-top: 2px;
    margin-bottom: 2px;
}

.thedashy .bloc:hover {
     filter: alpha(opacity=0);
     -webkit-transition: all 0.2s ease;
     transition: all 0.2s ease;
     -moz-box-shadow: 1px 1px 5px 1px #cfcfcf;
     -webkit-box-shadow: 1px 1px 5px 1px #cfcfcf;
     -o-box-shadow: 1px 1px 5px 1px #cfcfcf;
     box-shadow: 1px 1px 5px 1px #cfcfcf;
     filter: progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=134, Strength=5);
     background-color: #fdfdfd;
}

.thedashy h3
{
    font-weight: bold;
    text-shadow: 0px 1px #FFFFFF;
    font-size: 1.5em;
}

.thedashy .icon
{
    width: 60px;
    margin-left: auto;
    margin-right: auto;
    border: solid 5px white;
    border-radius: 40px;
}

.gc_barner_one
{
    /* background-image: url('../images/8364995-13111833.jpg'); */
    background-size: cover;
    color: #ffffff;
    height: 200px;
    padding: 58px 66px 30px 30px;
}

.gc_barner_two
{
    /* background-image: url('../images/RaissaNebie_crop.jpg'); */
    background-size: cover;
    height: 200px;
    padding-right: 15%;
}

.gc_barner_general
{
    background-size: 100% auto;
    background-position: center center;
    height: auto;
    min-height: 200px;
}

.gc_barner_general a
{
    background-color: #e9a217;
    padding: 5px 10px;
    color: #ffffff;
    border-radius: 5px;
    font-size: 11px;
}

.gc_barner_general a:hover {
     filter: alpha(opacity=0);
     -webkit-transition: all 0.2s ease;
     transition: all 0.2s ease;
     -moz-box-shadow: 1px 1px 5px 1px #333;
     -webkit-box-shadow: 1px 1px 5px 1px #333;
     -o-box-shadow: 1px 1px 5px 1px #333;
     box-shadow: 1px 1px 5px 1px #333;
     filter: progid:DXImageTransform.Microsoft.Shadow(color=#333, Direction=134, Strength=5);
     text-decoration:none;
}

.gc-smallslider .box
{
    height: 200px;
    width: 300px;
    overflow: hidden;
    border: none;
    background: #ff0;
}

.gc-smallslider .hid-box
{
    top: 100%;
    position: relative;
    transition: all .2s ease-out;
    background: #428bca;
    height: 100%;
}

.gc-smallslider .box:hover > .hid-box
{
    top: 0;
}

.virgin-button .btn-default
{
    border: solid 1px #ddd;
    background-color: #fbfbfb;
    box-shadow: none;
    color: #2c2b2b;
    font-size: 1em!important;
}

.ellipsis
{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}.gc-bloc-sub-header {
text-align: center;
font-size: 18px !important;
padding-left: 20%;
padding-right: 20%;
font-weight: 100;
line-height: 120%;
opacity: 0.7;
}.gc-main-welcometext{
font-size: 28px !important;
line-height: 130%;
}

/* end gc-apps */


/* gc-app-3 */

.gc_barner_general:hover {
   
   background-size:120% auto;
    background-position:center center;
     filter: alpha(opacity=0);
     -webkit-transition: all 0.2s ease;
     transition: all 0.2s ease;
     -moz-box-shadow: 1px 1px 5px 1px #cfcfcf;
     -webkit-box-shadow: 1px 1px 5px 1px #cfcfcf;
     -o-box-shadow: 1px 1px 5px 1px #cfcfcf;
     box-shadow: 1px 1px 5px 1px #cfcfcf;
     filter: progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=134, Strength=5);
}

.gc_barner_three
{
    /* background-image: url('../images/expensive+black+car+in+sunset+background+photo.jpg'); */
    padding-right: 18%;
}

.gc-large-barner-one
{
    /* background-image: url('../images/concours_22'); */
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
    line-height: 250px;
    min-height: 200px;
}

.gc-large-barner-one:hover
{
    /* background-image: url('../images/8364995-13111833.jpg'); */
    background-size: cover;
}

.gc-banner-raww img
{
    width: 100%;
}

.gc-banner-raww
{
    padding: 0px;
    overflow: hidden;
}

.gc-apps-common-content p
{
    font-size: 13px;
    color: #333333;
}

.englobe
{
    width: 100%;
    display: inline-block;
}

/* Reset */

.nav > li.nav-search input[type="submit"]:hover
{
    background-color: #4b4441;
}

/* Menu Dropdown */

/* Menu Content Styles */

.conceptual-header
{
    min-height: 100px;
    vertical-align: middle;
    line-height: 100px;
    display: inline-block;
    padding-top: 0px;
    background-color: #f9f9f9;
    width: 100%;
    height: 100px;
    overflow: hidden;
}

.conceptual-header .glyphicon
{
    font-size: 66px;
    color: #d5cece;
    text-align: right;
    margin-top: 17px;
}

.thespaceer-divider
{
    display: inline-block;
    width: 100%;
    overflow: hidden;
    height: 400px;
}

.thespaceer-divider img
{
    width: auto;
    height: 100%;
}

.thespaceer
{
    height: 400px;
    overflow: hidden;
    position: relative;
}

.thespaceer-texted
{
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: 500;
}

.theopener
{
    overflow: hidden;
    min-height: 400px;
}

.thelawyer
{
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.thelawyer-partone
{
    padding: 60px;
}

.thelawyer_parttwo
{
    background-color: #073d9d;
    padding-top: 14px;
    padding-bottom: 14px;
}

.thelawyer_announcer
{
    display: inline-block;
}

.easer
{
    padding-left: 0px;
    padding-right: 0px;
}

.easer-picture
{
    overflow: hidden;
    height: 400px;
    background-color: #080707;
    border: solid 8px #fff;
}

.easer-picture img
{
    width: 100%;
}

.easer-text
{
    background-color: #a93e3e;
    padding: 30px 60px;
    text-align: center;
    color: #fdfdfd;
}

.theenglober
{
    min-height: 400px;
    background-position: center center;
}

.theenglober-rounded
{
    width: 100%;
    height: 400px;
    border-radius: 0px;
    background-color: #080808;
    border: dashed 10px white;
    opacity: 0.8;
    vertical-align: middle;
    line-height: 350px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.theenglober-inner
{
    color: #ffffff;
    text-align: center;
    padding-left: 35px;
    padding-right: 35px;
    vertical-align: middle;
    display: inline-block;
}

.theenglober-inner p
{
    line-height: 20px;
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
    color: #d5d3d3;
}

.alpha .btn:after
{
    content: "";
    background: #fff;
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 4px;
    opacity: 0.5;
    transition: 0.4s;
    transition-timing-function: cubic-bezier(.36, .99, .47, 1.09);
}

.alpha .btn
{
    font-weight: 700;
    position: relative;
    font-size: 12px;
    padding: 15px 30px;
    border: none;
    text-transform: uppercase;
    border-radius: 0px;
}

.alpha .fa
{
    margin-right: 10px;
    color: #c55d5d;
    margin-top: 15px;
}

table.gc-prized tr td
{
    background-color: #f3f3f3;
    border-width: 3px 1px 3px 0px;
    border-style: solid;
    border-color: #fff;
    position: relative;
    vertical-align: middle;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 60px;
}

table.gc-prized tr td:hover
{
    background-color: #fff;
    cursor: pointer;
}

table.gc-prized td img
{
    width: 40px;
    text-align: right;
    margin-bottom: 0px;
    line-height: 0px;
    margin-right: 10px;
}

table.gc-prized th
{
    color: #fff;
    border-width: 0px 1px 0px 0px;
    border-style: solid;
    border-color: #fff;
    vertical-align: middle;
    line-height: 1.1;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: #adcd5e;
    font-size: 2em;
}

.prix
{
    width: 100%;
}

.lh1
{
    font-size: 69px;
    font-weight: bold;
    display: inline-block;
    float: left;
    line-height: 50px;
    color: #99d534;
}

.vaBott
{
    float: left;
    display: inline-block;
    font-weight: bold;
    font-size: 28px;
    line-height: 25px;
}

.morezer
{
    float: left;
}

table.gc-classement tr td
{
    padding-top: 15px;
    padding-bottom: 15px;
}

table.gc-classement tr td img
{
    width: 50px;
    margin-right: 15px;
    margin-top: -15px;
    margin-bottom: -15px;
}

table.gc-classement tr td h1
{
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

table.gc-classement tr td p
{
}

table.gc-classement tr
{
    border-top: 1px solid #fdfdfd;
    border-bottom: 1px solid #dddcdb;
    background-color: #f7f7f7;
}

table.gc-classement th
{
    background-color: #383535;
    color: #f5f1f1;
    font-size: 16px;
    text-align: center;
}

.gc-table-admin
{
    margin-bottom: 0px;
}

.gc-table-admin .glyphicon
{
    margin-right: 3px;
}

.gc-table-admin th
{
    font-size: 13px;
    font-weight: bold;
}

table.gc-table-admin .dataname, table.gc-table-admin
{
    font-weight: bold;
    font-size: 13px;
    text-shadow: 0px 1px #FFFFFF;
    line-height: 26px;
}

.gc-form-data .pagination > .active > a, .gc-form-data .pagination > .active > span, .gc-form-data .pagination > .active > a:hover, .gc-form-data .pagination > .active > span:hover, .gc-form-data .pagination > .active > a:focus, .gc-form-data .pagination > .active > span:focus
{
    background-color: transparent;
    border-color: #16A085;
}

.tablesearch label
{
    font-size: 12px;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-right: 5px;
}

.table-action {
     border: 1px solid #dddcdb;
    /* Old browsers */
     background: -moz-linear-gradient(top, #f7f7f7 10%, #f5f5f4 47%, #ececea 95%);
    /* FF3.6+ */
     background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #f7f7f7), color-stop(47%, #f5f5f4), color-stop(95%, #ececea));
    /* Chrome,Safari4+ */
     background: -webkit-linear-gradient(top, #f7f7f7 10%, #f5f5f4 47%, #ececea 95%);
    /* Chrome10+,Safari5.1+ */
     background: -o-linear-gradient(top, #f7f7f7 10%, #f5f5f4 47%, #ececea 95%);
    /* Opera 11.10+ */
    /*background: -ms-linear-gradient(top, #f7f7f7 10%,#f5f5f4 47%,#ececea 95%);
    */
    /* IE10+ */
     background: linear-gradient(to bottom, #f7f7f7 10%, #f5f5f4 47%, #ececea 95%);
    /* W3C */
}

.tablesearch
{
    border: 1px solid #dddcdb;
}

.alert h1, table.alert tr td h1
{
    text-align: left;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 1em;
}

table.alert tr td
{
    border-bottom: solid 1px #fff;
}

tr.scalable.urgent
{
    background-color: #f9a9a9;
}

tr.scalable.priority_normal
{
    background-color: #f5dac2;
}

tr.scalable.priority_faible
{
    background-color: #c2edf5;
}

.animated
{
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.modal.fade .modal-dialog
{
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.proddetails-group
{
    background-color: #f5efef;
    border-top: 1px solid #fdfdfd;
    border-bottom: 1px solid #dddcdb;
}

.proddetails-group img
{
    border: solid 8px #fff;
    border-radius: 59px;
    width: 120px;
    box-shadow: 145px #333;
}

.proddetails span
{
    display: block;
    float: none;
    font-size: 11px;
    font-weight: 500;
    border: none;
    text-align: left;
}

.dark ul.common-login-vertical li a
{
    color: #b5b2b2;
    text-shadow: 0px 1px #333;
    font-size: 11px;
}

.dark*
{
    color: white;
    text-shadow: 0px 1px #333;
}

.dark ul li
{
    color: #f1f1f1;
}

.caroussel-menu .panel-group
{
    background-color: #0E8136;
    padding-left: 12px;
    padding-right: 5px;
    border-radius: 0px;
    border: none;
}

.section-executiveprofiles
{
}

.section-executiveprofiles .content
{
    margin-left: auto;
    margin-right: auto;
}

.profile-list
{
    list-style-type: none;
    padding-left: 0px;
    border-bottom: solid 1px #e5dfdf;
    padding-top: 30px;
    padding-bottom: 15px;
}

.profile-list-item {
     margin-bottom: 28px;
     display: inline-block;
     vertical-align: top;
     margin-right: 18px;
     margin-bottom: 15px;
}

.small-profilelist .image-ceo
{
    width: 120px;
    height: 180px;
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
}

.profile-caption
{
    padding-top: 167px;
}

.hero-area-form
{
    overflow: hidden;
}

.amen input
{
    border-radius: 0;
    box-shadow: none;
    height: 60px;
    padding: 15px;
    width: 75%;
    border: 0px;
    float: left;
}

.amen button
{
    border-radius: 0;
    width: 25%;
    height: 60px;
    border: 0px;
}

.amen
{
    padding: 30px 0px;
    background-color: #2b4f6c;
}.amen img
{
    width: 35%;
}

.trace-top-category
{
    background-size: cover;
    background-position: center center;
    padding: 82px 0;
    text-align: center;
    background-color: #d1d1d1;
    position: relative;
}

.trace-top-category h1
{
    padding-top: 30px;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    line-height: 0.8em;
    font-weight: normal;
}

.trace-top-category h1:before
{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 50px;
    height: 4px;
    background: #eb6e08;
    top: 0;
}

.radis-details
{
    position: absolute;
    bottom: 0;
    top: auto;
    width: 100%;
    word-wrap: break-word;
    background-size: cover;
    padding-bottom: 20px;
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
    /* background-image: url('../images/shadow.png'); */
}

.radisblock
{
    float: left;
    height: 250px;
    overflow: hidden;
    width: 100%;
}

.alpha #main-menu-bg
{
    background-color: #a91605;
}

.alpha .main-menu
{
    position: relative;
    text-transform: uppercase;
    height: 65px;
    line-height: 62px;
    font-size: 13px;
    font-weight: 700;
    background-color: #a91605;
}

.alpha .main-menu *
{
    height: 100%;
}

.alpha .main-menu a
{
    font-size: 15px;
    vertical-align: middle;
    line-height: 40px;
    color: #ffffff;
}

.alpha .navbar-main > li {
     padding: 21px 0 24px;
     float: left;
     border-right: 1px solid rgba(255, 255, 255, 0.4);
     padding: 0 25px;
}

.alpha .topmenu:before, .alpha .topmenu nav
{
    line-height: 32px;
    border-top-color: #104A5C;
    background: #104A5C;
}

.alpha .header-padding div
{
    border-left-color: #eee;
    text-align: left;
    float: left;
    border-left: 1px solid #eee;
    padding: 5px 15px;
}

.alpha .header-padding
{
    border-left-color: #eee;
}

.alpha .header-padding span
{
    text-transform: uppercase;
    display: block;
}

.alpha .header-padding
{
    padding: 25px 0;
    font-weight: 500;
}

alpha .header
{
    height: 200px;
}

.wii-menu, .wii-menu *
{
    height: 70px;
    text-align: center;
    vertical-align: middle;
}

.wii-menu a
{
    vertical-align: middle;
    line-height: 75px;
    text-align: left;
    display: inline-block;
    font-size: 17px;
    color: #020000;
    font-weight: bold;
}

.wii-menu ul li a
{
    font-size: 15px;
    vertical-align: middle;
    line-height: 40px;
}

.wii-menu .navbar-default
{
    height: 100%;
    display: inline-block;
    float: left;
    margin-bottom: 0px;
    border: none;
}

@media (max-width:767px)
{
    .conceptual-header
    {
        height: auto;
    }
}

.gc-van-navbar ul li,ol li
{
    position: static;
}

.gc-van-navbar ul.dropdown-menu
{
    width: 102%;
    position: absolute;
    left: 0;
    float: left;
    margin-top: -5px;
    margin-left: -5px;
    margin-right: -5px;
}

.theme-opacity-half
{
    opacity: 0.5;
}

.dynamic-fixed-div
{
    position: fixed !important;
    top: 0px !important;
    background-color: white;
    width: 100%;
    height: 150px;
    z-index: 3000;
    left: 0px;
    padding: 15px 30px;
}

div#dynamicbar.dynamic-fixed-div
{
    position: absolute;
    top: 50px;
    bottom: 0px;
}

.searchin-dynamics
{
    min-height: 100px;
    vertical-align: middle;
    line-height: 100%;
    width: 100%;
}

ul.synamics li
{
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 15px;
    color: #9b9797;
}

ul.synamics li a
{
    color: #7e7a7a;
    font-weight: normal;
    width: 100%;
    display: inline-block;
    padding-top: 15px;
    padding-bottom: 15px;
    text-decoration: none;
}

.synamics
{
}

.dynamic-static-div .dynamicbar-variable
{
    display: none;
}

.slider-button
{
    width: 200%;
}

.slider-button-parent
{
    width: 100%;
    overflow: hidden;
}

.no-border
{
    border: none !important;
}

.gc-cart-numbered .qte
{
    position: absolute;
    background-color: #a71c1c;
    color: #f1f1f1;
    font-size: 22px;
    border-radius: 50%;
    padding: 15px;
    z-index: 300;
    right: -30px;
    width: 60px;
    height: 60px;
    text-align: center;
    top: -18px;
}

.gc-block-header
{
    padding: 5% 5% 5% 5%;
}

.numbers-row input
{
    padding: 5px;
    height: 45px;
    float: left;
    border: solid 1px #DADEDE;
    font-weight: bold;
    text-align: center;
}

.numbers-row .inc, .numbers-row .dec
{
    padding: 5px;
    width: 45px;
    float: left;
    font-size: 25px;
    border: solid 1px #DADEDE;
    height: 45px;
    text-align: center;
    cursor: pointer;
}

.numbers-row .inc-enhancer
{
    float: left;
    width: 100%;
    height: auto;
    display: inline-block;
}

.numbers-row
{
    width: 100%;
    float: left;
}

.cookies
{
    position: fixed;
    z-index: 10000;
    bottom: 0px;
    width: 100%;
    text-align: left;
    font-size: 0.3em;
    left: 0px;
}

.optionpage_close
{
    width: 100%;
    border-radius: 0px!important;
    padding-top: 15px!important;
    padding-bottom: 15px!important;
    left: 1%!important;
    right: 1%!important;
    height: 80px;
    vertical-align: middle;
    display: inline-block;
    line-height: 2em;
    font-size: 2em!important;
}

.gc-smallslider .box
{
    height: 200px;
    width: 300px;
    overflow: hidden;
    border: none;
    background: #ff0;
}

.gc-smallslider .hid-box
{
    top: 100%;
    position: relative;
    transition: all .2s ease-out;
    background: #428bca;
    height: 100%;
}

.gc-smallslider .box:hover > .hid-box
{
    top: 0;
}

.frm_subscribe
{
    height: 400px;
    overflow: auto;
}

.the_swicthable
{
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.dashycloser *
{
    padding: 0px;
    margin-bottom: 0px!important;
    margin-top: 0px;
}

.dashycloser .bloc
{
    height: 250px;
    box-shadow: none;
    border: solid 1px #f1f1f1;
}

@media (max-width:500px)
{
    .welcomed
    {
        padding-top: 0px;
        width: 1280px!important;
    }
    .gc-blocker-fix .item
    {
        overflow: hidden;
        background-size: cover;
        background-origin: center;
        background-position: center;
        padding: 10px 40px 30px 20px;
        height: 200px;
    }
    .gc-blocker-fix .data
    {
    }
    .gc-blocker-fix .sitem
    {
        padding: 0px !important;
        margin-bottom: 30px;
    }
    .gc-blocker-fix .sitem
    {
        padding: 0px !important;
        margin-bottom: 30px;
    }
    .gc-blocker-fix .item
    {
        height: 200px;
        background-color: transparent;
    }
    .gc-blocker-fix .data
    {
        border-bottom: solid 1px #e1e0e0;
        padding-top: 15px;
    }
    .gc-blocker-fix
    {
    }
    .gc-blocker-fix
    {
        padding-left: 30px;
        padding-right: 0px;
        margin-right: -5px;
        margin-left: -30px;
    }
    .gc-blocker-fix
    {
        padding-left: 30px;
        padding-right: 0px;
        margin-right: -5px;
        margin-left: -30px;
    }
    .gc-blocker-fix .item
    {
        height: 200px;
        padding: 15px;
    }
    .gc-blocker-fix .img-block
    {
        height: 180px!important;
    }
    .gc-blocker-fix .img-block
    {
        height: 280px!important;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .gc-blocker-fix .img-block
    {
        height: 280px!important;
    }
    .welcomed-caption
    {
        overflow-x: scroll;
        width: 100%!important;
    }
}

.gc-blocker-fix .img-block
{
    height: 200PX;
}

.ivonedlink
{
    float: left;
    width: 50px;
    height: 50px!important;
    border-radius: 80px;
    right: 15px;
    font-size: 25px!important;
    padding: 12px;
    line-height: 20px!important;
    border: solid  1px red;
}

.hovered-link-picture .glyphicon
{
    display: none;
    font-size: 2em!important;
}.hovered-link-picture
{
    width: 100%!important;
    height: 100%!important;
    border: solid 1px #fff;
    opacity: 1;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    min-height: 300px;
    
      line-height: 30px;
    list-style-type: none;background-repeat: no-repeat;background-size: cover;
}

.hovered-link-picture  .glyphicon {
font-size: 38px!important;
    margin-bottom: 0px;
}

.hovered-link-picture:hover .glyphicon
{
    display: block;
    font-size: 8em!important;
    text-align: right;
    color: #cdc2c2!important;
}

.hovered-link-picture:hover
{
    width: 100%!important;
    height: 100%!important;
    border: solid 30px #fff;
    opacity: 0.8;
    background-color: #000;
}.no-margin {
    margin:0px!important;
}


/* end gc-app3 */


/*gc-app2 */

.helpslide .panel-group .panel + .panel
{
}

.helpslide .panel-title > a, .helpslide .panel-title > a:hover, .helpslide .panel-title span
{
    background: transparent;
    color: #141313;
    text-align: left;
    font-size: 14px;
    text-transform: capitalize;
    text-decoration: none;
    text-shadow: 0px 1px #FFFFFF;
}

.helpslide .panel-title span.details .glyphicon
{
    font-size: 0px;
    color: #dd1414;
    display: none;
}

.helpslide .panel-title span.details:after
{
    display: none;
}

.helpslide .panel-title
{
    border: none;
}

.helpslide .panel-heading
{
    border-top-color: #fdfdfd !important;
    border-bottom-color: #dddcdb !important;
    border-top: solid 1px;
    margin-top: -1px;
    margin-bottom: 0px;
    border-radius: 0px;
}

.appv3-contentslide .panel
{
    background-color: transparent;
    border: none;
    border-bottom: none;
}

.appv3-contentslide .panel-collapse
{
    background-color: transparent;
    height: 100%;
}

.appv3-searchpage
{
    height: 100%;
    float: left;
    width: 100%;
    background-size: cover;
}

.appv3-fillcontent
{
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    height: 100%;
}

.appv3-contentslide .panel-body
{
    padding: 0px;
    height: 100%;
}

.appsv3-left-close .panel-body
{
    border: none;
}

.appv3-searchzone
{
    height: 100%;
    text-align: center;
    border-right: solid 1px silver;
    padding-left: 30px;
    padding-right: 30px;
}

.appv3-searchzone-hide
{
    display: none;
}

.appv3-resultzone
{
    height: 100%;
    text-align: center;
}

.appsv3-collapsed-title
{
    background-color: #646060;
    margin: -5px 0px 35px;
}

.appv3-result-show
{
    text-align: left;
    display: block;
}

.appv3-result-hide
{
    text-align: left;
    display: none;
}

.appsv3-left {
     filter: alpha(opacity=0);
     -webkit-transition: all 0.2s ease;
     transition: all 0.2s ease;
     -moz-box-shadow: 1px 1px 5px 1px #cfcfcf;
     -webkit-box-shadow: 1px 1px 5px 1px #cfcfcf;
     -o-box-shadow: 1px 1px 5px 1px #cfcfcf;
     box-shadow: 1px 1px 5px 1px #cfcfcf;
     filter: progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=134, Strength=5);
     overflow:hidden;
     background-color:#4A4848;
     padding-bottom:30px;
     padding-top:30px;
}

appsv3-opacity
{
    opacity: 0.4;
    filter: alpha(opacity=40);
}

.appsv3-left-close {
     width: 0px;
     height:5px;
     padding: 0px;
     overflow: hidden;
     background-color: #4A4848;
     padding-bottom: 50px;
     filter: alpha(opacity=0);
     -webkit-transition: all 0.2s ease;
     transition: all 0.2s ease;
     -moz-box-shadow: 1px 1px 5px 1px #cfcfcf;
     -webkit-box-shadow: 1px 1px 5px 1px #cfcfcf;
     -o-box-shadow: 1px 1px 5px 1px #cfcfcf;
     box-shadow: 1px 1px 5px 1px #cfcfcf;
     filter: progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=134, Strength=5);
}

.appsv3-left-close a, .appsv3-left a
{
    color: silver;
}

.appsv3-left-close a:hover
{
    text-decoration: none;
}

.appsv3-left-close .panel-title, .appsv3-left .panel-title
{
    visibility: visible;
    height: 50px;
    border-bottom: none;
}

.appsv3-right {
     filter: alpha(opacity=0);
     -webkit-transition: all 0.2s ease;
     transition: all 0.2s ease;
     -moz-box-shadow: 1px 1px 5px 1px #cfcfcf;
     -webkit-box-shadow: 1px 1px 5px 1px #cfcfcf;
     -o-box-shadow: 1px 1px 5px 1px #cfcfcf;
     box-shadow: 1px 1px 5px 1px #cfcfcf;
     filter: progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=134, Strength=5);
}

.appsv3-vertical-menu ul li
{
    display: block;
    width: 100%;
}

.appsv3-vertical-menu .dropdown-menu li
{
}

.appsv3-vertical-menu .panel-title
{
}

.appsv3-vertical-menu
{
    background-color: #4A4848;
    border: none;
}

.appsv3-admin-bloc-title
{
    font-size: 21px;
    text-align: left;
    border-bottom: solid 1px #DADEDE;
    font-weight: bold;
}

.appsv3-admin-bloc
{
    padding: 0px;
    margin: 15px 0px 0px;
    border: 1px solid #e5e6e9;
}

.appsv3-bloc-bigtitle
{
    background-color: transparent;
    text-align: center;
}

.theme-text-innershadow-textdark
{
    text-shadow: 0px 1px #fff!important;
}

.theme-text-innershadow-textlight
{
    text-shadow: 0px 1px #333!important;
}

.appsv3-admin-bloc-content .glyphicon
{
    text-align: left;
    margin-right: 15px;
    color: #120505;
}

.appsv3-admin-bloc-content a
{
    text-align: left;
    color: #c77c2b;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 15px;
    width: 100%;
    font-size: 13px;
}

.appsv3-admin-bloc-content a:hover
{
    text-align: left;
}

.appsv3-admin-bloc-content
{
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ffffff;
}

.gc-form-datav3 .appsv3-admin-bloc-content
{
    background-color: white;
    border: 1px solid #c3cac9;
    border-top: none;
    padding: 30px 20px 45px;
    position: relative;
}

.appv3-left-popup .ap-modal
{
    min-height: auto;
    max-width: 400px;
    width: 400px;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 100%;
    left: 190px;
    background-color: transparent;
    border: 0px solid #e3e3e3;
    box-shadow: none;
    -webkit-box-shadow: none;
    -ms-box-shadow: none;
}

.appv3-topmenu-popup-btn
{
    background-color: transparent;
    border: none;
    box-shadow: none;
    margin: 0px;
    padding-top: 15px;
    border-radius: 0px;
}

.appv3-left-popup
{
    box-shadow: none;
}

.appsv3-tabpanes
{
    background-color: transparent;
    height: 100%;
}

.appsv3-tabpanes .tab-pane
{
    font-weight: bold;
    color: #0a0101;
    background-color: transparent;
    border: none;
    margin: 0px;
    padding: 15px;
    height: 100%;
}

.appsv3-tabpanes .tab-pane ul li
{
    font-weight: bold;
    color: #0a0101;
    background-color: transparent;
    border: none;
    margin: 0px;
    padding: 5px 15px;
    width: 100%;
    font-size: 13px;
}

.appsv3-tabpanes .tab-pane ul li a
{
    color: #6a6666;
    font-size: 13px;
    margin: 0px;
    padding: 0px;
}

.appsv3-tabpanes .tab-pane a
{
    margin: 0px;
    padding: 0px;
}

.appsv3-tabpanes .tab-content
{
    font-weight: bold;
    color: #0a0101;
    background-color: white;
    border: none;
    margin: 0px;
    padding: 0px;
}

.appsv3-tabpanes > .nav-tabs > li.active > a
{
    font-weight: bold;
    color: #0a0101;
    background-color: white;
    border-bottom: 0 solid #000;
    margin: 0px;
    padding: 5px 10px;
    font-size: 12px;
    text-align: center;
}

.appsv3-tabpanes > .nav-tabs > li.active
{
    font-weight: bold;
    color: #625757;
    background-color: white;
    border-bottom: 0 solid #000;
    margin: 0px;
    padding: 5px 10px;
    font-size: 12px;
    text-align: center;
    border: 1px solid #CED0D0;
}

.appsv3-tabpanes > .nav-tabs > li > a
{
    font-weight: bold;
    color: #625757;
    background-color: transparent;
    border-bottom: 0px solid #000 !important;
    margin: 0px;
    padding: 5px 10px;
    font-size: 12px;
    text-align: center;
}

.appsv3-tabpanes > .nav-tabs > li
{
    font-weight: bold;
    color: #625757;
    background-color: transparent;
    border-bottom: 0px solid #000 !important;
    margin: 0px;
    padding: 5px 10px;
    font-size: 12px;
    text-align: center;
}

.appsv3-tabpanes > .nav-tabs > li.active > a
{
    border-bottom: 0px solid #fff;
}

.appsv3-search .btn
{
    color: gray;
    position: absolute;
    top: -15px;
}

.appsv3-search-result
{
    padding: 10px;
    color: #a9a3a3;
}

.croquis-link .glyphicon
{
    font-size: 14px;
    margin-right: 16px;
}

.appsv3-logout-popup .ap-modal
{
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 800px;
    min-width: 600px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 2000;
    background-color: #ffffff;
}

.appsv3-close-popup
{
    color: #8f8787;
}

.appsv3-top-menu .gc-appone-profile-menu
{
    padding-top: 5px;
    height: auto;
}

.appsv3-top-menu ul li
{
    margin-bottom: 0px;
    margin-top: 0px;
}

.appsv3-tabpanes ul li a
{
    color: #666;
    font-size: 12px;
    font-weight: normal;
}

.tab-pane ul li a
{
    color: #666;
    font-size: 12px;
    font-weight: normal;
}

.appsv3-newsearch-hide
{
    display: none;
}

.appsv3-newsearch-show
{
    display: block;
}

.appsv3-top-menu a:focus
{
    color: #555;
    background-color: red;
}

.appsv3-table th
{
    padding: 5px;
    font-size: 16px;
    color: silver;
}

.appsv3-table td
{
    padding: 10px;
    border: none;
}

.appsv3-table tr
{
    background-color: white;
}

.appsv3-table tr:hover
{
    background-color: silver;
    color: white;
}

.appsv3-form-fieldset, .appsv3-form fieldset
{
    border: solid 1px #E6E6E6;
    padding: 5px 10px;
    border-radius: 1px;
    margin: 30px auto;
    background-color: transparent;
}

.appsv3-form-fieldset .form-control, .appsv3-form input, .appsv3-form textarea, .appsv3-form select
{
    border-radius: 15px;
    font-size: 13px;
    width: 100%;
    padding: 5px 5px 5px 30px;
    border: solid 1px #CCCCCC;
    margin-top: 5px;
    margin-bottom: 5px;
}

.form-control:focus, .appsv3-form input:focus, .appsv3-form textarea:focus, .appsv3-form select:focus
{
    -moz-box-shadow: 1px 1px 5px 1px #E87352;
    -webkit-box-shadow: 1px 1px 5px 1px #E87352;
    -o-box-shadow: 1px 1px 5px 1px #E87352;
    box-shadow: 1px 1px 5px 1px #E87352;
    filter: progid:DXImageTransform.Microsoft.Shadow(color=#E87352, Direction=134, Strength=5);
    padding: 5px;
}

.appsv3-form-fieldset .form-control:focus
{
    padding: 5px 5px 5px 30px;
    border: solid 1px #CCCCCC;
    border-radius: 15px;
    box-shadow: none;
    background-color: #333;
    color: white;
}

.appsv3-form
{
    margin-right: auto;
    margin-left: auto;
    background-color: #ffffff;
    padding-top: 10px;
}

.appsv3-form table tr td, .appsv3-form table tr
{
    border: none;
}

.appsv3-form table
{
    border: none;
    margin: 30px 0px 30px 0px;
}

.appsv3-form table tr td:hover, .appsv3-form table tr:hover
{
    background-color: transparent;
}

.pretty-effect
{
    float: left;
    width: 100%;
    height: 100%;
    background-color: #040404;
}

.form-description img, .form-description img, .form-description img.img-responsive
{
    width: 100%;
}

input[type="radio"], input[type="checkbox"], input[type="checkbox"], input[type="radio"]
{
    margin: 4px 4px 4px 0px;
    line-height: 100%;
}

.form-description a.glyphicon
{
    color: #fbfbfb;
}

.result-notifications
{
    height: 70px;
    line-height: 70px;
    border: solid 3px #E7501C;
    margin: 10px 0px;
    width: 100%;
    float: left;
    background-color: #f3f3f3;
}

.notif-icons
{
    height: 100%;
    background-color: #E7501C;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
    padding-top: 5px;
}

.notif-icons .glyphicon
{
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.scalable:hover
{
    transform: scale(1.09);
    z-index: 900;
    -moz-box-shadow: 0px 0px 1px 1px #9b9b9b;
    -webkit-box-shadow: 0px 0px 1px 1px #9b9b9b;
    -o-box-shadow: 0px 0px 1px 1px #9b9b9b;
    box-shadow: 0px 0px 1px 1px #9b9b9b;
    filter: progid:DXImageTransform.Microsoft.Shadow(color=#9b9b9b, Direction=NaN, Strength=2);
    cursor: pointer;
    background-color: white;
}

.gcapps-blk
{
    display: inline-block;
    vertical-align: middle;
    line-height: 100%;
    width: 100%;
}

.users-list-block
{
    display: inline-block;
    padding: 15px 0px;
    color: #c31c1c;
}

.users-list-block:hover
{
    filter: alpha(opacity=0);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    display: inline-block;
    padding: 15px 0px;
    background-color: #cc3343;
    color: #fdfdfd;
}

.users-list-img
{
    border-radius: 90px;
    overflow: hidden;
    width: 80px;
    height: 80px;
    border: 5px solid #fff;
}

.users-list-img img
{
    width: 100%;
    height: auto;
    text-align: center;
}

.users-list, .row.users-list
{
    background-color: #Fff;
    border: 1px solid #D2D2D2;
}

.users-list-details span
{
    font-size: 12px;
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
    color: inherit;
}

.users-list-details::after
{
    content: "/";
}

.users-list-block .users-list-details:hover
{
    color: white;
}

.apps-social img
{
    width: 50px;
}

.apps-social img:hover
{
    filter: alpha(opacity=0);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    width: 50px;
    border: 5px solid white;
    border-radius: 50px;
}

.apps-social
{
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #F1F1F1;
    background: #F1F1F1;
}

.search-box
{
    box-shadow: 0 2px 4px rgba(0,0,0,0.2),0 -1px 0px rgba(0,0,0,0.02);
    position: relative;
    background: #fff;
    border-radius: 2px;
    box-sizing: border-box;
    border-bottom: 1px solid transparent;
    padding: 12px 104px 11px 34px;
    transition-property: background,box-shadow;
    transition-duration: 0.3s;
}

.search
{
    margin: 15px 0px;
    padding: 30px;
    /* background-image: url('../images/huggy.jpg'); */
}

.search .glyphicon
{
    font-size: 26px;
    color: #726e6e;
}

.validate
{
    position: absolute;
    right: 71px;
    height: 58px;
    margin-top: 8px;
    float: right;
}

.gc-filter
{
    margin-left: 0px;
    margin-right: 0px;
}

.gc-form-datav2
{
    background-color: #EFEFF2;
}

.gc-form-data .gc-filter .control-label
{
    background-color: #ffffff;
}

.gc-actions
{
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.gc-form-data .app-menu
{
    background-color: #ffffff;
    border: 1px solid #d3d6db;
}

.gc-form-data .app-menu ul
{
    padding-bottom: -10px;
}

.gc-form-data .app-menu ul li
{
    border-right: 1px solid #e9eaed;
    float: left;
    font-size: 16px;
    font-weight: bold;
    height: 41px;
    line-height: 3.05;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    color: #0e0c0c;
    margin-bottom: 0px;
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 0px;
}

.gc-form-data .app-menu ul li:hover
{
    border-right: 1px solid #e9eaed;
    float: left;
    font-size: 16px;
    font-weight: bold;
    height: 43px;
    line-height: 3.05;
    padding: 0 17px;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    border-bottom: solid 3px red;
    color: #0e0c0c;
}

ul.second li
{
    font-size: 11px;
    color: #ffffff;
    font-weight: bold;
}

ul.second
{
    font-size: 13px;
    margin-top: 10px;
    color: #978f8f;
    font-weight: bold;
    list-style-type: square;
}

.gc-form-datav3
{
    background: #E8EDED;
}

.gc-searchv1
{
    /* background-image: url('../images/8364995-13111833.jpg'); */
    background-size: cover;
    line-height: 600px;
    vertical-align: middle;
    height: 600px;
}

.gc-searchv1 input
{
    height: 50px;
    border: none;
    width: 100%;
}

.gc-searchv1 input:focus
{
    -moz-box-shadow: 0px 0px 25px 0px #ffcc67;
    -webkit-box-shadow: 0px 0px 25px 0px #ffcc67;
    -o-box-shadow: 0px 0px 25px 0px #ffcc67;
    box-shadow: 0px 0px 25px 0px #ffcc67;
    filter: progid:DXImageTransform.Microsoft.Shadow(color=#ffcc67, Direction=NaN, Strength=25);
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding-left: 10px;
    font-size: 35px;
}

.contain-box
{
    background-color: transparent;
    vertical-align: middle;
    line-height: 100%;
    display: inline-block;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
}

.serchy
{
    background-color: #ffffff;
    height: 58px;
    border: 4px solid rgba(0,0,0,.2);
    border-radius: 4px;
    width: 100%;
    padding: auto 20%;
}

.gc-searchv1 button.btn-default {
     width:100%;
     padding: 5px 10px 4px;
     height: 51px;
     font-size: 36px;
     margin:0px;
     margin-top:-10px;
     border-radius: 0;
     color: #fff;
     background-color: #e85b54;
     background-image: -webkit-linear-gradient(top,#e85b54 0,#b22520 100%);
     background-image: -o-linear-gradient(top,#e85b54 0,#b22520 100%);
     background-image: linear-gradient(to bottom,#e85b54 0,#b22520 100%);
     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFE85B54', endColorstr='#FFB22520', GradientType=0);
     border-color: #b22520;
     box-shadow: inset 0 1px 0 rgba(242,164,162,.6),0 1px 2px rgba(0,0,0,.05);
}

.stepped
{
    background-color: #ffffff;
    padding-top: 0px;
    padding-bottom: 0px;
}

.stepped-block .icolink .glyphicon
{
    margin-right: 15px;
}

.stepped-block h3
{
    display: inline-block;
    margin-bottom: 0px;
}

.number-stepped
{
    font-size: 48px;
    float: left;
    width: 20%;
    margin-right: 15px;
}

.name-stepped {
     font-size: 0.3em;
     line-height: 100%;
     text-align: left;
     line-height: 16px;
     float: left;
     display: inline;
     width: 85%;
     margin-top: 0px;
}

.gc-price tr
{
    color: #5a5a5a;
}

.gc-price .table-responsive
{
    margin-bottom: 30px;
}

.ivonedescr
{
    float: left;
}

.ivonesxtented
{
    display: inline-block;
    height: 60px;
    font-size: 60px;
    margin-right: 10px;
}

.xtentedivone
{
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    float: left;
    width: 50px;
    height: 50px!important;
    border-radius: 80px;
    right: 15px;
    font-size: 25px!important;
    padding: 12px;
    line-height: 20px!important;
    border: none;
    overflow: hidden;
    background-color: #333!important;
}

.xtentedivone:hover
{
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    height: 60px;
    display: inline-block;
    width: 180px;
    line-height: 60px;
    vertical-align: middle;
    overflow: hidden;
}

.ivoneextented-details
{
    float: left;
    text-align: left;
    font-size: 19px;
}

.stepped-block
{
    /* background-image: url('../images/next.png'); */
    background-size: 20px 100%;
    background-repeat: no-repeat;
    padding-left: 25px;
    padding-bottom: 15px;
    height: 180px;
    overflow: hidden;
    background-position: -5px;
}

.steppeddebut img
{
    width: 80%;
    margin-left: 10px;
}

.stepped .viewed
{
    color: #f3e1e1;
    background-color: #6e6262;
    /* background-image: url('none'); */
}

.viewed
{
    color: #080101;
}

.bybrand-brand .logo
{
    vertical-align: middle;
    width: 110px;
}

.bybrand-brand img
{
    width: 28px;
}

.small_ban_one
{
    /* background-image: url('../images/8364995-13111833.jpg'); */
}

.gc-custom-radio input[type="radio"], .gc-custom-radio input[type="checkbox"],.gc-custom-radio input[type="checkbox"],.gc-custom-radio input[type="radio"]
{
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: solid 3px #000;
    float: left;
    margin-right: 15px;
    cursor: pointer;
}

.gc-custom-radio input[type="radio"]:checked, .gc-custom-radio input[type="radio"]:hover
{
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    background-color: #333!important;
    border: solid 3px #333!important;
    /* background-image: url('../images/check-box-icon-white.png'); */
    background-size: 60%;
    background-position: center center;
    background-repeat: no-repeat;
}

.gc-custom-radio input[type="radio"]:checked::before
{
    font: 13px/1 'Open Sans', sans-serif;
    left: 20px;
    top: 12px;
    content: '\02143';
    transform: rotate(40deg);
    position: absolute;
    font-size: 20px;
}

.gc-custom-radio .form-radio:checked::before
{
    font: 13px/1 'Open Sans', sans-serif;
    left: 20px;
    top: 12px;
    content: '\02143';
    transform: rotate(40deg);
    position: absolute;
    font-size: 20px;
}

.gc-custom-radio .control-label
{
    width: auto;
    height: auto;
    line-height: 40px;
    float: left;
    padding-right: 30px;
}

.sliderv2_btn_header
{
    border-top: solid 1px #ccc;
    border-bottom: solid 1px #ccc;
    height: 100px;
    line-height: 100px;
}

.option_slider_v2 .sliderv2_btn_title
{
    font-size: 2em;
    line-height: inherit;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: bold;
}
.ourhotels-property {
font-weight: bold!important;
}

/*end gc-app2 */


/*widget css*/



.wysiwig
{
    float: left;
    width: 400px;
    border: solid 1px #F0F0F0;
    overflow: hidden;
    height: 43px;
}

.wysiwig .glyphicon
{
    font-size: 17px;
}

.wysiwig .iconbloc
{
    border: 1px solid #CCCCCC;
    text-align: center;
    padding: 10px;
}

.wysiwig .iconbloc:hover
{
    background-color: #e5e5e5;
}

.carousel-control
{
    background-color: #000000;
    position: static;
    color: #120303;
}

.wysiwigcontainer .form-control
{
    border-radius: 0px;
    height: 50px;
}

.prixing-block
{
    float: left;
    font-size: 22px;
    padding-right: 15px;
    padding-left: 15px;
}

.prixing-block .btn, .prixing-block .btn-default
{
    font-size: 16px;
    padding-right: 30px;
    padding-left: 30px;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    color: #605959;
    border-radius: 0px;
    margin-right: -6px;
    margin-left: 0px;
    font-weight: bold;
    min-height: 50px;
    line-height: 50px;
}

.prixing .glyphicon
{
    font-size: 30px;
    margin-right: 10px;
    color: #bdb6b6;
    vertical-align: middle;
    margin-top: -5px;
}

.profil-moyenne
{
    padding: 10px;
    background-color: #29849d;
    border-radius: 10px;
    color: #ffffff;
    font-weight: bold;
}

.red
{
    background-color: #c10a0a;
}

.profil-level
{
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    border: solid 1px #cbc1c1;
}

.profil-level-name, h3.profil-level-name
{
    text-align: center;
    font-weight: bold;
    margin-bottom: 0px;
}

.profil-level-nickname
{
    text-align: center;
    color: #4a9bd1;
    margin-top: 0px;
    margin-bottom: 30px;
}

h3.Profil-description, .Profil-description
{
    font-weight: bold;
}

.profile-line-one
{
    background-color: #f9f7f7;
}

.profile-line2
{
    border-top: solid 1px #cbc1c1;
    padding-bottom: 15px;
    padding-top: 15px;
}

.gc-animateback
{
    /* background-image: url('../images/BANN.jpg'); */
    min-height: 350px;
}

.wizard-placeholder-img
{
    text-align: center;
    padding: 15px;
}

.wizard-placeholder-img p
{
    font-size: 12px;
    font-weight: normal;
}

.rating a
{
    float: right;
    color: #aaa;
    text-decoration: none;
    font-size: 3em;
    transition: color .4s;
}

.rating a:hover,
.rating a:focus,
.rating a:hover ~ a,
.rating a:focus ~ a
{
    color: orange;
    cursor: pointer;
}

.rating
{
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

.gc-animated-button1
{
    border-radius: 4px;
    background-color: #f4511e;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 28px;
    padding: 20px;
    width: 200px;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
}

/*HELPSLIDE*/

.helpslide
{
    padding-left: 0px;
    padding-right: 0px;
    background-color: #f5efef;
}

.helpslide h1
{
    font-weight: normal;
    text-shadow: 0px 1px #FFFFFF;
    text-align: left;
}

.helpslide .panel-default
{
    border: none;
    background-color: transparent;
    margin-bottom: 0px;
    margin-top: 0px;
}

.helpslide .panel-body
{
    font-size: 12px;
    text-shadow: 0px 1px #FFFFFF;
}

.gc-animated-button1 span
{
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.gc-animated-button1 span:after
{
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.gc-animated-button1:hover span
{
    padding-right: 25px;
}

.gc-animated-button1:hover span:after
{
    opacity: 1;
    right: 0;
}

.takeoff h1
{
    font-size: 5em;
}

.takeoff h2
{
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 4em;
}

.takeoff h3
{
    margin-top: 0px;
    margin-bottom: 0px;
}

.lovin h1
{
    display: inline-block;
    width: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 5em;
}

.lovin h2
{
    display: inline-block;
    width: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 3em;
}

.lovin h3
{
    display: inline-block;
    width: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 3em;
}

.lovin h4
{
    display: inline-block;
    width: auto;
}

.error2
{
    background-color: #FD0002;
    color: #ffffff;
    padding: 0px;
    margin-top: 0px;
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 0px;
    position: fixed;
    bottom: 15px;
    z-index: 3000;
    width: 400px;
    right: 15px;
    border: none;
}

.error2 .event
{
    color: #ffffff;
    padding: 15px;
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 0px;
    width: 100%;
    border-bottom: 1px solid #E1E1E1;
}

.error2 .event.add
{
    background-color: #e7a1a1;
    color: #ffffff;
    padding: 30px;
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 0px;
}

.error2 .event.infos
{
    background-color: #ffffff;
    color: #0a0101;
    padding: 30px;
    font-size: 14px;
    line-height: 100%;
    margin-bottom: 0px;
}

.error2 .event.remove
{
    background-color: #b1acac;
    color: #ffffff;
    padding: 30px;
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 0px;
}

/*NEWSTICKER*/

.newsticker_1 .ticker
{
    display: inline-block;
    background-color: #000001;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
}

.newsticker_1 .ticker p
{
    color: white;
    margin-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    font-size: 18px;
}

.ticker-live
{
    width: 100%;
    display: inline-block;
    background-color: #c34040;
    color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    font-size: 1em;
    font-weight: bold;
}

.tickersecondplace
{
    color: #fff9f9;
    margin-left: 15px;
    font-size: 18px;
}

/*widget*/

.profileslide
{
    padding-bottom: 30px;
    padding-top: 30px;
}

.profileslide ul li
{
    font-size: 1em;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 1.2em;
    list-style-type: none;
    text-align: center;
    list-style-position: inside;
    padding-left: 0px;
    margin-left: 0px;
}

.profileimg
{
    width: 200px;
    height: 200px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-radius: 200px;
}

.takeoff *
{
    line-height: 98%!important;
    letter-spacing: -3px;
}

.lovin *
{
    line-height: 98%!important;
    letter-spacing: -3px;
}





/*end widgets */


/* left-static css */



.vertical_menu_area {
    background-color: #ffffff;
    position: fixed;
width: 260px;
top: 0;
left: 0;
padding: 10px 30px;
background-color: #fff;
height: 100%;
z-index: 10001;
-webkit-backface-visibility: hidden;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
-webkit-transition: background-color .3s ease;
-moz-transition: background-color .3s ease;
-o-transition: background-color .3s ease;
-ms-transition: background-color .3s ease;
transition: background-color .3s ease;
outline: 0;
}

.with_scroll
{
    overflow: hidden;
}

.left-static-pagecontent
{
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.left-static_page_content_inner
{
    padding-left: 260px;
    position: relative;
    display: block;
    z-index: 100;
}

@media (max-width:767px)
{
    .left-static_page_content_inner
    {
        padding-left: 5px;
    }
}




/* end left-static css */


/* rescue-login css */


.rescue-left
{
    background-color: #151922;
    color: #ffffff;
    height: 100%;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
}

.rescue-left h2
{
    color: #b9b9b9;
    font-size: 22px;
    margin-top: 10px;
}

.rescue-block
{
    height: 600px !important;
    margin-top: 5%;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding-left: 0px;
    padding-right: 0px;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
}

.rescue-right button .rescue-right button, .rescue-right input[type="button"], .rescue-right input[type="reset"], .rescue-right input[type="submit"]
{
    border-radius: 1px;
    color: #00A875;
    font-weight: bold;
    font-size: 14px;
    margin-top: 15px;
    padding: 8px 25px;
    margin-right: 15px;
    width: auto;
}

.rescue-right button:hover .rescue-right button:hover, .rescue-right input[type="button"]:hover, .rescue-right input[type="reset"]:hover, .rescue-right input[type="submit"]:hover
{
    border-radius: 1px;
    background-color: transparent;
    color: #00A875;
    font-weight: bold;
    font-size: 14px;
    margin-top: 15px;
    padding: 8px 25px;
    margin-right: 15px;
}

.rescue-block input
{
    border-radius: 0px;
    height: 45px;
    border: none;
    width: 100%;
    padding: 2px 5px;
}

.rescue-right
{
 
    height: 100%;
}


.rescue-middle
{
    width: 100%;
    float: left;
    height: 80%;
    line-height: 400px;
    display: inline-block;
    vertical-align: middle;
}

.rescue-bottom
{
    width: 100%;
    float: left;
    height: 100px;
}

.rescue-aligner
{
    width: 100%;
    vertical-align: middle;
    line-height: 130%;
    display: inline-block;
}

.rescue-right-ray
{
    width: 100%;
    display: inline-block;
    line-height: 500px;
}

.rescue-form
{
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    line-height: 150%;
}

.rescue-form .control-label
{
    color: #f9f9f9;
    font-size: 19px;
    font-weight: 100;
    margin-bottom: 10px;
    margin-top: 16px;
}.rescue-heighthy
{
    width: 100%;
    float: left;
    height: 100%;
    background-color: #383F49;
    margin-top: 0px;
    line-height:600px;
}

.rescue-heighthy2
{
    width: 100%;
    float: left;
    height: 76%;
    background-color: #8d2121;
    margin-top: 0px;
    /* background-image: url('../images/huggy.jpg'); */
    background-size: cover;
}

body
{
    height: 100%;
}

.rescue-left-pict
{
    /* background-image: url('../images/expensive+black+car+in+sunset+background+photo.jpg'); */
}

.rescue-form .control-label
{
    clear: both;
    display: block;
}

.rescue-form fieldset
{
    border: none;
}

.rescue-block input
{
    border-radius: 24px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: bold;
    color: #666;
}

.rescue-block input:focus
{
    border-radius: 24px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: bold;
    background-color: #333;
    color: white;
    box-shadow: none;
    font-size: 1.2em;
    opacity: 1;
}

.rescue-block .form-group
{
    margin-bottom: 15px;
}
@media (max-width:767px) {
.rescue-middle {
  height: 40% !important;
  line-height: 200px;
}
.rescue-left {
  height: 40%!IMPORTANT;
}
.rescue-right {
  height: 70%!IMPORTANT;
  line-height: 100PX;
}
.rescue-right-ray {
  height: 100%!IMPORTANT;
  line-height: 300px!important;
}
};


/* end rescue-login css */



/* gcs-filter css */


.gcs-filter
{
    border-bottom: solid 1px #f1f1f1;
    margin-bottom: 0px;
    padding: 10px;
    opacity: 0.6;
    line-height: 100%;
    vertical-align: middle;
}

.gcs-filter img
{
    width: 35px;
}

.gcs-filter:hover
{
    opacity: 1;
}

.gcs-filter ul li
{
    list-style-type: disc;
    font-size: 11px;
    font-weight: normal;
    line-height: 12px;
}

.gcs-filter *
{
}

.gcs-filter .pagination
{
    margin-top: 0px;
    margin-bottom: 0px;
}

.gcs-filter input, .gcs-filter select
{
    border-radius: 0px;
    padding: 2px 2px 2px 6px;
    border: solid 1px #ddd;
    box-shadow: none;
    font-size: 16px;
}

.gcs-filter input:focus, .gcs-filter select:focus
{
    border-radius: 0px;
    border: solid 1px #ddd;
    box-shadow: none;
    padding: 2px 2px 2px 6px;
}

.gcs-filter td,th,tr
{
    vertical-align: middle;
    color: #363333;
    font-weight: normal;
    border: none;
    border-top: none;
    font-size: 11px;
}

.gcs-filter .table > tbody > tr > td, .table > tfoot > tr > td
{
    padding: 1px 2px;
    line-height: 1.42857143;
    vertical-align: middle;
    border-top: none;
    margin-top: 0px;
    margin-bottom: 0px;
}

.gcs-right-filter
{
    display: block;
    width: 100%;
    min-height: 80px;
    line-height: 80px;
    vertical-align: middle;
    border: solid 1px #f1f1f1;
    border-bottom: solid 1px #ddd;
}

p.gcs-filtersresult, .gcs-filtersresult
{
    font-size: 22px;
    font-weight: bold;
    font-family: Arial, Helvetica,sans-serif;
    color: #080101;
    letter-spacing: -1px;
}

.gcs-filter-item-bloc
{
    min-height: 150px;
    background-color: #f1f1f1;
    border: solid 1px #f1f1f1;
}

.gc-grille
{
    width: 33%;
    display: inline-block;
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
}

.gc-grille:hover
{
    width: 33%;
    display: inline-block;
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
}

.gc-filter-listing
{
    -webkit-transition: 1s ease-in-out;
    -moz-transition: 1s ease-in-out;
    -o-transition: 1s ease-in-out;
    transition: 1s ease-in-out;
}

.gcs-filter-placeholder
{
    height: 100%;
    width: 100%;
    background-color: #cd6b6b;
}

.gcs-filter-placeholder:hover
{
    height: 100%;
    background-color: #333;
    position: absolute;
    width: 33%;
    z-index: 1000;
}

.gcs-left-filter .tab-pane
{
    padding-top: 5px;
    border-bottom: none;
}

.gcs-left-filter
{
    padding-top: 15px;
    opacity: 0.6;
}

.gcs-left-filter:hover
{
    opacity: 1;
}

.gcs-filter .pagination li a
{
    background-color: #ffffff;
    border: solid 1px #ddd;
}

.gc-colordata .item, .gc-colordata .form-radio, .gc-colordata .btn.btn-default
{
    width: 50px!important;
    height: 50px!important;
    margin: 5px!important;
    border-radius: 50px;
    box-shadow: -1px 2px 10px 3px rgba(0, 0, 0, 0.3) inset;
    float: left;
    position: relative!important;
    font-size: 0em!important;
    line-height: 1em;
}

.gc-colordata .form-checkbox
{
    background-color: #a31a1a;
    width: 80px!important;
    height: 50px!important;
    margin: null null 10px null null !important;
    border-radius: 5px;
    box-shadow: -1px 2px 10px 3px rgba(0, 0, 0, 0.3) inset;
    float: left;
    position: relative!important;
    border: solid 1px #000;
}

.gc-colordata .control-label, .gc-colordata control-label, .gc-colordata label
{
    width: 70px!important;
    height: 70px;
    text-align: center;
}

.gc-colordata
{
    background-color: #ffffff;
    padding: 10px;
}

.gc-colordata .form-radio
{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #f1f1f1;
    color: #666;
    cursor: pointer;
}

.gc-colordata .form-radio:checked::before
{
    font: 13px/1 'Open Sans', sans-serif;
    left: 20px;
    top: 12px;
    content: '\02143';
    transform: rotate(40deg);
    position: absolute;
    font-size: 20px;
}

.gc-colordata .form-radio:hover
{
    background-color: #f7f7f7;
}

.gc-colordata .form-radio:checked
{
    background-color: #f1f1f1;
}

.virgin-button .btn-default
{
    border: solid 1px #ddd;
    background-color: #fbfbfb;
    box-shadow: none;
    color: #2c2b2b;
    font-size: 1em!important;
}

.rates_picture input
{
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50px;
    font-size: 0em;
    background-size: cover;
    background-color: #f3e9e9;
    margin-right: 8px;
}

.rates_picture input:hover
{
    font-size: 0em;
    background-color: #ffffff;
}

.rates_picture
{
    padding-top: 15px;
    padding-bottom: 15px;
}

.gc-event-agenda
{
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 0px;
}

.gc-event-agenda .month
{
    font-size: 26px;
}

.gc-event-agenda .day1
{
    font-size: 18px;
}

.gc-event-agenda .day
{
    font-weight: bold;
    font-size: 80px;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 110%;
}

.gc-event-agenda .date-bloc
{
    border-left: solid 5px;
}

.gc-event-agenda .item
{
    padding-top: 0px;
    padding-bottom: 15px;
    background-color: #ffffff;
}

/*USERPROFILE*/

.gc-user-profile-header
{
    background-color: #f1f1f1;
}

.gc-user-profile-header a
{
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 0px;
}

.profilimg
{
    width: 100px;
}

.gc-user-profile-picture
{
    background-color: #ccc;
    min-height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 50px;
    color: #333;
}

.gc-user-profile-picture .glyphicon
{
    color: #f9f7f7;
    position: absolute;
    line-height: 99px;
    font-size: 80px;
    top: 11px;
    left: 40px;
    padding-bottom: 0px;
}

.gc-user-profile-bloc
{
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: solid 1px #f1f1f1;
}

.gc-user-profile-1 .btn
{
    font-size: 11px;
    border-radius: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.gc-user-profile-headerbloc
{
    min-height: 100px;
    display: inline-block;
    vertical-align: middle;
    line-height: 100px;
    padding-left: 30px;
    border-right: solid 1px #ddd;
}

.gc-user-profile-headerbloc:hover
{
    background: #fff;
}

.gc-user-profile-left .list-group-item
{
    background-color: transparent;
    border: none;
}

.gc-user-profile-left .list-group-item a:hover
{
    background-color: rgba(163, 71, 71, 0);
    border: none;
}

.profil_content_dynamic
{
    display: inline-block;
    width: 100%;
}

.fullmenu ul li a
{
    font-size: 1em;
    color: #a5a3a3;
    margin-bottom: 0px;
    line-height: 16px;
}

.fullmenu ul li a:hover
{
    color: #444343;
    text-decoration: none;
    list-style-type: none;
}

.fullmenu2 ul li
{
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #ddd;
    border-top: solid 1px #eee;
    margin-top: 0px;
    margin-bottom: -1px;
}

.fullmenu2 ul li:hover
{
    background-color: #f1f1f1;
}

.fullmenu2 ul li a
{
    font-size: 1em;
    color: #666565;
    font-weight: bold;
}

.fullmenu2 ul li a:hover
{
    font-size: 1em;
    color: #000;
    text-decoration: none;
}

.card
{
    border-top: none;
    border-bottom: solid 1px #ddd;
    padding-top: 15px;
    padding-bottom: 15px;
}.card-heading, .card-heading a, .card-heading a:hover
{
    margin: 0px!important;
    color: #000!important;
    width: 100%;
    text-align: left;
    font-size: 1.3em;
    font-family: helvetica, arial;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0px;
    text-shadow: 0px 1px #fff!important;
    padding-left: 0px;
    padding-right: 0px;
    border:none!important;
    background:none!important;
}

.card-heading a:hover
{
    text-decoration: none;
}

.card-heading .material-icons
{
    float: right;
}

.card-body*
{
    color: #333;
}


/* end gc-filter css */

/* option slider */

 .gc-optionslider1 .op-body {
     vertical-align: middle;
     height: 300px;
     line-height: 300px;
     display: inline-block;
     width: 100%;
}
 .gc-optionslider1 .form {
     width: 100%;
     line-height: 10px !important;
     height: 300px !important;
     padding-left: 0px;
     padding-right: 0px;
}
 .gc-optionslider1 .form input[type="radio"] {
     width: 100%;
     margin-top: -2px;
     margin-right: 10px !important;
}
 .gc-optionslider1 .form .radio {
     width: 100%;
     line-height: 10px !important;
     display: inline-block;
     padding: 12px;
     border-bottom: solid 1px #f1f1f1;
     text-align: left;
}
 .gc-optionslider1 .op-body .glyphicon {
     vertical-align: middle;
     display: inline-block;
}
 .gc-optionslider1 glyphicon {
     min-height: 300px;
     vertical-align: middle;
     height: 300px;
     line-height: 300px;
     display: inline-block;
}
 .gc-optionslider1 .navigator {
     background: transparent;
     position: relative;
     margin-top: 10px;
     height: auto;
     border: none;
}
 .gc-optionslider1 {
     text-align: center;
     overflow: hidden;
     /* background-image: url('../images/bg-opacity.png'); */
}
 .gc-optionslider1 .footer {
     text-align: center;
     padding-top: 5px;
     padding-bottom: 10px;
}.gc-optionslider-link ul li {
     text-align: left;
     border-bottom: solid 1px #f1f1f1;
     font-size: 1.5em;
     padding: 5px;
     color: #a3a1a1;
}
 .gc-optionslider-link a li {
     color: #333!important;
}
 .gc-optionslider-link .glyphicon {
     margin: 0px 15px 0px 0px!important;
     color: #e7e3e3;
}
 .gc-optionslider-link ul {
     padding-left: 0px;
     padding-bottom: 0px;
}


.gc-ulapps {
     background-color: #ddd5d5;
     background: -moz-linear-gradient(top, #989898, #6b6b6b);
     background: -webkit-linear-gradient(top, #989898, #6b6b6b);
}
 .gc-ulapps li {
     font-size: 13px;
     color:white;
     background-color: #ddd5d5;
     text-shadow: 0px 1px #000;
     padding: 15px 30px;
     font-weight: bold;
     margin-left: -4px;
     margin-right: 0px;
     border-right: solid 1px #555;
     border-left: solid 1px #a7a7a7;
     text-shadow: 1px 2px 1px #585858;
     background: -moz-linear-gradient(top, #989898, #6b6b6b);
     background: -webkit-linear-gradient(top, #989898, #6b6b6b);
}
 .gc-ulapps-right li {
     font-size: 13px;
     color:#333;
     background-color: #ddd5d5;
     text-shadow: 0px 1px #FFFFFF;
     padding: 15px 30px;
     font-weight: bold;
     margin-bottom:0px;
     margin-left: -4px;
     margin-right: 0px;
     border-right:none;
     border-left:none;
     border-top: 1px solid #fdfdfd;
     border-bottom: 1px solid #dddcdb;
    /* Old browsers */
     background: -moz-linear-gradient(top, #f7f7f7 10%, #f5f5f4 47%, #ececea 95%);
    /* FF3.6+ */
     background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #f7f7f7), color-stop(47%, #f5f5f4), color-stop(95%, #ececea));
    /* Chrome,Safari4+ */
     background: -webkit-linear-gradient(top, #f7f7f7 10%, #f5f5f4 47%, #ececea 95%);
    /* Chrome10+,Safari5.1+ */
     background: -o-linear-gradient(top, #f7f7f7 10%, #f5f5f4 47%, #ececea 95%);
    /* Opera 11.10+ */
    /*background: -ms-linear-gradient(top, #f7f7f7 10%,#f5f5f4 47%,#ececea 95%);
    */
    /* IE10+ */
     background: linear-gradient(to bottom, #f7f7f7 10%, #f5f5f4 47%, #ececea 95%);
    /* W3C */
}
 .gc-ulapps-right li .glyphicon {
     margin-right: 15px;
     float: left;
     margin-bottom: 25px;
     font-size: 25px;
     display: inline;
}
 .gc-ulapps li:hover {
     background: -moz-linear-gradient(top, #fff, #fff);
}
 .gc-ulapps li.first {
     background-color: #ddd5d5;
     border-top-left-radius: 15px;
     font-weight: bold;
     margin-left: -4px;
     margin-right: 0px;
}
 .gc-ulapps-right li.first {
     background-color: #ddd5d5;
     border-top-left-radius: 15px;
     border-top-right-radius: 15px;
     font-weight: bold;
     margin-left: -4px;
     margin-right: 0px;
}
 .gc-ulapps li.last {
     background-color: #ddd5d5;
     border-top-right-radius: 15px;
     font-weight: bold;
     margin-left: -4px;
     margin-right: 0px;
}
 .gc-ulapps-right li.last {
     background-color: #ddd5d5;
     border-bottom-right-radius: 15px;
     border-bottom-left-radius: 15px;
     font-weight: bold;
     margin-left: -4px;
     margin-right: 0px;
}
 .common-login1 li a {
     display: inline-block;
     padding: .6em .6em .7em 1em;
     color: #333333;
     text-shadow: 0px 1px #fff5cc;
}
 ul.common-login-vertical li a {
     display: inline-block;
     padding: 0.1em .6em 0.1em 0em;
     color: #333333;
     text-shadow: 0px 1px #fff5cc;
     list-style-type: square;
     list-style-position: outside;
}
 ul.common-login-vertical li {
     list-style-type: square;
     list-style-position: inside;
     /* list-style-image: url('../images/arrow_right.png'); */
     font-size: 11px;
     line-height: 11px;
}
 .common-login1 .glyphicon {
     margin-right: 6px;
}
 ul.common-login-vertical .glyphicon {
     margin-right: 6px;
}

/* end option-slider */




/*cool animate*/

/* .3bloc-animate img {
}
.3bloc-animate {
}
.3bloc-animate {
  width: 8px !important;
background-color: #cc2727 !important;
} */
.ert {
}
.threebloc-animate {
  height: 550px;
}
.threebloc-animate img {
  width: 214px;
height: auto;
top: 25px;
}
@media (max-width:767px) {
.threebloc-animate img {
  width: 155px;
}
}

/*end cool animate*/

/* stepped form css */
#regForm {
  background-color: #ffffff;
  margin: 100px auto;
  padding: 40px;
  width: 70%;
  min-width: 300px;
}

/* Style the input fields */
#regForm input {
  padding: 10px;
  width: 100%;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid #aaaaaa;
  border-radius: 30px !important;
}

#regForm select {
  border-radius: 30px !important;
}

/* Mark input boxes that gets an error on validation: */
#regForm input.invalid {
  background-color: #ffdddd;
}

/* Hide all steps by default: */
#regForm .tab {
  display: none;
}

/* Make circles that indicate the steps of the form: */
#regForm .step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none; 
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

/* Mark the active step: */
#regForm .step.active {
  opacity: 1;
}

/* Mark the steps that are finished and valid: */
#regForm .step.finish {
  background-color: #04AA6D;
}


#regForm button {
  /*background-color: #04AA6D;*/
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
}

#regForm button:hover {
  opacity: 0.8;
}

#regForm #prevBtn {
  background-color: #bbbbbb;
}


/* Make circles that indicate the steps of the form: */
#regForm .step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;  
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

#regForm .step.active {
  opacity: 1;
}

/* Mark the steps that are finished and valid: */
#regForm .step.finish {
  background-color: #04AA6D;
}