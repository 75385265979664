.App {
  &-logo {
    height: 40vmin;
    pointer-events: none;
  }
  &-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  &-link {
    color: #61dafb;
  }

  &-profil__row__header {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  &__errors{
    color: red !important;
    font-weight: bolder;
}

}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 767px){
  .App{
    &-text-nowrap{
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
      width: 40vw !important;
      overflow: hidden !important;
    }
  }
  .menu-module{
    .btn {
      padding: 5px 15px !important;
      
    }
    .bloc{
      h3{
        font-size: 16px !important;
      }
    }
  }
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
