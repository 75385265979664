

.mylonaci-tabs{
    height: auto;
    min-height: 400px;
    //background: #053742;
    margin: 3.5rem auto 1.5rem;
    padding: 2rem 1rem;
    color: #ede8f2;
    border-radius: 2rem;
    
  .react-tabs {
    -webkit-tap-highlight-color: transparent;
  }
   
  .react-tabs__tab-list {
    width: 100%;
    // margin-left: 1.5rem;
    // margin-right: 2rem !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px #f0cf79 !important;
    padding-left: 0;
  }
  
   
  .react-tabs__tab {
    width: 50%;
    padding: 28px 10px;
    margin-bottom : 0px !important;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
    font-size: 12px;
    font-weight: bolder;
    text-transform: uppercase;
  }
   
  .react-tabs__tab--selected {
    background: #f0cf79 !important;
    // margin-right: 2rem !important;
  }
   
  .react-tabs__tab--disabled {
    color: GrayText;
    // margin-right: 2rem !important;
    cursor: default;
  }
   
  .react-tabs__tab:hover {
    background: #FBF1D2;
    // margin-right: 2rem !important;
  }
   
  .react-tabs__tab:first-child {
   /* border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;*/
  }
   
  .react-tabs__tab:last-child {
    /*border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;*/
  }
   
  .react-tabs__tab-panel {
    display: none;
  }
   
  .react-tabs__tab-panel--selected {
    display: block;
    font-size: 2rem;
    text-align: center;
  }

}