
@import "../../layout/themes/components/bootstrap/css/bootstrap.css";
@import "../../layout/themes/css/gc-stater-package.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.min.css";
@import "../../layout/themes/css/apps-package.css";
@import "../../layout/themes/css/stratec.css";

.login-page{
    text-align: center;
    height: 100vh !important;
    .virgin-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    &__errors{
        color: red !important;
        font-weight: bolder;
    }
    &__success{
        color: green !important;
        font-weight: bolder;
    }
    &__input-invalid{
        border-color: red !important;
    }
}

@media screen and (min-width: 800px) {
    .signin-page {
        height: 100vh !important;
    }
  }

.signin-page{
    text-align: center;
    .virgin-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    &__errors{
        color: red !important;
        font-weight: bolder;
    }
    &__success{
        color: green !important;
        font-weight: bolder;
    }
    &__input-invalid{
        border-color: red !important;
    }
}