
.loadable-content{
    min-height:200px;
    &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height:200px;
    &__loader {
        width: 50px;
        height: 50px;
    }
}
}