
@import "../../layout/themes/components/bootstrap/css/bootstrap.css";
@import "../../layout/themes/css/gc-stater-package.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.min.css";
@import "../../layout/themes/css/apps-package.css";
@import "../../layout/themes/css/stratec.css";

.rechargement{
    &__errors{
        color: red !important;
        font-weight: bolder;
    }
    &__success{
        color: green !important;
        font-weight: bolder;
    }
    &__input-invalid{
        border-color: red !important;
    }
    &__link:link, &__link:hover, &__link:visited, &__linka:link, &__link:active{
        text-decoration: underline !important;
    }
}

#transError {
    color: red !important;
}